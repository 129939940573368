import React from "react";
import { Button } from "@mui/material";
import { PiArrowLeft } from "react-icons/pi";

export default function BackButton({ handleBack, name }) {
  return (
    <Button
      varient="outlined"
      onClick={handleBack}
      sx={{
        color: "#001F3F",
        border:'1px solid #0F4F96',
        height: "40px",
        padding:'0 24px',
        minWidth: "8rem",
        boxShadow:"none",
        borderRadius: "40px",
        fontWeight:'600',
      }}
    >
      {name ? (
        name
      ) : (
        <>
           Back
        </>
      )}
    </Button>
  );
}
