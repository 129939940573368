import React, { useEffect, useState } from "react";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  useTheme,
  useMediaQuery,
  styled,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logDOM } from "@testing-library/react";
import candidatesActions from "../../redux/candidates/actions";
import authActions from "../../redux/auth/actions";

export default function Tabs(props) {
  const dispatch =useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const { activeTab, courseRegistrationData } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
 const [filledForms,setFilledForms] =useState('');
  const { courseStatus } = useSelector((state)=>state?.candidatesReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const ispaid = useSelector((state) => state.candidatesReducer?.ispaid?.ispaid);
  
console.log('courseStatus-----',courseStatus);
console.log('candidateid-----',candidateid);
console.log('ispaid-----',ispaid);

useEffect(() => {
  dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
}, []);

useEffect(() => {
  if(candidateid){
    dispatch({type:candidatesActions.GET_COURSE_STATUS,payload:{candidateid,courseid}})
    dispatch({type:candidatesActions.GET_IS_PAID,payload:{candidateid,courseid}})
  }
}, [candidateid]);

useEffect(() => {
if(courseStatus){
  console.log("courseStatus---",courseStatus);
  // const filledForms = courseStatus.profile_status === 1 ? 1 : courseStatus.address_status === 1 ? 2 : courseStatus.reservation_status === 1 ? 3 : courseStatus.academic_status === 1 ? 4 : courseStatus.exampreference_status === 1 ? 5 : 0
  const filledForms =   courseStatus.exampreference_status === 1 ? 5 :  courseStatus.academic_status === 1 ? 4 : courseStatus.reservation_status === 1 ? 3 : courseStatus.address_status === 1 ? 2 :courseStatus.profile_status === 1 ? 1 : null
setFilledForms(filledForms)
}
  
}, [courseStatus]);


console.log('filledForms---',filledForms);
// {candidateid: '167', courseid: 31, profile_status: 1, address_status: 1, academic_status: 1, …}


  const handleTabClick = (index, to) => {
    
    if(ispaid ==1 || ispaid !=1){
      navigate(to, {
        state: {
          courseid: courseid,
        },
      });
      
    }
    else if (index <= filledForms && to) { 
      navigate(to, {
        state: {
          courseid: courseid,
        },
      });
    }  
  };

  return (
    <Grid
      sx={{
        height: "auto",
        backgroundColor: "#FFFFFF",
        margin: "10px 0px",
        padding: "15px 15px",
        borderRadius: "8px",
        overflowX: "auto",
        width: "100%",
        whiteSpace: "nowrap",
        "&::-webkit-scrollbar": {
          height: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stepper
        activeStep={ispaid == 1 ? 7 : activeTab === 6 ? activeTab: activeTab === 7 ? activeTab : filledForms}
        orientation={isMobile ? "vertical" : "horizontal"}
      >
        {courseRegistrationData.map((item, index) => (
          <Step key={index} sx={{ pr: 10 }}>
            <StepLabel onClick={() => handleTabClick(index, item.to)}
               sx={{
                cursor: ispaid == 1 ? "pointer" : index <= filledForms ? "pointer" : "default",
                '& .MuiStepLabel-label': {
                  cursor:ispaid == 1 ? "pointer" : index <= filledForms ? "pointer" : "default",
                }
              }}
              >
              {item.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
}
