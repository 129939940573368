import React, { useState } from "react";
import Index from "../components/signup/index";
import BeforeLoginHeader from "../layouts/beforeLoginHeader";
import SupportModal from "../components/modals/supportModal";
import HelpManualVideoModal from "../components/modals/helpManualVideoModal";
import BeforeLoginFooter from "../layouts/beforeLoginFooter";
import BeforeLoginLayout from "../layouts/beforeLoginLayout";

export default function Signup() {

  const [showModal, setShowModal] = useState({ show: false, type: null });

  return (
    <>
      <BeforeLoginLayout>
        <BeforeLoginHeader />
        <Index />
      </BeforeLoginLayout>
      <BeforeLoginFooter />

      <SupportModal showModal={showModal?.type === 'services' && showModal?.show} handleClose={() => setShowModal({ show: false, type: null })} />
      <HelpManualVideoModal showModal={showModal?.type === 'video' && showModal?.show} handleClose={() => setShowModal({ show: false, type: null })} />
    </>
  );
}
// import { useForm } from "react-hook-form";
// import {
//   TextField,
//   Button,
//   Grid,
//   Typography,
//   IconButton,
//   InputAdornment,
// } from "@mui/material";
// import { FiEye, FiEyeOff } from "react-icons/fi";
// import { Link, useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import ButtonLoader from "../common/buttonLoader";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   AlphabetsValidation,
//   InputTrimFunction,
//   MobileNumberFirstNumberValidation,
//   MobileNumberValidation,
//   handleEmailValidation,
//   passwordValidation,
// } from ".././utils/validations";
// import authActions from "../redux/auth/actions";

// const Signup = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const { buttonLoader } = useSelector((state) => state.commonReducer);

//   const {
//     register,
//     handleSubmit,
//     watch,
//     formState: { errors },
//   } = useForm();

//   const [passState, setPassState] = useState(false);
//   const [passState2, setPassState2] = useState(false);

//   const onSubmit = (data) => {
//     dispatch({
//       type: authActions.CANDIDATE_SINUP,
//       payload: { navigate: navigate, data: data },
//     });
//   };

//   return (
//     <Grid
//       container
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       height={"100vh"}
//       // margin={"20px 0px"}
//     >
//       <Grid
//         item
//         xs={11}
//         sm={8}
//         md={6}
//         lg={6}
//         sx={{ borderRadius: "8px", p: 3, gap: 2, bgcolor: "#FFFFFF" }}
//       >
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <Typography variant="h5" fontWeight="540" pb={2}
//           style={{ fontSize: "24px", fontWeight: "540" }}
//           >
//             Candidate Sign Up
//           </Typography>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 size="small"
//                 {...register("firstname", {
//                   required: "First name is required",
//                 })}
//                 onInput={AlphabetsValidation}
//                 inputProps={{ maxLength: 50 }}
//                 label="First name"
//                 placeholder="Enter first name"
//                 fullWidth
//                 error={!!errors.firstname}
//                 helperText={errors.firstname ? errors.firstname.message : ""}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 size="small"
//                 {...register("lastname", { required: "Last name is required" })}
//                 onInput={AlphabetsValidation}
//                 inputProps={{ maxLength: 50 }}
//                 label="Last name"
//                 placeholder="Enter last name"
//                 fullWidth
//                 error={!!errors.lastname}
//                 helperText={errors.lastname ? errors.lastname.message : ""}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Typography variant="body2" color="red">
//                 In case, your full name only consists of one word (without
//                 surname), please enter your name as "NAME." (Name"Dot").
//               </Typography>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 size="small"
//                 {...register("emailid", {
//                   required: "Email id is required",
//                   validate: handleEmailValidation,
//                 })}
//                 onInput={InputTrimFunction}
//                 inputProps={{ maxLength: 50 }}
//                 label="Email id"
//                 placeholder="Enter email id"
//                 fullWidth
//                 error={!!errors.emailid}
//                 helperText={errors.emailid ? errors.emailid.message : ""}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 size="small"
//                 {...register("mobileno", {
//                   required: "Mobile number is required",
//                   minLength: {
//                     value: 10,
//                     message: "Enter valid mobile number",
//                   },
//                   validate: MobileNumberFirstNumberValidation,
//                 })}
//                 onInput={MobileNumberValidation}
//                 inputProps={{ maxLength: 10 }}
//                 label="Mobile no"
//                 placeholder="Enter mobile number"
//                 fullWidth
//                 error={!!errors.mobileno}
//                 helperText={errors.mobileno ? errors.mobileno.message : ""}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 size="small"
//                 {...register("temppassword", {
//                   required: "Password is required",
//                   minLength: {
//                     value: 8,
//                     message: "Password must have at least 8 characters",
//                   },
//                   validate: passwordValidation,
//                 })}
//                 onInput={InputTrimFunction}
//                 inputProps={{ maxLength: 16 }}
//                 type={passState ? "text" : "password"}
//                 label="Password"
//                 placeholder="Enter password"
//                 fullWidth
//                 error={!!errors.temppassword}
//                 helperText={
//                   errors.temppassword ? errors.temppassword.message : ""
//                 }
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton onClick={() => setPassState(!passState)}>
//                         {passState ? <FiEyeOff /> : <FiEye />}
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 size="small"
//                 {...register("confirmpassword", {
//                   required: "Confirm password is required",
//                   minLength: {
//                     value: 8,
//                     message: "Password must have at least 8 characters",
//                   },
//                   validate: (val) => {
//                     if (watch("temppassword") !== val) {
//                       return "Passwords do not match";
//                     }
//                   },
//                 })}
//                 onInput={InputTrimFunction}
//                 inputProps={{ maxLength: 16 }}
//                 type={passState2 ? "text" : "password"}
//                 label="Confirm Password"
//                 placeholder="Enter password"
//                 fullWidth
//                 error={!!errors.confirmpassword}
//                 helperText={
//                   errors.confirmpassword ? errors.confirmpassword.message : ""
//                 }
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton onClick={() => setPassState2(!passState2)}>
//                         {passState2 ? <FiEyeOff /> : <FiEye />}
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Typography variant="body2" color="red">
//                 Password must be at least 8 characters long and include at least
//                 one uppercase letter, one lowercase letter, one number, and one
//                 special character. The maximum password character length cannot
//                 exceed 16 characters.
//               </Typography>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 size="small"
//                 {...register("dob", { required: "Date of Birth is required" })}
//                 // max={moment().format('YYYY-MM-DD')}
//                 type="date"
//                 label="DOB"
//                 InputLabelProps={{ shrink: true }}
//                 fullWidth
//                 error={!!errors.dob}
//                 helperText={errors.dob ? errors.dob.message : ""}
//               />
//             </Grid>
//           </Grid>
//           <Grid container justifyContent="center" sx={{ py: 3 }}>
//             <Button
//               type="submit"
//               variant="contained"
//               sx={{
//                 height: "40px",
//                 width: "107px",
//                 borderRadius: "40px",
//                 padding: "10px, 24px, 10px, 24px",
//                 backgroundColor: "#3F41D1",
//                 "&:hover": { backgroundColor: "#3F41D1" },
//               }}
//               onClick={handleSubmit(onSubmit)}
//               disabled={buttonLoader}
//             >
//               {buttonLoader ? <ButtonLoader /> : "Signup"}
//             </Button>
//           </Grid>
//           <Grid container justifyContent="center" sx={{ py: 1 }}>
//             <Typography variant="body2">
//               Already have an account?{" "}
//               <Link
//                 to={`/${Cookies.get("mhet_cnd_project")}/login`}
//                 style={{
//                   textDecoration: "none",
//                   color: "#212121",
//                   fontWeight: 600
//                 }}
//                 >
//                 Login
//               </Link>
//             </Typography>
//           </Grid>
//         </form>
//       </Grid>
//     </Grid>
//   );
// };

// export default Signup;
