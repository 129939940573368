import { put, call, all, takeEvery, select } from "redux-saga/effects";
import masterActions from "./action";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";

const masterSaga = function* () {
  yield all([
    yield takeEvery(masterActions.GET_GENDER, getGender),
    yield takeEvery(masterActions.GET_MOTHERTONGUE, getMothertongue),
    yield takeEvery(masterActions.GET_REGION, getRegion),
    yield takeEvery(masterActions.GET_RELIGION, getReligion),
    yield takeEvery(masterActions.GET_NATIONALITY, getNationality),
    yield takeEvery(masterActions.GET_ANNUALINCOME, getAnnualIncome),
    yield takeEvery(masterActions.GET_STATE, getState),
    yield takeEvery(masterActions.GET_DISTRICT, getDistrict),
    yield takeEvery(masterActions.GET_TALUKA, getTaluka),
    yield takeEvery(masterActions.GET_VILLAGE, getVillage),
    yield takeEvery(masterActions.GET_CATEGORY, getCategory),
    yield takeEvery(masterActions.GET_CASTE, getCaste),
    yield takeEvery(masterActions.GET_PWD_TYPE, getPwdType),
    yield takeEvery(masterActions.GET_BOARD, getBoard),
    yield takeEvery(masterActions.GET_LANGUAGE, getLanguage),
    yield takeEvery(masterActions.GET_ALL_DISTRICT, getAllDistrict),
    yield takeEvery(masterActions.GET_GRIEVANCE, getGrievance),
    yield takeEvery(masterActions.GET_MINORITY, getMinority),
    yield takeEvery(masterActions.GET_DOCUMENT_TYPE, getDocumentType),
    // yield takeEvery(masterActions.GET_STATE_FOR_REGISTRATION, getStateForRegistration),
    yield takeEvery(masterActions.GET_DISTRICT_FOR_REGISTRATION, getDistrictForRegistration),
    yield takeEvery(masterActions.GET_TALUKA_FOR_REGISTRATION, getTalukaForRegistration),
    yield takeEvery(masterActions.GET_VILLAGE_FOR_REGISTRATION, getVillageForRegistration),
  ]);
};

const getGender = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/gender`));
    if (result) {
      yield put({
        type: masterActions.SET_GENDER,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};


const getMinority = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/minority`));
    if (result) {
      yield put({
        type: masterActions.SET_MINORITY,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getMothertongue = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/mothertongue`)
    );
    if (result) {
      yield put({
        type: masterActions.SET_MOTHERTONGUE,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getRegion = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/region`));
    if (result) {
      yield put({
        type: masterActions.SET_REGION,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getReligion = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/religion`));
    if (result) {
      yield put({
        type: masterActions.SET_RELIGION,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getNationality = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/nationality`));
    if (result) {
      yield put({
        type: masterActions.SET_NATIONALITY,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getAnnualIncome = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/familyincome`)
    );
    if (result) {
      yield put({
        type: masterActions.SET_ANNUALINCOME,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getState = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/state`));
    if (result) {
      yield put({
        type: masterActions.SET_STATE,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getDistrict = function* (action) {
  try {
    const stateid = action.payload;
    const result = yield call(() =>
      axios.get(`${API_URL}/master/district/by_stateid/${stateid}`)
    );
    if (result) {
      yield put({
        type: masterActions.SET_DISTRICT,
        payload: result?.data?.result,
      });
    }
    console.log(stateid);
  } catch (err) {
    console.log(err);
  }
};

const getTaluka = function* (action) {
  try {
    const districtid = action.payload;
    const result = yield call(() =>
      axios.get(`${API_URL}/master/taluka/by_districtid/${districtid}`)
    );
    if (result) {
      yield put({
        type: masterActions.SET_TALUKA,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getVillage = function* (action) {
  try {
    const talukaid = action.payload;
    const result = yield call(() =>
      axios.get(`${API_URL}/master/village/by_talukaid/${talukaid}`)
    );
    if (result) {
      yield put({
        type: masterActions.SET_VILLAGE,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getCategory = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/category`));
    if (result) {
      yield put({
        type: masterActions.SET_CATEGORY,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getCaste = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/caste`));
    if (result) {
      yield put({
        type: masterActions.SET_CASTE,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getPwdType = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/pwdtype`));
    if (result) {
      yield put({
        type: masterActions.SET_PWD_TYPE,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getBoard = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/board`));
    if (result) {
      yield put({
        type: masterActions.SET_BOARD,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getLanguage = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/language`));
    if (result) {
      yield put({
        type: masterActions.SET_LANGUAGE,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getAllDistrict = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/district`));
    if (result) {
      yield put({
        type: masterActions.SET_ALL_DISTRICT,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getGrievance = function* () {

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });

  try {
    const result = yield call(() => axios.get(`${API_URL}/master/grievance`));
    if (result) {
      yield put({
        type: masterActions.SET_GRIEVANCE,
        payload: result?.data?.result,
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getDocumentType = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/document`))
    if (result) {
      yield put({ type: masterActions.SET_DOCUMENT_TYPE, payload: result?.data?.result })
    }
  } catch (err) {
    console.log(err);
  }
}

// const getStateForRegistration = function* (data) {
//   const { payload } = data;

//   try {
//     const { stateForReg } = yield select(state => state.masterReducer);
//     const result = yield call(() => axios.get(`${API_URL}/master/state`));
//     if (result) {
//       let finalRes;
//       (payload?.type === 'parmanent') ? finalRes = {...stateForReg, parmanent: result?.data?.result} :
//       finalRes = {...stateForReg, corespondence: result?.data?.result}
//       yield put({
//         type: masterActions.SET_STATE_FOR_REGISTRATION,
//         payload: finalRes
//       });
//     }
//   } catch (err) {
//     console.log(err);
//   }
// }

const getDistrictForRegistration = function* (data) {
  const { payload } = data;

  try {
    const { districtForReg } = yield select(state => state.masterReducer);
    const result = yield call(() =>
      axios.get(`${API_URL}/master/district/by_stateid/${payload?.stateid}`)
    );
    if (result) {
      let finalRes;
      (payload?.type === 'parmanent') ? finalRes = {...districtForReg, parmanent: result?.data?.result} :
      finalRes = {...districtForReg, corespondence: result?.data?.result}
      yield put({
        type: masterActions.SET_DISTRICT_FOR_REGISTRATION,
        payload: finalRes
      });
    }
  } catch (err) {
    console.log(err);
  }
}

const getTalukaForRegistration = function* (data) {
  const { payload } = data;

  try {
    const { talukaForReg } = yield select(state => state.masterReducer);
    const result = yield call(() =>
      axios.get(`${API_URL}/master/taluka/by_districtid/${payload?.districtid}`)
    );
    if (result) {
      let finalRes;
      (payload?.type === 'parmanent') ? finalRes = {...talukaForReg, parmanent: result?.data?.result} :
      finalRes = {...talukaForReg, corespondence: result?.data?.result}
      yield put({
        type: masterActions.SET_TALUKA_FOR_REGISTRATION,
        payload: finalRes
      });
    }
  } catch (err) {
    console.log(err);
  }
}

const getVillageForRegistration = function* (data) {
  const { payload } = data;

  try {
    const { villageForReg } = yield select(state => state.masterReducer);
    const result = yield call(() =>
      axios.get(`${API_URL}/master/village/by_talukaid/${payload?.talukaid}`)
    );
    if (result) {
      let finalRes;
      (payload?.type === 'parmanent') ? finalRes = {...villageForReg, parmanent: result?.data?.result} :
      finalRes = {...villageForReg, corespondence: result?.data?.result}
      yield put({
        type: masterActions.SET_VILLAGE_FOR_REGISTRATION,
        payload: finalRes
      });
    }
  } catch (err) {
    console.log(err);
  }
}


export default masterSaga;
