import React from "react";
import Layout from "../layouts/layout";
import Sidebar from "../layouts/sideBar";
import Index from "../components/courseSelection/index";
import Header from "../layouts/header";
import {  NavLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import Cookies from "js-cookie";

export default function CourseSelection() {

    const breadcrumbs = [
        <Link underline="hover" key="1" component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/home`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
        }}>
            Dashboard
        </Link>,
        <Typography key="3" color="text.primary"
        sx={{
            display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: "bold",
            lineHeight: '20px',
            letterSpacing: '0.1px'
        }}
        >
            Course selection<MdKeyboardArrowDown size={16} />
        </Typography>,
    ];


    return (
        <Layout>
            <Header breadcrumbs={breadcrumbs}/>
            <Sidebar/>
            <Index />
        </Layout>
    )
}