import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../protectedRoute/PrivateRoute";
import PublicRoute from "../protectedRoute/publicRoute";
import Registration from "../pages/registration";
import Login from "../pages/login";
import Signup from "../pages/signup";
import LandingPage from "../pages/landingPage";
import EmailVerification from "../common/verification/emailVerification";
import MobileVerification from "../pages/mobileVerification";
// import CourseSelection from "../pages/courseSelection";
import QueryList from "../pages/queryList";
import EmailVerificationSuccess from "../common/verification/emailVerificationStatus";
import MobileVerificationStatus from "../common/verification/mobileVerificationStatus";
import RaiseQuery from "../pages/raiseQuery";
// import PaymentReceiptList from "../pages/paymentReceiptList";
import PaymentReceiptView from "../pages/paymentReceiptView";
import { ForgetPassword } from "../pages/forgetPassword";
import NewPasswordMail from "../pages/newPasswordMail";
import ForgotPasswordStatus from "../common/verification/forgotpasswordStatus";
import CandidateDetails from "../pages/candidateDetails";
import CourseRegistration from "../pages/courseRegistration";
import CourseSelection from "../pages/courseSelection";
import PreRequestPage from "../pages/preRequestPage";
import PaymentConfirmation from "../pages/paymentConfirmation";
import Instruction from "../pages/instruction";
import PaymentReciptList from "../pages/PaymentReciptList";
import ObjectionTrackerDashboard from "../pages/ObjectionTrackerDashboard";
import ObjectionTrackerView from "../pages/ObjectionTrackerView";
import AnswerKey from "../pages/answerKeys";
import AdmitCardList from "../pages/admitCardList";
import AdmitCard from "../pages/admitCard";
import ObjectionDetails from "../pages/objectionDetails";
import BilldeskStatus from "../common/billdeskStatus";
import PayUStatus from "../common/payuStatus";
import ResetPassword from "../pages/resetPassword";

const CandidateRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="signup" element={<PublicRoute><Signup /></PublicRoute>} />
        <Route path="forgot-password" element={<PublicRoute><ForgetPassword /></PublicRoute>} />
        <Route path="verify-email" element={<PublicRoute><EmailVerification /></PublicRoute>} />
        <Route path="email-verified-status" element={<PublicRoute><EmailVerificationSuccess /></PublicRoute>} />
        <Route path="mobileno-verification" element={<PublicRoute><MobileVerificationStatus /></PublicRoute>} />
        <Route path="new-password" element={<PublicRoute><NewPasswordMail /></PublicRoute>} />
        <Route path="forgot-password-status" element={<PublicRoute><ForgotPasswordStatus /></PublicRoute>} />
        <Route path="verify-mobileno" element={<PublicRoute><MobileVerification /></PublicRoute>} />
        <Route path="home" element={<PrivateRoute><LandingPage /></PrivateRoute>} />
        <Route path="registration" element={<PrivateRoute><Registration /></PrivateRoute>} />
        <Route path="candidate-details" element={<PrivateRoute><CandidateDetails /></PrivateRoute>} />
        <Route path="course-registration" element={<PrivateRoute><CourseRegistration/></PrivateRoute>}/>
        {/* <Route path="course-selection" element={<PrivateRoute><CourseSelection /></PrivateRoute>} /> */}
        <Route path="course-selection" element={<PrivateRoute><CourseSelection /></PrivateRoute>} />
        <Route path="query-list" element={<QueryList />} />
        <Route path="raise-query" element={<PrivateRoute><RaiseQuery /></PrivateRoute>} />
        <Route path="pre-request" element={<PrivateRoute><PreRequestPage/></PrivateRoute>} />
        <Route path="confirm-payment" element={<PrivateRoute><PaymentConfirmation/></PrivateRoute>} />
        <Route path="instruction" element={<PrivateRoute><Instruction/></PrivateRoute>} />
        <Route path="payment-receipts" element={<PrivateRoute><PaymentReciptList /></PrivateRoute>} />
        <Route path="payment-receipt-view/:id" element={<PrivateRoute><PaymentReceiptView /></PrivateRoute>} />
        <Route path="objection-tracker" element={<PrivateRoute><ObjectionTrackerDashboard /></PrivateRoute>} />
        <Route path="objection-tracker-view" element={<PrivateRoute><ObjectionTrackerView /></PrivateRoute>} />
        <Route path="objection-details" element={<PrivateRoute><ObjectionDetails/></PrivateRoute>} />
        <Route path="answer-key" element={<PrivateRoute><AnswerKey /></PrivateRoute>} />
        <Route path="pre-request" element={<PrivateRoute><PreRequestPage /></PrivateRoute>} />
        <Route path="confirm-payment" element={<PrivateRoute><PaymentConfirmation /></PrivateRoute>} />
        <Route path="billdesk-status" element={<PrivateRoute><BilldeskStatus /></PrivateRoute>} />
        <Route path="payu-status" element={<PrivateRoute><PayUStatus /></PrivateRoute>} />
        <Route path="instruction" element={<PrivateRoute><Instruction /></PrivateRoute>} />
        <Route path="admit-card-list" element={<AdmitCardList/>} />
        <Route path="admit-card" element={<AdmitCard/>} />
        <Route path="reset-password" element={<ResetPassword/>} />
        {/* <Route path="payment-receipts" element={<PrivateRoute><PaymentReceiptList /></PrivateRoute>} /> */}
        {/* <Route path="payment-receipt-view" element={<PrivateRoute><PaymentReceiptView /></PrivateRoute>} /> */}
      </Routes>
    </>
  );
};

export default CandidateRoutes; 
