/* --- MHCET V3 --- */
import React from "react";
import { Button } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";

export default function SecondaryButton({ text, disabled, handleClick }) {
  return (
    <Button
      variant="contained"
      sx={{
        width: "100%",
        boxShadow: "none",
        fontWeight:"bold",
        border: `1px solid ${cssProperties?.bordercolor?.primary}`,
        color : `${cssProperties?.color?.primary}`,
        backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
        textTransform: "none",
        "&:hover" : {
          backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`
        }
      }}
      disabled={disabled}
      onClick={text !== "loader" && handleClick}
      disableElevation
    >
      {text}
    </Button>
  );
}
