import React from 'react'
import Index from '../components/ObjectionTrackerView'
import Layout from '../layouts/layout'
import Header from '../layouts/header'
import Sidebar from '../layouts/sideBar'

const ObjectionTrackerView = () => {
  return (
    <>  <Layout>
    <Header />
    <Sidebar />
    <Index />
</Layout>   
</>
  )
}

export default ObjectionTrackerView