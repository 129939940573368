import React from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import CancelButton from '../../common/button/cancelButton';
import videoUrl from '../../assets/images/file_example_MP4_480_1_5MG.mp4';
import { PiX } from "react-icons/pi";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '64px 0',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 660,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: '24px',
    borderRadius: '8px'
};

export default function HelpManualVideoModal(props) {

    const { handleClose, showModal } = props;

    return (
        <Modal
            open={showModal}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowY: 'auto' }}
        >
            <Box sx={style}>
                <Box display="flex" alignItems="center" marginBottom='24px'>
                    <Box flexGrow={1} ><Typography variant="h5" sx={{ color: `#212121`, fontWeight: '500', fontSize: '20px', }}>Help Manual</Typography></Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <PiX />
                        </IconButton>
                    </Box>
                </Box>

                <Box sx={{ height: '300px' }}>
                    <iframe
                        width="100%"
                        height="100%"
                        src={videoUrl}
                        // frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Video"
                    ></iframe>

                </Box>
                {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginTop: '32px' }}>
                    <CancelButton handleBack={handleClose} title={'Close'} />
                </Box> */}
            </Box>
        </Modal >
    )
};