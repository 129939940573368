import React from 'react'
import Index from '../components/ObjectionTrackerDashboard'
import Layout from '../layouts/layout'
import Header from '../layouts/header'
import Sidebar from '../layouts/sideBar'

const ObjectionTrackerDashboard = () => {
  return (
    <>
       <Layout>
                <Header />
                <Sidebar />
                <Index />
            </Layout>   

    </>
  )
}

export default ObjectionTrackerDashboard
