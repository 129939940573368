import React from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import PageHeading from "../../layouts/pageHeading";
import { cssProperties } from "../../utils/commonCssProperties";

export default function ForgotPasswordStatus() {
  const navigate = useNavigate();
  const handleRelogin = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/login`);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: '8rem' }}>
      <Box m={2} p={3} sx={{ backgroundColor: "#fff", borderRadius: "8px", width: "35rem" }}>
        <PageHeading page={'New Password'} />
        <Box sx={{marginTop: '12px'}}>New password has been sent to your email id</Box>
        <Box sx={{marginTop: '12px', display: 'flex', alignItems: 'center', gap: '6px'}}>
          <Typography
            style={{
              color: `${cssProperties?.color?.primary}`,
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={handleRelogin}
          >
            Click here
          </Typography>{" "}
          to re-login.{" "}
        </Box>
      </Box>
    </Box>
  );
}
