import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";

const Layout = ({ children }) => {
  const { showSidebar } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Box
        sx={{
          width: `calc(100% - ${showSidebar} ? ${cssProperties?.drawerWidth}px) : "0px" `,
          ml: `${cssProperties?.drawerWidth}px`,
          height: `calc(100% - ${cssProperties?.headerHight}px)`,
          mt: `${cssProperties?.headerHight}px`,
          padding: "0 24px 15px 24px"
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default Layout;
