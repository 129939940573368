import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Box,
  colors,
} from "@mui/material";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ButtonLoader from "../../common/buttonLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  AlphabetsValidation,
  InputTrimAndLowercaseFunction,
  InputTrimFunction,
  MobileNumberFirstNumberValidation,
  MobileNumberValidation,
  handleEmailValidation,
  passwordValidation,
} from "../../utils/validations";
import authActions from "../../redux/auth/actions";
import PageHeading from "../../layouts/pageHeading";
import { cssProperties } from "../../utils/commonCssProperties";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarIcon, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { PiDotOutlineFill } from "react-icons/pi";
import BeforeLoginInformation from "../../common/beforeLoginInformation";
import PrimaryButton from "../../common/button/primaryButton";
import EmailVerification from "./emailAndMobileVerificationModel";
import EmailVerifications from "./emailAndMobileVerificationModel";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFieldFocus = async (currentFieldName, previousFieldName) => {
    if (previousFieldName) {
      const isValid = await trigger(previousFieldName);
      if (!isValid) {
        // If the previous field is invalid, focus back on it
        const previousField = document.querySelector(
          `input[name="${previousFieldName}"]`
        );
        if (previousField) {
          previousField.focus();
        }
        return false; // Prevent focus on the current field
      }
    }
    return true; // Allow focus on the current field
  };
  const { buttonLoader } = useSelector((state) => state.commonReducer);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  const [passState, setPassState] = useState(false);
  const [passState2, setPassState2] = useState(false);

  const onSubmit = (data) => {
    dispatch({
      type: authActions.CANDIDATE_SINUP,
      payload: { navigate: navigate, data: data },
    });
  };

  const handlePasswordChange = async (e) => {
    const { name } = e.target;

    await trigger(name);
  };

  const setAndClearError = ({ type, statusCode, message }) => {
    if (statusCode === 409) {
      setError(type, { message: message });

    }
    else {
      clearErrors(type, "");
    }
  };


  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  const handleCheckDuplicate = (type) => {
    if (getValues(type)) {
      dispatch({
        type: authActions.CHECK_DUPLICATE_EMAIL_OR_MOBILENO,
        payload: {
          data: { type: type, value: getValues(type) },
          setAndClearError: setAndClearError,
        },
      });
    }
  };

  const debouncedCheck = debounce(handleCheckDuplicate, 100);

  const textFieldTitleStyle = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16.94px",
    textAlign: "left",
    color: `${cssProperties?.color?.tertiary}`,
    paddingBottom: "4px",
  };

  const textFieldInfoStyle = {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14.52px",
    textAlign: "left",
    color: `${cssProperties?.color?.primary2}`,
  };
  return (
    // <Box
    //   sx={{
    //     height: "100vh",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     margin: "84px auto",
    //   }}
    // >
    //   <Grid container sx={{ display: "flex", justifyContent: "center" }}>
    //     <Grid
    //       item
    //       md={8}
    //       sm={11}
    //       sx={{
    //         borderRadius: "8px",
    //         padding: "24px",

    //         backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
    //       }}
    //     >
    //       <form onSubmit={handleSubmit(onSubmit)}>
    //         <Box sx={{ marginBottom: "24px" }}>
    //           <PageHeading page={"Candidate Sign Up"} />
    //         </Box>
    //         <Grid container spacing={2}>
    //           <Grid item xs={12} md={12}>
    //             <TextField
    //               {...register("candidatename", {
    //                 required: "full name is required",
    //               })}
    //               onInput={AlphabetsValidation}
    //               inputProps={{
    //                 style: { height: "56px", boxSizing: "border-box" },
    //                 maxLength: 50,
    //               }}
    //               label={
    //                 <span>
    //                   Enter your full name
    //                   <span className="error" style={{ color: "red" }}>
    //                     {" "}
    //                     *
    //                   </span>
    //                 </span>
    //               }
    //               placeholder="Enter your full name"
    //               fullWidth
    //               error={!!errors.candidatename}
    //               helperText={
    //                 errors.candidatename ? errors.candidatename.message : ""
    //               }
    //               FormHelperTextProps={{
    //                 style: { margin: 0 },
    //               }}
    //             />
    //           </Grid>
    //           {/* <Grid item xs={12} md={6}>
    //             <TextField

    //               {...register("lastname", {
    //                 required: "Last name is required",
    //               })}
    //               onInput={AlphabetsValidation}
    //               inputProps={{style:{height:'56px',boxSizing:'border-box'}, maxLength: 50 }}
    //               label={
    //                 <span>
    //                   Last name
    //                   <span className="error" style={{ color: "red" }}>
    //                     {" "}
    //                     *
    //                   </span>
    //                 </span>
    //               }
    //               placeholder="Enter last name"
    //               fullWidth
    //               error={!!errors.lastname}
    //               helperText={errors.lastname ? errors.lastname.message : ""}
    //               FormHelperTextProps={{
    //                 style: { margin: 0 },
    //               }}
    //             />
    //           </Grid> */}
    //           <Grid item xs={12} sx={{ paddingTop: "4px !important" }}>
    //             <Typography
    //               variant="body2"
    //               sx={{ color: `#666666`, fontSize: "12px", fontWeight: 400 }}
    //             >
    //               Add your name as per SSC Marksheet or Certificate
    //               {/* In case, your full name only consists of one word (without
    //               surname), please enter your name as "NAME." (Name"Dot"). */}
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={12} md={6}>
    //             <TextField
    //               {...register("emailid", {
    //                 required: "Email id is required",
    //                 validate: handleEmailValidation,
    //               })}
    //               onFocus={() => handleFieldFocus("emailid", "candidatename")}
    //               onKeyUp={() =>  debouncedCheck('emailid')
    //               }
    //               onInput={(e)=>{InputTrimAndLowercaseFunction(e);
    //                 trigger("emailid");
    //               }}
    //               inputProps={{
    //                 style: { height: "56px", boxSizing: "border-box" },
    //                 maxLength: 50,
    //               }}
    //               label={
    //                 <span>
    //                   Email id
    //                   <span className="error" style={{ color: "red" }}>
    //                     {" "}
    //                     *
    //                   </span>
    //                 </span>
    //               }
    //               placeholder="Enter email id"
    //               fullWidth
    //               error={!!errors.emailid}
    //               helperText={errors.emailid ? errors.emailid.message : ""}
    //               FormHelperTextProps={{
    //                 style: { margin: 0 },
    //               }}
    //             />
    //           </Grid>
    //           <Grid item xs={12} md={6}>
    //             <TextField
    //               {...register("mobileno", {
    //                 required: "Mobile number is required",
    //                 minLength: {
    //                   value: 10,
    //                   message: "Enter valid mobile number",
    //                 },
    //                 validate: MobileNumberFirstNumberValidation,
    //               })}
    //               onFocus={() => handleFieldFocus("mobileno", "emailid")}
    //               onKeyUp={() =>  debouncedCheck('mobileno')}
    //               onInput={MobileNumberValidation}
    //               inputProps={{
    //                 style: { height: "56px", boxSizing: "border-box" },
    //                 maxLength: 10,
    //               }}
    //               label={
    //                 <span>
    //                   Mobile no
    //                   <span className="error" style={{ color: "red" }}>
    //                     {" "}
    //                     *
    //                   </span>
    //                 </span>
    //               }
    //               placeholder="Enter mobile number"
    //               fullWidth
    //               error={!!errors.mobileno}
    //               helperText={errors.mobileno ? errors.mobileno.message : ""}
    //               FormHelperTextProps={{
    //                 style: { margin: 0 },
    //               }}
    //             />
    //           </Grid>
    //           <Grid item xs={12} md={6}>
    //             <TextField
    //               {...register("temppassword", {
    //                 validate: { passwordValidation },
    //                 required: "Password is required",
    //               })}
    //               onFocus={() => handleFieldFocus("temppassword", "mobileno")}
    //               onKeyUp={handlePasswordChange}
    //               onInput={InputTrimFunction}
    //               inputProps={{
    //                 style: { height: "56px", boxSizing: "border-box" },
    //                 maxLength: 16,
    //               }}
    //               type={passState ? "text" : "password"}
    //               label={
    //                 <span>
    //                   Password
    //                   <span className="error" style={{ color: "red" }}>
    //                     {" "}
    //                     *
    //                   </span>
    //                 </span>
    //               }
    //               placeholder="Enter password"
    //               fullWidth
    //               error={!!errors.temppassword}
    //               helperText={
    //                 errors.temppassword ? errors.temppassword.message : ""
    //               }
    //               FormHelperTextProps={{
    //                 style: { margin: 0 },
    //               }}
    //               InputProps={{
    //                 style: { height: "56px", boxSizing: "border-box" },
    //                 endAdornment: (
    //                   <InputAdornment position="end">
    //                     <IconButton
    //                       onClick={() => setPassState(!passState)}
    //                       sx={{ paddingRight: "0" }}
    //                     >
    //                       {passState ? (
    //                         <FiEyeOff size={18} />
    //                       ) : (
    //                         <FiEye size={18} />
    //                       )}
    //                     </IconButton>
    //                   </InputAdornment>
    //                 ),
    //               }}
    //             />
    //           </Grid>
    //           <Grid item xs={12} md={6}>
    //             <TextField
    //               {...register("confirmpassword", {
    //                 required: "Confirm password is required",
    //                 validate: (val) => {
    //                   if (watch("temppassword") !== val) {
    //                     return "Passwords do not match";
    //                   }
    //                 },
    //               })}
    //               onFocus={() =>
    //                 handleFieldFocus("confirmpassword", "temppassword")
    //               }
    //               onInput={InputTrimFunction}
    //               onKeyUp={handlePasswordChange}
    //               inputProps={{
    //                 style: { height: "56px", boxSizing: "border-box" },
    //                 maxLength: 16,
    //               }}
    //               type={passState2 ? "text" : "password"}
    //               label={
    //                 <span>
    //                   Confirm Password
    //                   <span className="error" style={{ color: "red" }}>
    //                     {" "}
    //                     *
    //                   </span>
    //                 </span>
    //               }
    //               placeholder="Enter password"
    //               fullWidth
    //               error={!!errors.confirmpassword}
    //               helperText={
    //                 errors.confirmpassword ? errors.confirmpassword.message : ""
    //               }
    //               FormHelperTextProps={{
    //                 style: { margin: 0 },
    //               }}
    //               InputProps={{
    //                 style: { height: "56px", boxSizing: "border-box" },
    //                 endAdornment: (
    //                   <InputAdornment position="end">
    //                     <IconButton
    //                       onClick={() => setPassState2(!passState2)}
    //                       sx={{ paddingRight: "0" }}
    //                     >
    //                       {passState2 ? (
    //                         <FiEyeOff size={18} />
    //                       ) : (
    //                         <FiEye size={18} />
    //                       )}
    //                     </IconButton>
    //                   </InputAdornment>
    //                 ),
    //               }}
    //             />
    //           </Grid>
    //           <Grid
    //             item
    //             xs={12}
    //             md={12}
    //             sx={{
    //               display: "flex",
    //               flexDirection: "column",
    //               paddingTop: "4px !important",
    //             }}
    //           >
    //             <Typography
    //               variant="body2"
    //               sx={{ color: `#666666`, fontSize: "12px", fontWeight: 600 }}
    //             >
    //               Instructions:
    //             </Typography>
    //             <Typography
    //               variant="body2"
    //               sx={{
    //                 color: `#666666`,
    //                 fontSize: "12px",
    //                 fontWeight: 400,
    //                 paddingLeft: "1rem",
    //                 display: "flex",
    //                 alignItems: "center",
    //               }}
    //             >
    //               <PiDotOutlineFill /> Password must be 8 Character long
    //             </Typography>
    //             <Typography
    //               variant="body2"
    //               sx={{
    //                 color: `#666666`,
    //                 fontSize: "12px",
    //                 fontWeight: 400,
    //                 paddingLeft: "1rem",
    //                 display: "flex",
    //                 alignItems: "center",
    //               }}
    //             >
    //               <PiDotOutlineFill /> One special character mandatory
    //             </Typography>
    //             <Typography
    //               variant="body2"
    //               sx={{
    //                 color: `#666666`,
    //                 fontSize: "12px",
    //                 fontWeight: 400,
    //                 paddingLeft: "1rem",
    //                 display: "flex",
    //                 alignItems: "center",
    //               }}
    //             >
    //               <PiDotOutlineFill /> One Digit is must
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={12} md={6}>
    //             <LocalizationProvider dateAdapter={AdapterDayjs}>
    //               <DatePicker
    //                 fullWidth
    //                 name="dob"
    //                 id="dob"
    //                 {...register("dob", {
    //                   required: "Date of Birth is required",
    //                   validate: {
    //                     validDate: (date) => {
    //                       const isValid =
    //                         date &&
    //                         dayjs(date).isBetween(
    //                           // dayjs("2000-01-01"),
    //                           // dayjs(),
    //                           // dayjs("1900-01-01"),
    //                           // dayjs("2000-12-31"),
    //                           dayjs("1900-01-01"),
    //                           dayjs().subtract(15, 'year').endOf('year'),
    //                           null,
    //                           "[]"
    //                         );
    //                       return (
    //                         isValid ||
    //                         `Date must be between ${dayjs("1900-01-01").format('DD-MM-YYYY')} and ${dayjs().subtract(15, 'year').endOf('year').format('DD-MM-YYYY')}`
    //                       );
    //                     },
    //                   },
    //                 })}
    //                 onChange={(date) => {
    //                   setValue("dob", date, { shouldValidate: true });
    //                 }}
    //                 format="DD/MM/YYYY"
    //                 // minDate={dayjs("2000-01-01")}
    //                 // maxDate={dayjs()}
    //                 // maxDate={dayjs("2000-12-31")}
    //                 // minDate={dayjs("1900-01-01")}
    //                 maxDate={dayjs().subtract(15, 'year').endOf('year')}
    //                 minDate={dayjs("1900-01-01")}
    //                 slotProps={{
    //                   layout: {
    //                     sx: {
    //                       borderRadius: "58px",
    //                     },
    //                   },
    //                   textField: {
    //                     helperText: errors.dob ? errors.dob.message : "",
    //                     onFocus: () =>
    //                       handleFieldFocus("dob", "confirmpassword"),
    //                     error: !!errors.dob,
    //                     style: {
    //                       width: "100%",
    //                       borderRadius: "8px",
    //                       height: "56px",
    //                       boxSizing: "border-box",
    //                     },

    //                     FormHelperTextProps: {
    //                       style: {
    //                         margin: 0,
    //                       },
    //                     },
    //                   },
    //                 }}
    //                 label={
    //                   <span>
    //                     DOB
    //                     <span className="error" style={{ color: "red" }}>
    //                       {" "}
    //                       *
    //                     </span>
    //                   </span>
    //                 }
    //                 renderInput={(params) => <TextField {...params} />}
    //               />
    //             </LocalizationProvider>
    //           </Grid>
    //         </Grid>
    //         <Box sx={{ textAlign: "center", marginTop: "24px" }}>
    //           <BluePrimaryButton
    //             type="submit"
    //             title={buttonLoader ? <ButtonLoader /> : "Sign up"}
    //             onFocus={() => handleFieldFocus("submit-button", "dob")}
    //           />
    //         </Box>
    //         <Box
    //           display="flex"
    //           justifyContent="center"
    //           alignItems="center"
    //           mt={2}
    //         >
    //           <Typography variant="body2">Already have an account?</Typography>
    //           <Link
    //             to={`/${Cookies.get("mhet_cnd_project")}/login`}
    //             style={{
    //               marginLeft: 4,
    //               fontWeight: 600,
    //               color: `${cssProperties?.bordercolor?.primary2blue}`,
    //               fontSize: "14px",
    //               textDecoration: "none",
    //               textTransform: "uppercase",
    //             }}
    //           >
    //             Login
    //           </Link>
    //         </Box>
    //       </form>
    //     </Grid>
    //   </Grid>
    // </Box>
    <>
      <Grid container spacing={3} sx={{ padding: "20px 50px" }}>
        <Grid item lg={8} md={8} xs={12}>
          <Grid
            sx={{
              background: `${cssProperties?.backgroundcolor?.primary2}`,
              border: `1px solid ${cssProperties?.bordercolor?.tertiary}`,
              borderRadius: "21px 21px 10px 10px",
              minHeight: "568px",
              boxShadow: "2px 3px 12px 0px #0F4F9666",
            }}
          >
            <Grid padding={"24px"}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ marginBottom: "16px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: "bold",
                      lineHeight: "24.2px",
                      textAlign: "left",
                      color: `${cssProperties?.color?.secondary2}`,
                    }}
                  >
                    Register
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography style={textFieldTitleStyle}>
                      Candidate’s Full Name (As per SSC/HSC marksheet)<span style={{ color: `${cssProperties?.color?.failure}` }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      {...register("candidatename", {
                        required: "Full name is required",
                      })}
                      onInput={AlphabetsValidation}
                      inputProps={{
                        // style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      // label={
                      //   <span>
                      //     Enter your full name
                      //     <span className="error" style={{ color: "red" }}>
                      //       {" "}
                      //       *
                      //     </span>
                      //   </span>
                      // }
                      placeholder="Enter your full name"
                      fullWidth
                      error={!!errors.candidatename}
                      helperText={
                        errors.candidatename ? errors.candidatename.message : ""
                      }
                      FormHelperTextProps={{
                        style: { margin: 0 },
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                <TextField

                  {...register("lastname", {
                    required: "Last name is required",
                  })}
                  onInput={AlphabetsValidation}
                  inputProps={{style:{height:'56px',boxSizing:'border-box'}, maxLength: 50 }}
                  label={
                    <span>
                      Last name
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    </span>
                  }
                  placeholder="Enter last name"
                  fullWidth
                  error={!!errors.lastname}
                  helperText={errors.lastname ? errors.lastname.message : ""}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                />
              </Grid> */}
                  <Grid item xs={12} sx={{ paddingTop: "4px !important" }}>
                    <Typography sx={textFieldInfoStyle}>
                      Add your name as per SSC Marksheet or Certificate In case,
                      your full name only consists of one word (without
                      surname), please enter your name as "NAME." (Name"Dot").
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography style={textFieldTitleStyle}>Email*</Typography>
                    <TextField
                      size="small"
                      {...register("emailid", {
                        required: "Email id is required",
                        validate: handleEmailValidation,
                      })}
                      onFocus={() =>
                        handleFieldFocus("emailid", "candidatename")
                      }
                      onKeyUp={() => debouncedCheck("emailid")}
                      onInput={(e) => {
                        InputTrimAndLowercaseFunction(e);
                        trigger("emailid");
                      }}
                      inputProps={{
                        maxLength: 50,
                      }}
                      // label={
                      //   <span>
                      //     Email id
                      //     <span className="error" style={{ color: "red" }}>
                      //       {" "}
                      //       *
                      //     </span>
                      //   </span>
                      // }
                      placeholder="Enter email id"
                      fullWidth
                      error={!!errors.emailid}
                      helperText={errors.emailid ? errors.emailid.message : ""}
                      FormHelperTextProps={{
                        style: { margin: 0 },
                      }}
                    />
                    <Grid item xs={12} sx={{ paddingTop: "4px !important" }}>
                      <Typography sx={textFieldInfoStyle}>
                        Enter an active email ID, as the same will be verified
                        and used for your profile creation.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography style={textFieldTitleStyle}>
                      Mobile Number*
                    </Typography>
                    <TextField
                      disabled={!!errors.emailid}
                      size="small"
                      {...register("mobileno", {
                        required: "Mobile number is required",
                        minLength: {
                          value: 10,
                          message: "Enter valid mobile number",
                        },
                        validate: MobileNumberFirstNumberValidation,
                      })}
                      onFocus={() => handleFieldFocus("mobileno", "emailid")}
                      onKeyUp={() => debouncedCheck("mobileno")}
                      onInput={MobileNumberValidation}
                      inputProps={{
                        // style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 10,
                      }}
                      // label={
                      //   <span>
                      //     Mobile no
                      //     <span className="error" style={{ color: "red" }}>
                      //       {" "}
                      //       *
                      //     </span>
                      //   </span>
                      // }
                      placeholder="Enter mobile number"
                      fullWidth
                      error={!!errors.mobileno}
                      helperText={
                        errors.mobileno ? errors.mobileno.message : ""
                      }
                      FormHelperTextProps={{
                        style: { margin: 0 },
                      }}
                    />
                    <Grid item xs={12} sx={{ paddingTop: "4px !important" }}>
                      <Typography sx={textFieldInfoStyle}>
                        Enter an active mobile number, as the same will be
                        verified and used for your profile creation.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography style={textFieldTitleStyle}>
                      Password*
                    </Typography>
                    <TextField
                      size="small"
                      {...register("temppassword", {
                        validate: { passwordValidation },
                        required: "Password is required",
                      })}
                      onFocus={() =>
                        handleFieldFocus("temppassword", "mobileno")
                      }
                      onKeyUp={handlePasswordChange}
                      onInput={InputTrimFunction}
                      inputProps={{
                        // style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 16,
                      }}
                      type={passState ? "text" : "password"}
                      // label={
                      //   <span>
                      //     Password
                      //     <span className="error" style={{ color: "red" }}>
                      //       {" "}
                      //       *
                      //     </span>
                      //   </span>
                      // }
                      placeholder="Enter password"
                      fullWidth
                      error={!!errors.temppassword}
                      helperText={
                        errors.temppassword ? errors.temppassword.message : ""
                      }
                      FormHelperTextProps={{
                        style: { margin: 0 },
                      }}
                      InputProps={{
                        // style: { height: "56px", boxSizing: "border-box" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setPassState(!passState)}
                              sx={{ paddingRight: "0" }}
                            >
                              {passState ? (
                                <FiEyeOff size={18} />
                              ) : (
                                <FiEye size={18} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Grid item xs={12} sx={{ paddingTop: "4px !important" }}>
                      <Typography sx={textFieldInfoStyle}>
                        Password should be between 8 to 16 characters. It must
                        include at least one uppercase, one lowercase, one
                        number and one special character.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography style={textFieldTitleStyle}>
                      Confirm password*
                    </Typography>
                    <TextField
                      size="small"
                      {...register("confirmpassword", {
                        required: "Confirm password is required",
                        validate: (val) => {
                          if (watch("temppassword") !== val) {
                            return "Passwords do not match";
                          }
                        },
                      })}
                      onFocus={() =>
                        handleFieldFocus("confirmpassword", "temppassword")
                      }
                      onInput={InputTrimFunction}
                      onKeyUp={handlePasswordChange}
                      inputProps={{
                        // style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 16,
                      }}
                      type={passState2 ? "text" : "password"}
                      // label={
                      //   <span>
                      //     Confirm Password
                      //     <span className="error" style={{ color: "red" }}>
                      //       {" "}
                      //       *
                      //     </span>
                      //   </span>
                      // }
                      placeholder="Enter password"
                      fullWidth
                      error={!!errors.confirmpassword}
                      helperText={
                        errors.confirmpassword
                          ? errors.confirmpassword.message
                          : ""
                      }
                      FormHelperTextProps={{
                        style: { margin: 0 },
                      }}
                      InputProps={{
                        // style: { height: "56px", boxSizing: "border-box" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setPassState2(!passState2)}
                              sx={{ paddingRight: "0" }}
                            >
                              {passState2 ? (
                                <FiEyeOff size={18} />
                              ) : (
                                <FiEye size={18} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "4px !important",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: `#666666`,
                        fontSize: "12px",
                        fontWeight: 600,
                      }}
                    >
                      Instructions:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: `#666666`,
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingLeft: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PiDotOutlineFill /> Password must be 8 Character long
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: `#666666`,
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingLeft: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PiDotOutlineFill /> One special character mandatory
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: `#666666`,
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingLeft: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PiDotOutlineFill /> One Digit is must
                    </Typography>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <Typography style={textFieldTitleStyle}>
                      {" "}
                      Date of Birth*{" "}
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        name="dob"
                        id="dob"
                        {...register("dob", {
                          required: "Date of Birth is required",
                          validate: {
                            validDate: (date) => {
                              const isValid =
                                date &&
                                dayjs(date).isBetween(
                                  // dayjs("2000-01-01"),
                                  // dayjs(),
                                  // dayjs("1900-01-01"),
                                  // dayjs("2000-12-31"),
                                  dayjs("1900-01-01"),
                                  dayjs().subtract(15, "year").endOf("year"),
                                  null,
                                  "[]"
                                );
                              return (
                                isValid ||
                                `Date must be between ${dayjs(
                                  "1900-01-01"
                                ).format("DD-MM-YYYY")} and ${dayjs()
                                  .subtract(15, "year")
                                  .endOf("year")
                                  .format("DD-MM-YYYY")}`
                              );
                            },
                          },
                        })}
                        onChange={(date) => {
                          setValue("dob", date, { shouldValidate: true });
                        }}
                        format="DD/MM/YYYY"
                        // minDate={dayjs("2000-01-01")}
                        // maxDate={dayjs()}
                        // maxDate={dayjs("2000-12-31")}
                        // minDate={dayjs("1900-01-01")}
                        maxDate={dayjs().subtract(15, "year").endOf("year")}
                        minDate={dayjs("1900-01-01")}
                        slotProps={{
                          layout: {
                            sx: {
                              borderRadius: "58px",
                            },
                          },
                          textField: {
                            size: "small",
                            helperText: errors.dob ? errors.dob.message : "",
                            onFocus: () =>
                              handleFieldFocus("dob", "confirmpassword"),
                            error: !!errors.dob,
                            style: {
                              width: "100%",
                              borderRadius: "8px",
                              // height: "56px",
                              // boxSizing: "border-box",
                              // color: `${cssProperties?.color?.primary}`
                            },
                            // InputProps: {
                            //   endAdornment: (
                            //     <InputAdornment position="end">
                            //       <CalendarIcon style={{ color: `${cssProperties?.color?.primary}` }} /> 
                            //     </InputAdornment>
                            //   ),
                            // },
                            FormHelperTextProps: {
                              style: {
                                margin: 0,
                              },
                            },
                          },
                        }}
                        // label={
                        //   <span>
                        //     DOB
                        //     <span className="error" style={{ color: "red" }}>
                        //       {" "}
                        //       *
                        //     </span>
                        //   </span>
                        // }
                        renderInput={(params) => <TextField {...params} />}

                      />
                    </LocalizationProvider>
                    <Grid item xs={12} sx={{ paddingTop: "4px !important" }}>
                      <Typography sx={textFieldInfoStyle}>
                        Enter your Date of Birth as per your SSC certificate.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box>
                  <Typography
                    onClick={() =>
                      navigate(`/${Cookies.get("mhet_cnd_project")}/login`)
                    }
                    sx={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: `${cssProperties?.color?.primary}`,
                      paddingTop: "8px"
                    }}
                  >
                    Already Registered? Back to Login
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center", marginTop: "16px" }}>
                  <PrimaryButton
                    type="submit"
                    text={buttonLoader ? <ButtonLoader /> : "Continue"}
                    onFocus={() => handleFieldFocus("submit-button", "dob")}
                  />
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <BeforeLoginInformation />
        </Grid>
        <EmailVerifications />
      </Grid>

    </>
  );
}
