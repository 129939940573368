import { Box, Pagination } from "@mui/material";
import React from "react";

export default function TablePagination({pageCount, handlePageChange, currentPage}) {

    return (
        <Box sx={{ marginTop: '24px', display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination
                count={pageCount}
                page={currentPage} 
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                sx={{
                    '& .MuiPaginationItem-root': {
                        backgroundColor: 'rgba(207, 213, 241, 1)',
                        color: 'rgba(0, 0, 0, 1)',
                        fontWeight: '500',
                        borderRadius: '10px',
                        borderColor: 'transparent'
                    },
                    '& .MuiPaginationItem-root.Mui-selected': {
                        backgroundColor: 'rgba(63, 65, 209, 1)',
                        color: 'rgba(255, 255, 255, 1)'
                    },
                    '& .MuiPaginationItem-root.Mui-selected:hover': {
                        backgroundColor: 'rgba(63, 65, 209, 1)',
                        color: 'rgba(255, 255, 255, 1)'
                    },
                    '& .MuiPaginationItem-root:hover': {
                        backgroundColor: 'rgba(63, 65, 209, 1)',
                        color: 'rgba(255, 255, 255, 1)'
                    },
                    // '&:hover': {
                    //     backgroundColor: 'rgba(63, 65, 209, 1)',
                    //     color: 'rgba(255, 255, 255, 1)'
                    // },
                    '& .MuiPaginationItem-ellipsis': {
                        height: '32px',
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                    },
                }}
            />
        </Box>
    )
};