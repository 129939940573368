import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/action";
import Cookies from "js-cookie";
import {
  AlphabetsValidation,
  percentageNumberValidation,
  percentageValidation,
} from "../../utils/validations";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../common/backButton";
import SubmitButton from "../../common/submitButton";
import candidatesActions from "../../redux/candidates/actions";
import { masterFieldLabel, isFieldDisabled } from "../../utils/courseValues";
import { LocalizationProvider,DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/de';


export default function AcademicDetailsForm({
  nextPageName,
  previousPageName,
}) {
  console.log(nextPageName);
  const [years, setYears] = useState([]);
  const location = useLocation();
  const courseid = location.state?.courseid;
  const { state, board } = useSelector((state) => state.masterReducer);
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { masterFields } = useSelector((state) => state.candidatesReducer);
  const { courseFullRegistrationDetails } = useSelector(
    (state) => state.candidatesReducer
  );

  console.log('masterFields----',masterFields);
  console.log(courseFullRegistrationDetails);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const date = new Date();
  const currentYear = date.getFullYear();

  useEffect(() => {
    dispatch({ type: masterActions.GET_STATE });
    dispatch({ type: masterActions.GET_BOARD });
    const getYears = () => {
      const array = [];
      for (let i = 2000; i <= currentYear; i++) {
        array.push(i);
      }
      return array;
    };
    setYears(getYears());
  }, []);

  const handleProcced = (data) => {
    const corcandidateacademicid =
      courseFullRegistrationDetails[0]?.corcandidateacademicid;
    console.log(corcandidateacademicid);
    dispatch({
      type: candidatesActions.COURSE_ACADEMIC_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidateacademicid
            ? parseInt(corcandidateacademicid)
            : 0,
          candidateid: parseInt(candidateid),
          courseid: courseid,
          status: 1,
          createdby: parseInt(candidateid),
          updatedby: corcandidateacademicid ? parseInt(candidateid) : "",
        },
        navigate: navigate,
        nextPageName: nextPageName,
      },
    });
  };

  const handleBack = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${previousPageName}`,
      { state: { courseid: courseid } }
    );
  };

  useEffect(() => {
    if (courseFullRegistrationDetails?.length > 0) {
      reset({ ...courseFullRegistrationDetails[0] });
    }
  }, [courseFullRegistrationDetails]);

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: {
          courseid: courseid,
        },
      });
    }
  }, [candidateid]);

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);


// mark percentage calculation

const hscmarksobtained = getValues('hscmarksobtained');
const hscmarksoutof = getValues('hscmarksoutof');
console.log(hscmarksobtained);
console.log(hscmarksoutof);
function convertToPercentage(mark,totalMarks) {
  if(mark && totalMarks){
  
    if (mark < 0 || mark > 900) {
      return 
  }

  const percentage = (mark / totalMarks) * 100;
  
  return percentage.toFixed(1) + "%"
}
}
const markINpercent = convertToPercentage(hscmarksobtained,hscmarksoutof)

console.log('mark in percenteage---',convertToPercentage(hscmarksobtained,hscmarksoutof));

console.log('appearingfor---',getValues('appearingfor'));
console.log('hscmarksobtained---',getValues('hscmarksobtained'));



  return (
    <Grid
      p={3}
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={2}>
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_PASSED_FROM_INDIA
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.sscpassedfromindia}
            >
              <InputLabel
                shrink={!!watch("sscpassedfromindia")}
                htmlFor="sscpassedfromindia"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_PASSED_FROM_INDIA
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SSC_PASSED_FROM_INDIA
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="sscpassedfromindia"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SSC_PASSED_FROM_INDIA
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_PASSED_FROM_INDIA
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("sscpassedfromindia", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_PASSED_FROM_INDIA
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("sscpassedfromindia", e.target.value);
                  trigger("sscpassedfromindia");
                }}
                value={watch("sscpassedfromindia") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.SSC_PASSED_FROM_INDIA)}
              >
                <MenuItem value="">Select One</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscpassedfromindia && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscpassedfromindia.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
    
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_STATE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.sscschoolstateid}
            >
              <InputLabel
                shrink={!!watch("sscschoolstateid")}
                htmlFor="sscschoolstateid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_STATE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SSC_SCHOOL_STATE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="sscschoolstateid"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SSC_SCHOOL_STATE
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_STATE
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("sscschoolstateid", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_STATE
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("sscschoolstateid", e.target.value);
                  trigger("sscschoolstateid");
                }}
                value={watch("sscschoolstateid") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.SSC_SCHOOL_STATE)}
              >
                <MenuItem value="">Select One</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscschoolstateid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscschoolstateid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_DISTRICT
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.sscschooldistrict}
            >
              <InputLabel
                shrink={!!watch("sscschooldistrict")}
                htmlFor="sscschooldistrict"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_DISTRICT
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SSC_SCHOOL_DISTRICT
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="sscschooldistrict"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SSC_SCHOOL_DISTRICT
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_DISTRICT
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("sscschooldistrict", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_DISTRICT
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("sscschooldistrict", e.target.value);
                  trigger("sscschooldistrict");
                }}
                value={watch("sscschooldistrict") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.SSC_SCHOOL_DISTRICT)}
              >
                <MenuItem value="">Select One</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscschooldistrict && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscschooldistrict.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_TALUKA
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.sscschooltaluka}
            >
              <InputLabel
                shrink={!!watch("sscschooltaluka")}
                htmlFor="sscschooltaluka"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_TALUKA
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SSC_SCHOOL_TALUKA
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="sscschooltaluka"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SSC_SCHOOL_TALUKA
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_TALUKA
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("sscschooltaluka", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_TALUKA
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("sscschooltaluka", e.target.value);
                  trigger("sscschooltaluka");
                }}
                value={watch("sscschooltaluka") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.SSC_SCHOOL_TALUKA)}
              >
                <MenuItem value="">Select One</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscschooltaluka && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscschooltaluka.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}


        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_PASSING_YEAR
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.sscpassingyear}>
              <InputLabel
                shrink={!!watch("sscpassingyear")}
                htmlFor="sscpassingyear"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_PASSING_YEAR
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SSC_PASSING_YEAR
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="sscpassingyear"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SSC_PASSING_YEAR
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_PASSING_YEAR
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("sscpassingyear", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_PASSING_YEAR
                  )?.ismandatory === "1" ? "SSC Passing Year is required" : false,
                })}
                onChange={(e) => {
                  setValue("sscpassingyear", e.target.value);
                  trigger("sscpassingyear");
                }}
                value={watch("sscpassingyear") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.SSC_PASSING_YEAR)}
              >
                <MenuItem value="">Select One</MenuItem>
                {years?.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscpassingyear && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscpassingyear.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SSC_BOARD
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.sscboardid}>
              <InputLabel
                shrink={!!watch("sscboardid") || watch("sscboardid") === 0}
                htmlFor="sscboardid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SSC_BOARD
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.SSC_BOARD
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="sscboardid"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SSC_BOARD
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.SSC_BOARD
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("sscboardid", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SSC_BOARD
                  )?.ismandatory === "1" ? "SSC Board is required" : false,
                })}
                //   onChange={(e) => {
                //     const id = e.target.value;
                //     const filterId = board?.find(
                //       (row) => row.boardid === parseInt(id)
                //     );
                //     const finalId = filterId.boardstateid;
                //     setValue("sscschoolstateid", finalId);
                //   }}
                value={watch("sscboardid") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.SSC_BOARD)}
              >
                <MenuItem value="">Select One</MenuItem>
                {board?.map((val, i) => (
                  <MenuItem value={val?.boardid} key={i}>
                    {val?.board}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscboardid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscboardid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_NAME
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="SSC SCHOOL NAME*"
              {...register("sscschoolname", {
                required:true
              })}
              // onInput={percentageNumberValidation}
value={getValues('sscschoolname') }              
              error={!!errors.sscschoolname}
              helperText={errors.sscschoolname?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("sscschoolname"),
              }}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.SSC_SCHOOL_NAME)}
            />
            
          </Grid>
        )}


        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_TOTAL_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_TOTAL_PERCENTAGE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_TOTAL_PERCENTAGE
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="SSC/Equivalent Total Percentage*"
              {...register("ssctotalpercentage", {
                required:( getValues("appearingfor")!= 1  || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SSC_TOTAL_PERCENTAGE
                )?.ismandatory != "1" )? false : "SSC Total percentage is required",
                // validate: percentageValidation,
              })}
              onInput={percentageNumberValidation}
value={getValues('ssctotalpercentage')?.length > 2 ? 100 : getValues('ssctotalpercentage') }              
              error={!!errors.ssctotalpercentage}
              helperText={errors.ssctotalpercentage?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("ssctotalpercentage"),
              }}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.SSC_TOTAL_PERCENTAGE)}
            />
            <Box>
              <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small>
            </Box>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.APPEARING_FOR_HSC
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.isappeared}>
              <InputLabel
                shrink={!!watch("appearingfor") || watch("appearingfor") === 0}
                htmlFor="appearingfor"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.APPEARING_FOR_HSC
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.APPEARING_FOR_HSC
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="appearingfor"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.APPEARING_FOR_HSC
                        )?.fieldlabel
                      }  
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.APPEARING_FOR_HSC
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("appearingfor", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.APPEARING_FOR_HSC
                  )?.ismandatory === "1" ? "Select is required" : false,
                })}
                onChange={(e) => {
                  setValue("appearingfor", e.target.value);
                  trigger("appearingfor");
                }}
                value={watch("appearingfor") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.APPEARING_FOR_HSC)}
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.appearingfor && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.appearingfor.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}




{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_STATE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.hsccollegestate}
            >
              <InputLabel
                shrink={!!watch("hsccollegestate")}
                htmlFor="hsccollegestate"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_STATE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_COLLEGE_STATE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="hsccollegestate"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.HSC_COLLEGE_STATE
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_COLLEGE_STATE
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("hsccollegestate", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_STATE
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("hsccollegestate", e.target.value);
                  trigger("hsccollegestate");
                }}
                value={watch("hsccollegestate") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_COLLEGE_STATE)}
              >
                <MenuItem value="">Select One</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.hsccollegestate && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.hsccollegestate.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_DISTRICT
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.hsccollegedistrict}
            >
              <InputLabel
                shrink={!!watch("hsccollegedistrict")}
                htmlFor="hsccollegedistrict"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_DISTRICT
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_COLLEGE_DISTRICT
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="hsccollegedistrict"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.HSC_COLLEGE_DISTRICT
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_COLLEGE_DISTRICT
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("hsccollegedistrict", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_DISTRICT
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("hsccollegedistrict", e.target.value);
                  trigger("hsccollegedistrict");
                }}
                value={watch("hsccollegedistrict") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_COLLEGE_DISTRICT)}
              >
                <MenuItem value="">Select One</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.hsccollegedistrict && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.hsccollegedistrict.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_TALUKA
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.hsccollegetaluka}
            >
              <InputLabel
                shrink={!!watch("hsccollegetaluka")}
                htmlFor="hsccollegetaluka"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_TALUKA
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_COLLEGE_TALUKA
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="hsccollegetaluka"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.HSC_COLLEGE_TALUKA
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_COLLEGE_TALUKA
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("hsccollegetaluka", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_TALUKA
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("hsccollegetaluka", e.target.value);
                  trigger("hsccollegetaluka");
                }}
                value={watch("hsccollegetaluka") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_COLLEGE_TALUKA)}
              >
                <MenuItem value="">Select One</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.hsccollegetaluka && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.hsccollegetaluka.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}




        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_PASSING_YEAR
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.hscpassingyear}>
              <InputLabel
                shrink={!!watch("hscpassingyear")}
                htmlFor="hscpassingyear"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_PASSING_YEAR
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_PASSING_YEAR
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="hscpassingyear"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.HSC_PASSING_YEAR
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_PASSING_YEAR
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("hscpassingyear", {
                  required: getValues('appearingfor') !== 1 || masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_PASSING_YEAR
                  )?.ismandatory !== "1" ?   false : " HSC/Diploma Passing Year is required",
                })}
                onChange={(e) => {
                  setValue("hscpassingyear", e.target.value);
                  trigger("hscpassingyear");
                }}
                value={watch("hscpassingyear") ?? ""}
                // disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_PASSING_YEAR)}
                disabled={getValues('appearingfor') == 0 ? true :false}
              >
                <MenuItem value="">Select One</MenuItem>
                {years?.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errors.hscpassingyear && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.hscpassingyear.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

{masterFields?.find(
  (field) => field.masterfieldlabel === masterFieldLabel.HSC_BOARD
) && (
  <Grid item xs={12} md={6}>
    <FormControl fullWidth size="small" error={!!errors.hscboardid}>
      <InputLabel
        shrink={!!watch("hscboardid") || watch("hscboardid") === 0}
        htmlFor="hscboardid"
      >
        {
          masterFields?.find(
            (field) =>
              field?.masterfieldlabel === masterFieldLabel.HSC_BOARD
          )?.fieldlabel
        }
        {masterFields?.find(
          (field) =>
            field?.masterfieldlabel === masterFieldLabel.HSC_BOARD
        )?.ismandatory === "1" && (
          <span className="error" >
            {" "}
            *
          </span>
        )}
      </InputLabel>
      <Select
        id="hscboardid"
        label={
          <span>
            <span>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_BOARD
                )?.fieldlabel
              }
            </span>
            {masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.HSC_BOARD
            )?.ismandatory === "1" && (
              <span className="error" style={{ color: "red" }}>
                {" "}
                *
              </span>
            )}
          </span>
        }
        {...register("hscboardid", {
          required:
            (getValues('appearingfor') != 1) ||
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel === masterFieldLabel.HSC_BOARD
            )?.ismandatory != "1"
              ? false
              : "HSC/Diploma/Equivalent Board is required",
        })}
        value={watch("hscboardid") ?? ""}
        disabled={getValues('appearingfor') == 0}
        error={!!errors?.hscboardid}
        helperText={errors.hscboardid?.message}
      >
        <MenuItem value="">Select One</MenuItem>
        {board?.map((val, i) => (
          <MenuItem value={val?.boardid} key={i}>
            {val?.board}
          </MenuItem>
        ))}
      </Select>
      {errors.hscboardid && (
        <FormHelperText style={{ margin: 0 }}>
          {errors?.hscboardid?.message}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>
)}


{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_COLLEGE_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_NAME
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="SSC SCHOOL NAME*"
              {...register("hsccollegename", {
                required:true
              })}
              // onInput={percentageNumberValidation}
value={getValues('hsccollegename') }              
              error={!!errors.hsccollegename}
              helperText={errors.hsccollegename?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("hsccollegename"),
              }}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_COLLEGE_NAME)}
            />
            
          </Grid>
        )}



{masterFields?.find(
  (field) => field.masterfieldlabel === masterFieldLabel.MARKS_TYPE
) && (
  <Grid item xs={12} md={6}>
    <FormControl fullWidth size="small" error={!!errors.markstype}>
      <InputLabel
        shrink={!!watch("markstype") || watch("markstype") === 0}
        htmlFor="markstype"
      >
        {
          masterFields?.find(
            (field) =>
              field?.masterfieldlabel === masterFieldLabel.MARKS_TYPE
          )?.fieldlabel
        }
        {masterFields?.find(
          (field) =>
            field?.masterfieldlabel === masterFieldLabel.MARKS_TYPE
        )?.ismandatory === "1" && (
          <span className="error" style={{ color: "red" }}>
            {" "}
            *
          </span>
        )}
      </InputLabel>
      <Select
        id="markstype"
        label={
          <span>
            <span>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.MARKS_TYPE
                )?.fieldlabel
              }
            </span>
            {masterFields?.find(
              (field) =>
                field?.masterfieldlabel === masterFieldLabel.MARKS_TYPE
            )?.ismandatory === "1" && (
              <span className="error" style={{ color: "red" }}>
                {" "}
                *
              </span>
            )}
          </span>
        }
        {...register("markstype", {
          required: (getValues('appearingfor') != 1 || masterFields?.find(
            (field) =>
              field?.masterfieldlabel === masterFieldLabel.MARKS_TYPE
          )?.ismandatory != "1") 
            ? false 
            : "Mark type is required",
        })}
        onChange={(e) => {
          setValue("markstype", e.target.value);
          trigger("markstype");
        }}
        value={watch("markstype") ?? ""}
        disabled={getValues('appearingfor') == 0}
      >
        <MenuItem value="">Select One</MenuItem>
        <MenuItem value={1}>Percentage</MenuItem>
        <MenuItem value={2}>CGPA</MenuItem>
      </Select>
        {errors.markstype && (
        <FormHelperText style={{ margin: 0 }}>
          {errors.markstype.message}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>
)}

      {masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.HSC_MARKS_OBTAINED
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="number"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.HSC_MARKS_OBTAINED
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.HSC_MARKS_OBTAINED
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="HSC/Diploma Marks Obtained"
      {...register("hscmarksobtained", {
        required: ((getValues("appearingfor") !== 1) || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.HSC_MARKS_OBTAINED
        )?.ismandatory !== "1")
          ? false
          : "HSC/Diploma Marks is required",
        validate: (value) => {
          if (getValues("appearingfor") !== 0) {
            const outOfValue = parseInt(getValues("hscmarksoutof"));
            return (
              parseInt(value) <= (outOfValue <= 900 ? outOfValue : 900) ||
              "Marks obtained cannot be greater than marks out of or 900"
            );
          }
        },
      })}
      onInput={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("hscmarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      onChange={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("hscmarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      inputProps={{
        min: 0,
        max: parseInt(getValues("hscmarksoutof")) <= 900 ? parseInt(getValues("hscmarksoutof")) : 900,
      }}
      error={!!errors.hscmarksobtained}
      helperText={errors.hscmarksobtained?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: true, // Explicitly set to true
      }}
      disabled={getValues('appearingfor') == 0 }
    />
  </Grid>
)}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_MARKS_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_MARKS_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="HSC/Diploma Marks Out Of*"
              {...register("hscmarksoutof", {
                required: ((getValues("appearingfor") != 1 )  || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_MARKS_OUT_OF
                )?.ismandatory != "1" )? false : "HSC/Diploma Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.hscmarksoutof}
              helperText={errors.hscmarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("hscmarksoutof"),
              }}
              
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_MARKS_OUT_OF)}
              disabled={getValues('appearingfor') == 0 ? true :false}
            
            />
          </Grid>
        )}

{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.HSC_CALCULATED_PERCENTAGE
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="text"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.HSC_CALCULATED_PERCENTAGE
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.HSC_CALCULATED_PERCENTAGE
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="HSC/Diploma Calculated/Equivalent Percentage*"
      {...register("hscpercentage", {
        required: (getValues("appearingfor") != 1 || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.HSC_CALCULATED_PERCENTAGE
        )?.ismandatory != "1") ? false : "HSC/Diploma Calculated/Equivalent percentage is required",
     
      })}
      value={markINpercent || ""}
      error={!!errors?.hscpercentage}
      helperText={errors?.hscpercentage?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: !!watch("hscpercentage") || !!markINpercent,
      }}
      disabled={getValues('appearingfor') == 0}
    />
    <Box>
      <small style={{ color: "#666666" }}>
        Up to 2 decimal places are allowed
      </small>
    </Box>
  </Grid>
)}



{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.HSC_ENG_MARKS_OBTAINED
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="number"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.HSC_ENG_MARKS_OBTAINED
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.HSC_ENG_MARKS_OBTAINED
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="HSC/Diploma English Marks Obtained"
      {...register("hscenglishmarksobtained", {
        required: ((getValues("appearingfor") !== 1) || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.HSC_ENG_MARKS_OBTAINED
        )?.ismandatory !== "1")
          ? false
          : "HSC/Diploma Marks is required",
        validate: (value) => {
          if (getValues("appearingfor") !== 0) {
            const outOfValue = parseInt(getValues("hscenglishmarksoutof"));
            return (
              parseInt(value) <= (outOfValue <= 900 ? outOfValue : 900) ||
              "Marks obtained cannot be greater than marks out of or 900"
            );
          }
        },
      })}
      onInput={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("hscenglishmarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      onChange={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("hscenglishmarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      inputProps={{
        min: 0,
        max: parseInt(getValues("hscenglishmarksoutof")) <= 900 ? parseInt(getValues("hscenglishmarksoutof")) : 900,
      }}
      error={!!errors.hscenglishmarksobtained}
      helperText={errors.hscenglishmarksobtained?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: true, // Explicitly set to true
      }}
      disabled={getValues('appearingfor') == 0 }
    />
  </Grid>
)}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_ENG_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_ENG_MARKS_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_ENG_MARKS_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="HSC/Diploma English Marks Out Of*"
              {...register("hscenglishmarksoutof", {
                required: ((getValues("appearingfor") != 1 )  || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.HSC_ENG_MARKS_OUT_OF
                )?.ismandatory != "1" )? false : "HSC/Diploma English Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.hscenglishmarksoutof}
              helperText={errors.hscenglishmarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("hscenglishmarksoutof"),
              }}
              
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_ENG_MARKS_OUT_OF)}
              disabled={getValues('appearingfor') == 0 ? true :false}
            
            />
          </Grid>
        )}

{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="text"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="HSC/Diploma English Calculated/Equivalent Percentage*"
      {...register("hscenglishpercentage", {
        required: (getValues("appearingfor") != 1 || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
        )?.ismandatory != "1") ? false : "HSC/Diploma English Calculated/Equivalent percentage is required",
     
      })}
      value={markINpercent || ""}
      error={!!errors?.hscenglishpercentage}
      helperText={errors?.hscenglishpercentage?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: !!watch("hscenglishpercentage") || !!markINpercent,
      }}
      disabled={getValues('appearingfor') == 0}
    />
    <Box>
      <small style={{ color: "#666666" }}>
        Up to 2 decimal places are allowed
      </small>
    </Box>
  </Grid>
)}


{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.ELIGIBLITY_QUALIFICATION
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.eligibilityqualification}
            >
              <InputLabel
                shrink={!!watch("eligibilityqualification")}
                htmlFor="eligibilityqualification"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.ELIGIBLITY_QUALIFICATION
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.ELIGIBLITY_QUALIFICATION
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="eligibilityqualification"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.ELIGIBLITY_QUALIFICATION
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.ELIGIBLITY_QUALIFICATION
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("eligibilityqualification", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.ELIGIBLITY_QUALIFICATION
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("eligibilityqualification", e.target.value);
                  trigger("eligibilityqualification");
                }}
                value={watch("eligibilityqualification") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.ELIGIBLITY_QUALIFICATION)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    Gradudation
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    Post Gradudation
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.eligibilityqualification && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.eligibilityqualification.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
    
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_STATUS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.graduationstatus}
            >
              <InputLabel
                shrink={!!watch("graduationstatus")}
                htmlFor="graduationstatus"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_STATUS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_STATUS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="graduationstatus"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.GRADUATION_STATUS
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_STATUS
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("graduationstatus", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_STATUS
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("graduationstatus", e.target.value);
                  trigger("graduationstatus");
                }}
                value={watch("graduationstatus") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.GRADUATION_STATUS)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  Appearing
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                  Passed
                  </MenuItem> 
                  
                {/* ))} */}
              </Select>
              {errors.graduationstatus && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.graduationstatus.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
    

    

    {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_PASSING_YEAR
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.graduationpassingyear}>
              <InputLabel
                shrink={!!watch("graduationpassingyear")}
                htmlFor="graduationpassingyear"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_PASSING_YEAR
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_PASSING_YEAR
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="graduationpassingyear"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.GRADUATION_PASSING_YEAR
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_PASSING_YEAR
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("graduationpassingyear", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_PASSING_YEAR
                  )?.ismandatory === "1" ? "SSC Passing Year is required" : false,
                })}
                onChange={(e) => {
                  setValue("graduationpassingyear", e.target.value);
                  trigger("graduationpassingyear");
                }}
                value={watch("graduationpassingyear") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.GRADUATION_PASSING_YEAR)}
              >
                <MenuItem value="">Select One</MenuItem>
                {years?.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errors.graduationpassingyear && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.graduationpassingyear.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}


    
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_BRANCH_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.graduationbranchname}
            >
              <InputLabel
                shrink={!!watch("graduationbranchname")}
                htmlFor="graduationbranchname"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_BRANCH_NAME
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_BRANCH_NAME
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="graduationbranchname"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.GRADUATION_BRANCH_NAME
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_BRANCH_NAME
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("graduationbranchname", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_BRANCH_NAME
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("graduationbranchname", e.target.value);
                  trigger("graduationbranchname");
                }}
                value={watch("graduationbranchname") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.GRADUATION_BRANCH_NAME)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  BRANCH 1
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                  BRANCH 2 
                  </MenuItem> 
                  
                {/* ))} */}
              </Select>
              {errors.graduationbranchname && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.graduationbranchname.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
    
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_MARK_TYPE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.graduationmarktype}
            >
              <InputLabel
                shrink={!!watch("graduationmarktype")}
                htmlFor="graduationmarktype"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_MARK_TYPE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_MARK_TYPE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="graduationmarktype"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.GRADUATION_MARK_TYPE
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_MARK_TYPE
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("graduationmarktype", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_MARK_TYPE
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("graduationmarktype", e.target.value);
                  trigger("graduationmarktype");
                }}
                value={watch("graduationmarktype") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.GRADUATION_MARK_TYPE)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  Percentage
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                  CGPA
                  </MenuItem> 
                  
                {/* ))} */}
              </Select>
              {errors.graduationmarktype && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.graduationmarktype.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
    
    {masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.GRADUATION_MARK_OBTAINED
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="number"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.GRADUATION_MARK_OBTAINED
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.GRADUATION_MARK_OBTAINED
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Graduation Marks Obtained"
      {...register("graduationmarksobtained", {
        required: ((getValues("appearingfor") !== 1) || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.GRADUATION_MARK_OBTAINED
        )?.ismandatory !== "1")
          ? false
          : "Graduation obtained  Marks is required",
        validate: (value) => {
          if (getValues("appearingfor") !== 0) {
            const outOfValue = parseInt(getValues("graduationmarksoutof"));
            return (
              parseInt(value) <= (outOfValue <= 900 ? outOfValue : 900) ||
              "Marks obtained cannot be greater than marks out of or 900"
            );
          }
        },
      })}
      onInput={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("graduationmarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      onChange={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("graduationmarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      inputProps={{
        min: 0,
        max: parseInt(getValues("graduationmarksoutof")) <= 900 ? parseInt(getValues("graduationmarksoutof")) : 900,
      }}
      error={!!errors.graduationmarksobtained}
      helperText={errors.graduationmarksobtained?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: true, // Explicitly set to true
      }}
      disabled={getValues('appearingfor') == 0 }
    />
  </Grid>
)}


{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_MARK_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_MARK_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_MARK_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Graduation Marks Out Of*"
              {...register("graduationmarksoutof", {
                required: ((getValues("appearingfor") != 1 )  || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_MARK_OUT_OF
                )?.ismandatory != "1" )? false : "Graduation Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.graduationmarksoutof}
              helperText={errors.graduationmarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("graduationmarksoutof"),
              }}
              
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.GRADUATION_MARK_OUT_OF)}
              disabled={getValues('appearingfor') == 0 ? true :false}
            
            />
          </Grid>
        )}



        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Graduation Marks Percentage"
              {...register("graduationmarkspercentage", {
                required: ((getValues("appearingfor") != 1 )  || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
                )?.ismandatory != "1" )? false : "HSC/Diploma Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.graduationmarkspercentage}
              helperText={errors.graduationmarkspercentage?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("graduationmarkspercentage"),
              }}
              
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE)}
              disabled={getValues('appearingfor') == 0 ? true :false}
            
            />
          </Grid>
        )}



{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.AWARDED_CLASS_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.awardedclassname}
            >
              <InputLabel
                shrink={!!watch("awardedclassname")}
                htmlFor="awardedclassname"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.AWARDED_CLASS_NAME
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.AWARDED_CLASS_NAME
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="awardedclassname"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.AWARDED_CLASS_NAME
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.AWARDED_CLASS_NAME
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("awardedclassname", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.AWARDED_CLASS_NAME
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("awardedclassname", e.target.value);
                  trigger("awardedclassname");
                }}
                value={watch("awardedclassname") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.AWARDED_CLASS_NAME)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  First Class with Dist
                  </MenuItem>
                  <MenuItem value={2} key={2}>
               First Class
                  </MenuItem> 
                  <MenuItem value={3} key={3}>
              Second Class
                  </MenuItem> 
                  
                {/* ))} */}
              </Select>
              {errors.awardedclassname && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.awardedclassname.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
    




{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_DURATION
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.awardedclassname}
            >
              <InputLabel
                shrink={!!watch("graduationduration")}
                htmlFor="graduationduration"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_DURATION
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.GRADUATION_DURATION
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="graduationduration"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.GRADUATION_DURATION
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_DURATION
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("graduationduration", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_DURATION
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("graduationduration", e.target.value);
                  trigger("graduationduration");
                }}
                value={watch("graduationduration") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.GRADUATION_DURATION)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  3
                  </MenuItem>
                  <MenuItem value={2} key={2}>
               4
                  </MenuItem> 
                  <MenuItem value={3} key={3}>
             5
                  </MenuItem> 
                  
                  <MenuItem value={4} key={4}>
                    6
                  </MenuItem> 
                  
                {/* ))} */}
              </Select>
              {errors.graduationduration && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.graduationduration.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
    

{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.DOCUMENT_FOR_TYPE_A
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.documentfortypea}
            >
              <InputLabel
                shrink={!!watch("documentfortypea")}
                htmlFor="documentfortypea"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.DOCUMENT_FOR_TYPE_A
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.DOCUMENT_FOR_TYPE_A
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="documentfortypea"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.DOCUMENT_FOR_TYPE_A
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.DOCUMENT_FOR_TYPE_A
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("documentfortypea", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.DOCUMENT_FOR_TYPE_A
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("documentfortypea", e.target.value);
                  trigger("documentfortypea");
                }}
                value={watch("documentfortypea") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.DOCUMENT_FOR_TYPE_A)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  type 1
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  type 2
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.documentfortypea && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.documentfortypea.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
    
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.DOCUMENT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.documentof}
            >
              <InputLabel
                shrink={!!watch("documentof")}
                htmlFor="documentof"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.DOCUMENT_OF
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.DOCUMENT_OF
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="documentof"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.DOCUMENT_OF
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.DOCUMENT_OF
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("documentof", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.DOCUMENT_OF
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("documentof", e.target.value);
                  trigger("documentof");
                }}
                value={watch("documentof") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.DOCUMENT_OF)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  type 1
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  type 2
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.documentof && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.documentof.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
    
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_NAME_FOR_OPEN
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.castenameforopen}
            >
              <InputLabel
                shrink={!!watch("castenameforopen")}
                htmlFor="castenameforopen"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_NAME_FOR_OPEN
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CASTE_NAME_FOR_OPEN
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="castenameforopen"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CASTE_NAME_FOR_OPEN
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_NAME_FOR_OPEN
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("castenameforopen", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_NAME_FOR_OPEN
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("castenameforopen", e.target.value);
                  trigger("castenameforopen");
                }}
                value={watch("castenameforopen") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.CASTE_NAME_FOR_OPEN)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  type 1
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  type 2
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.castenameforopen && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.castenameforopen.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
    

    
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CET_EXAM_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.cetexamname}
            >
              <InputLabel
                shrink={!!watch("cetexamname")}
                htmlFor="cetexamname"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CET_EXAM_NAME
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CET_EXAM_NAME
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="cetexamname"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CET_EXAM_NAME
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CET_EXAM_NAME
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("cetexamname", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CET_EXAM_NAME
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("cetexamname", e.target.value);
                  trigger("cetexamname");
                }}
                value={watch("cetexamname") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.CET_EXAM_NAME)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  type 1
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  type 2
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.cetexamname && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.cetexamname.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}



{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.passinstuteonmh}
            >
              <InputLabel
                shrink={!!watch("passinstuteonmh")}
                htmlFor="passinstuteonmh"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="passinstuteonmh"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("passinstuteonmh", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("passinstuteonmh", e.target.value);
                  trigger("passinstuteonmh");
                }}
                value={watch("passinstuteonmh") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  Yes
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  No
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.passinstuteonmh && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.passinstuteonmh.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}


  {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.PF_GRADUATION_DATE
        ) && (
          <Grid item xs={12} md={6}>
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="pfgraduationdate"
                control={control}
                rules={{
                  required:
                    ((getValues("pfgraduationdate") !== 1 )|| masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.PF_GRADUATION_DATE
                    )?.ismandatory !== "1")
                      ? false
                      : "PF graduation date is required",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PF_GRADUATION_DATE
                          )?.fieldlabel
                        }
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PF_GRADUATION_DATE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    disabled={
                      getValues("pfgraduationdate") == 0 ? true : false
                    }
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      if (date) {
                        const formattedDate = dayjs(date)
                          .hour(18)
                          .minute(30)
                          .second(0)
                          .millisecond(0)
                          .toISOString();
                        field.onChange(formattedDate);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        error: !!errors.pfgraduationdate,
                        helperText:
                          errors.pfgraduationdate?.message,
                        FormHelperTextProps: {
                          style: { margin: 0 },
                        },
                        style: {
                          width: "100%",
                          borderRadius: "8px",
                        },
                      },
                      layout: {
                        sx: {
                          borderRadius: "58px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}

  {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.INTERN_TRAINING_DATE
        ) && (
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="internshiptrainingdate"
                control={control}
                rules={{
                  required:
                    ((getValues("internshiptrainingdate") !== 1 )|| masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.INTERN_TRAINING_DATE
                    )?.ismandatory !== "1")
                      ? false
                      : "PF graduation date is required",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.INTERN_TRAINING_DATE
                          )?.fieldlabel
                        }
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.INTERN_TRAINING_DATE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    disabled={
                      getValues("internshiptrainingdate") == 0 ? true : false
                    }
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      if (date) {
                        const formattedDate = dayjs(date)
                          .hour(18)
                          .minute(30)
                          .second(0)
                          .millisecond(0)
                          .toISOString();
                        field.onChange(formattedDate);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        error: !!errors.internshiptrainingdate,
                        helperText:
                          errors.internshiptrainingdate?.message,
                        FormHelperTextProps: {
                          style: { margin: 0 },
                        },
                        style: {
                          width: "100%",
                          borderRadius: "8px",
                        },
                      },
                      layout: {
                        sx: {
                          borderRadius: "58px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}

  {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.MH_COUNCIL_REGISTRATION
        ) && (
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="councilregdate"
                control={control}
                rules={{
                  required:
                    ((getValues("councilregdate") !== 1 )|| masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.MH_COUNCIL_REGISTRATION
                    )?.ismandatory !== "1")
                      ? false
                      : "PF graduation date is required",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.MH_COUNCIL_REGISTRATION
                          )?.fieldlabel
                        }
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.MH_COUNCIL_REGISTRATION
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    disabled={
                      getValues("councilregdate") == 0 ? true : false
                    }
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      if (date) {
                        const formattedDate = dayjs(date)
                          .hour(18)
                          .minute(30)
                          .second(0)
                          .millisecond(0)
                          .toISOString();
                        field.onChange(formattedDate);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        error: !!errors.councilregdate,
                        helperText:
                          errors.councilregdate?.message,
                        FormHelperTextProps: {
                          style: { margin: 0 },
                        },
                        style: {
                          width: "100%",
                          borderRadius: "8px",
                        },
                      },
                      layout: {
                        sx: {
                          borderRadius: "58px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}
  {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.RE_COUNCIL_REGISTRATION
        ) && (
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="recouncilregdate"
                control={control}
                rules={{
                  required:
                    ((getValues("recouncilregdate") !== 1 )|| masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.RE_COUNCIL_REGISTRATION
                    )?.ismandatory !== "1")
                      ? false
                      : "PF graduation date is required",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.RE_COUNCIL_REGISTRATION
                          )?.fieldlabel
                        }
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.RE_COUNCIL_REGISTRATION
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    disabled={
                      getValues("recouncilregdate") == 0 ? true : false
                    }
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      if (date) {
                        const formattedDate = dayjs(date)
                          .hour(18)
                          .minute(30)
                          .second(0)
                          .millisecond(0)
                          .toISOString();
                        field.onChange(formattedDate);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        error: !!errors.recouncilregdate,
                        helperText:
                          errors.recouncilregdate?.message,
                        FormHelperTextProps: {
                          style: { margin: 0 },
                        },
                        style: {
                          width: "100%",
                          borderRadius: "8px",
                        },
                      },
                      layout: {
                        sx: {
                          borderRadius: "58px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}





{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.ALREADY_ADMITED
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.alreadyadmitted}
            >
              <InputLabel
                shrink={!!watch("alreadyadmitted")}
                htmlFor="alreadyadmitted"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.ALREADY_ADMITED
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.ALREADY_ADMITED
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="alreadyadmitted"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.ALREADY_ADMITED
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.ALREADY_ADMITED
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("alreadyadmitted", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.ALREADY_ADMITED
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("alreadyadmitted", e.target.value);
                  trigger("alreadyadmitted");
                }}
                value={watch("alreadyadmitted") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.ALREADY_ADMITED)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  Yes
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  No
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.alreadyadmitted && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.alreadyadmitted.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}



{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.G_CollegeName
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.G_CollegeName
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.G_CollegeName
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter College Name"
              {...register("gcollegename", {
                required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.G_CollegeName
                )?.ismandatory === "1" ? "Caste name is required" : false,
              })}
              // onInput={AlphabetsValidation}
              error={!!errors.gcollegename}
              helperText={errors.gcollegename?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("gcollegename"),
              }}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.G_CollegeName)}
            />
          </Grid>
        )}


        

{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.University_Name
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.University_Name
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.University_Name
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter Caste Name"
              {...register("universityname", {
                required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.University_Name
                )?.ismandatory === "1" ? "Caste name is required" : false,
              })}
              // onInput={AlphabetsValidation}
              error={!!errors.universityname}
              helperText={errors.universityname?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("universityname"),
              }}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.University_Name)}
            />
          </Grid>
        )}


{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.FY_MARKS_OBTAINED
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="number"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.FY_MARKS_OBTAINED
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.FY_MARKS_OBTAINED
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Fy Marks Obtained"
      {...register("fymarksobtained", {
        required: ((getValues("appearingfor") !== 1) || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.FY_MARKS_OBTAINED
        )?.ismandatory !== "1")
          ? false
          : "FY  Marks is required",
        validate: (value) => {
          if (getValues("appearingfor") !== 0) {
            const outOfValue = parseInt(getValues("fymarksoutof"));
            return (
              parseInt(value) <= (outOfValue <= 900 ? outOfValue : 900) ||
              "Marks obtained cannot be greater than marks out of or 900"
            );
          }
        },
      })}
      onInput={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("fymarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      onChange={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("fymarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      inputProps={{
        min: 0,
        max: parseInt(getValues("fymarksoutof")) <= 900 ? parseInt(getValues("fymarksoutof")) : 900,
      }}
      error={!!errors.fymarksobtained}
      helperText={errors.fymarksobtained?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: true, // Explicitly set to true
      }}
      disabled={getValues('appearingfor') == 0 }
    />
  </Grid>
)}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FY_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_MARKS_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_MARKS_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="FY Marks Out Of*"
              {...register("fymarksoutof", {
                required: ((getValues("appearingfor") != 1 )  || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FY_MARKS_OUT_OF
                )?.ismandatory != "1" )? false : "FY Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.fymarksoutof}
              helperText={errors.fymarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("fymarksoutof"),
              }}
              
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.FY_MARKS_OUT_OF)}
              disabled={getValues('appearingfor') == 0 ? true :false}
            
            />
          </Grid>
        )}

{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.FY_CALCULATED_PERCENTAGE
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="text"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.FY_CALCULATED_PERCENTAGE
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.FY_CALCULATED_PERCENTAGE
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Fy Calculated/Equivalent Percentage*"
      {...register("fypercentage", {
        required: (getValues("appearingfor") != 1 || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.FY_CALCULATED_PERCENTAGE
        )?.ismandatory != "1") ? false : "Fy Calculated/Equivalent percentage is required",
     
      })}
      value={markINpercent || ""}
      error={!!errors?.fypercentage}
      helperText={errors?.fypercentage?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: !!watch("fypercentage") || !!markINpercent,
      }}
      disabled={getValues('appearingfor') == 0}
    />
    <Box>
      <small style={{ color: "#666666" }}>
        Up to 2 decimal places are allowed
      </small>
    </Box>
  </Grid>
)}




{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FY_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.fynoofattempts}
            >
              <InputLabel
                shrink={!!watch("fynoofattempts")}
                htmlFor="fynoofattempts"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_NO_OF_ATTEMPTS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FY_NO_OF_ATTEMPTS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="fynoofattempts"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.FY_NO_OF_ATTEMPTS
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_NO_OF_ATTEMPTS
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("fynoofattempts", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_NO_OF_ATTEMPTS
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("fynoofattempts", e.target.value);
                  trigger("fynoofattempts");
                }}
                value={watch("fynoofattempts") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.FY_NO_OF_ATTEMPTS)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  1
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  2
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.fynoofattempts && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.fynoofattempts.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}


{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.SY_MARKS_OBTAINED
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="number"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.SY_MARKS_OBTAINED
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.SY_MARKS_OBTAINED
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Sy Marks Obtained"
      {...register("symarksobtained", {
        required: ((getValues("appearingfor") !== 1) || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.SY_MARKS_OBTAINED
        )?.ismandatory !== "1")
          ? false
          : "FY  Marks is required",
        validate: (value) => {
          if (getValues("appearingfor") !== 0) {
            const outOfValue = parseInt(getValues("symarksoutof"));
            return (
              parseInt(value) <= (outOfValue <= 900 ? outOfValue : 900) ||
              "Marks obtained cannot be greater than marks out of or 900"
            );
          }
        },
      })}
      onInput={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("symarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      onChange={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("symarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      inputProps={{
        min: 0,
        max: parseInt(getValues("symarksoutof")) <= 900 ? parseInt(getValues("symarksoutof")) : 900,
      }}
      error={!!errors.symarksobtained}
      helperText={errors.symarksobtained?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: true, // Explicitly set to true
      }}
      disabled={getValues('appearingfor') == 0 }
    />
  </Grid>
)}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SY_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_MARKS_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_MARKS_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="SY Marks Out Of*"
              {...register("symarksoutof", {
                required: ((getValues("appearingfor") != 1 )  || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SY_MARKS_OUT_OF
                )?.ismandatory != "1" )? false : "SY Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.symarksoutof}
              helperText={errors.symarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("symarksoutof"),
              }}
              
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.SY_MARKS_OUT_OF)}
              disabled={getValues('appearingfor') == 0 ? true :false}
            
            />
          </Grid>
        )}

{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.SY_CALCULATED_PERCENTAGE
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="text"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.SY_CALCULATED_PERCENTAGE
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.SY_CALCULATED_PERCENTAGE
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Sy Calculated/Equivalent Percentage*"
      {...register("sypercentage", {
        required: (getValues("appearingfor") != 1 || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.SY_CALCULATED_PERCENTAGE
        )?.ismandatory != "1") ? false : "Fy Calculated/Equivalent percentage is required",
     
      })}
      value={markINpercent || ""}
      error={!!errors?.sypercentage}
      helperText={errors?.sypercentage?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: !!watch("sypercentage") || !!markINpercent,
      }}
      disabled={getValues('appearingfor') == 0}
    />
    <Box>
      <small style={{ color: "#666666" }}>
        Up to 2 decimal places are allowed
      </small>
    </Box>
  </Grid>
)}




{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SY_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.synoofattempts}
            >
              <InputLabel
                shrink={!!watch("synoofattempts")}
                htmlFor="synoofattempts"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_NO_OF_ATTEMPTS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SY_NO_OF_ATTEMPTS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="synoofattempts"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SY_NO_OF_ATTEMPTS
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_NO_OF_ATTEMPTS
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("synoofattempts", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_NO_OF_ATTEMPTS
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("synoofattempts", e.target.value);
                  trigger("synoofattempts");
                }}
                value={watch("synoofattempts") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.SY_NO_OF_ATTEMPTS)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  1
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  2
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.synoofattempts && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.synoofattempts.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.TY_MARKS_OBTAINED
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="number"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.TY_MARKS_OBTAINED
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.TY_MARKS_OBTAINED
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Ty Marks Obtained"
      {...register("tymarksobtained", {
        required: ((getValues("appearingfor") !== 1) || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.TY_MARKS_OBTAINED
        )?.ismandatory !== "1")
          ? false
          : "TY  Marks is required",
        validate: (value) => {
          if (getValues("appearingfor") !== 0) {
            const outOfValue = parseInt(getValues("symarksoutof"));
            return (
              parseInt(value) <= (outOfValue <= 900 ? outOfValue : 900) ||
              "Marks obtained cannot be greater than marks out of or 900"
            );
          }
        },
      })}
      onInput={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("tymarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      onChange={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("tymarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      inputProps={{
        min: 0,
        max: parseInt(getValues("tymarksoutof")) <= 900 ? parseInt(getValues("tymarksoutof")) : 900,
      }}
      error={!!errors.tymarksobtained}
      helperText={errors.tymarksobtained?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: true, // Explicitly set to true
      }}
      disabled={getValues('appearingfor') == 0 }
    />
  </Grid>
)}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.TY_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_MARKS_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_MARKS_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="TY Marks Out Of*"
              {...register("tymarksoutof", {
                required: ((getValues("appearingfor") != 1 )  || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.TY_MARKS_OUT_OF
                )?.ismandatory != "1" )? false : "SY Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.tymarksoutof}
              helperText={errors.tymarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("tymarksoutof"),
              }}
              
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.TY_MARKS_OUT_OF)}
              disabled={getValues('appearingfor') == 0 ? true :false}
            
            />
          </Grid>
        )}

{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.TY_CALCULATED_PERCENTAGE
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="text"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.TY_CALCULATED_PERCENTAGE
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.TY_CALCULATED_PERCENTAGE
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Ty Calculated/Equivalent Percentage*"
      {...register("typercentage", {
        required: (getValues("appearingfor") != 1 || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.TY_CALCULATED_PERCENTAGE
        )?.ismandatory != "1") ? false : "Fy Calculated/Equivalent percentage is required",
     
      })}
      value={markINpercent || ""}
      error={!!errors?.typercentage}
      helperText={errors?.typercentage?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: !!watch("typercentage") || !!markINpercent,
      }}
      disabled={getValues('appearingfor') == 0}
    />
    <Box>
      <small style={{ color: "#666666" }}>
        Up to 2 decimal places are allowed
      </small>
    </Box>
  </Grid>
)}




{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.TY_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.tynoofattempts}
            >
              <InputLabel
                shrink={!!watch("tynoofattempts")}
                htmlFor="tynoofattempts"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_NO_OF_ATTEMPTS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.TY_NO_OF_ATTEMPTS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="tynoofattempts"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.TY_NO_OF_ATTEMPTS
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_NO_OF_ATTEMPTS
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("tynoofattempts", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_NO_OF_ATTEMPTS
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("tynoofattempts", e.target.value);
                  trigger("tynoofattempts");
                }}
                value={watch("tynoofattempts") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.TY_NO_OF_ATTEMPTS)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  1
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  2
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.tynoofattempts && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.tynoofattempts.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}


{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.FOUR_Y_MARKS_OBTAINED
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="number"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.FOUR_Y_MARKS_OBTAINED
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.FOUR_Y_MARKS_OBTAINED
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Four Y Marks Obtained"
      {...register("tymarksobtained", {
        required: ((getValues("appearingfor") !== 1) || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.FOUR_Y_MARKS_OBTAINED
        )?.ismandatory !== "1")
          ? false
          : "TY  Marks is required",
        validate: (value) => {
          if (getValues("appearingfor") !== 0) {
            const outOfValue = parseInt(getValues("symarksoutof"));
            return (
              parseInt(value) <= (outOfValue <= 900 ? outOfValue : 900) ||
              "Marks obtained cannot be greater than marks out of or 900"
            );
          }
        },
      })}
      onInput={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("tymarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      onChange={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("tymarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      inputProps={{
        min: 0,
        max: parseInt(getValues("tymarksoutof")) <= 900 ? parseInt(getValues("tymarksoutof")) : 900,
      }}
      error={!!errors.tymarksobtained}
      helperText={errors.tymarksobtained?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: true, // Explicitly set to true
      }}
      disabled={getValues('appearingfor') == 0 }
    />
  </Grid>
)}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FOUR_Y_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FOUR_Y_MARKS_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FOUR_Y_MARKS_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="TY Marks Out Of*"
              {...register("tymarksoutof", {
                required: ((getValues("appearingfor") != 1 )  || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FOUR_Y_MARKS_OUT_OF
                )?.ismandatory != "1" )? false : "SY Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.tymarksoutof}
              helperText={errors.tymarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("tymarksoutof"),
              }}
              
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.FOUR_Y_MARKS_OUT_OF)}
              disabled={getValues('appearingfor') == 0 ? true :false}
            
            />
          </Grid>
        )}

{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="text"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Ty Calculated/Equivalent Percentage*"
      {...register("typercentage", {
        required: (getValues("appearingfor") != 1 || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
        )?.ismandatory != "1") ? false : "Fy Calculated/Equivalent percentage is required",
     
      })}
      value={markINpercent || ""}
      error={!!errors?.typercentage}
      helperText={errors?.typercentage?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: !!watch("typercentage") || !!markINpercent,
      }}
      disabled={getValues('appearingfor') == 0}
    />
    <Box>
      <small style={{ color: "#666666" }}>
        Up to 2 decimal places are allowed
      </small>
    </Box>
  </Grid>
)}




{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.tynoofattempts}
            >
              <InputLabel
                shrink={!!watch("tynoofattempts")}
                htmlFor="tynoofattempts"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="tynoofattempts"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("tynoofattempts", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("tynoofattempts", e.target.value);
                  trigger("tynoofattempts");
                }}
                value={watch("tynoofattempts") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS)}
              >
                <MenuItem value="">Select One</MenuItem>
                {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  1
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                  2
                  </MenuItem>
                  
                {/* ))} */}
              </Select>
              {errors.tynoofattempts && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.tynoofattempts.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}




{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.AGG_MARKS_OBTAINED
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="number"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.AGG_MARKS_OBTAINED
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.AGG_MARKS_OBTAINED
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Aggregate Marks Obtained"
      {...register("tymarksobtained", {
        required: ((getValues("appearingfor") !== 1) || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.AGG_MARKS_OBTAINED
        )?.ismandatory !== "1")
          ? false
          : "TY  Marks is required",
        validate: (value) => {
          if (getValues("appearingfor") !== 0) {
            const outOfValue = parseInt(getValues("symarksoutof"));
            return (
              parseInt(value) <= (outOfValue <= 900 ? outOfValue : 900) ||
              "Marks obtained cannot be greater than marks out of or 900"
            );
          }
        },
      })}
      onInput={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("tymarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      onChange={(e) => {
        const value = parseInt(e.target.value);
        const maxValue = parseInt(getValues("tymarksoutof"));
        const maxAllowed = maxValue <= 900 ? maxValue : 900;
        if (value > maxAllowed) {
          e.target.value = maxAllowed;
        }
      }}
      inputProps={{
        min: 0,
        max: parseInt(getValues("tymarksoutof")) <= 900 ? parseInt(getValues("tymarksoutof")) : 900,
      }}
      error={!!errors.tymarksobtained}
      helperText={errors.tymarksobtained?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: true, // Explicitly set to true
      }}
      disabled={getValues('appearingfor') == 0 }
    />
  </Grid>
)}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.AGG_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.AGG_MARKS_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.AGG_MARKS_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="TY Marks Out Of*"
              {...register("tymarksoutof", {
                required: ((getValues("appearingfor") != 1 )  || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.AGG_MARKS_OUT_OF
                )?.ismandatory != "1" )? false : "SY Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.tymarksoutof}
              helperText={errors.tymarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("tymarksoutof"),
              }}
              
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.AGG_MARKS_OUT_OF)}
              disabled={getValues('appearingfor') == 0 ? true :false}
            
            />
          </Grid>
        )}

{masterFields?.find(
  (field) =>
    field.masterfieldlabel === masterFieldLabel.AGG_CALCULATED_PERCENTAGE
) && (
  <Grid item xs={12} md={6}>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      type="text"
      label={
        <span>
          {
            masterFields?.find(
              (field) =>
                field?.masterfieldlabel ===
                masterFieldLabel.AGG_CALCULATED_PERCENTAGE
            )?.fieldlabel
          }
          {masterFields?.find(
            (field) =>
              field?.masterfieldlabel ===
              masterFieldLabel.AGG_CALCULATED_PERCENTAGE
          )?.ismandatory === "1" && (
            <span className="error" style={{ color: "red" }}>
              {" "}
              *
            </span>
          )}
        </span>
      }
      placeholder="Ty Calculated/Equivalent Percentage*"
      {...register("typercentage", {
        required: (getValues("appearingfor") != 1 || masterFields?.find(
          (field) =>
            field?.masterfieldlabel ===
            masterFieldLabel.AGG_CALCULATED_PERCENTAGE
        )?.ismandatory != "1") ? false : "Fy Calculated/Equivalent percentage is required",
     
      })}
      value={markINpercent || ""}
      error={!!errors?.typercentage}
      helperText={errors?.typercentage?.message}
      FormHelperTextProps={{
        style: { margin: 0 },
      }}
      InputLabelProps={{
        shrink: !!watch("typercentage") || !!markINpercent,
      }}
      disabled={getValues('appearingfor') == 0}
    />
    <Box>
      <small style={{ color: "#666666" }}>
        Up to 2 decimal places are allowed
      </small>
    </Box>
  </Grid>
)}





        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CANDIDATE_NAME_ON_HSC
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CANDIDATE_NAME_ON_HSC
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CANDIDATE_NAME_ON_HSC
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Candidate's Name as on HSC Marksheet*"
              {...register("cndnameonhscmarksheet", {
                required:( (getValues("appearingfor") != 1 )  ||  masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CANDIDATE_NAME_ON_HSC
                )?.ismandatory != "1" )? false: "Candidate's Name as on HSC Marksheet is required" ,
              })}
              onInput={AlphabetsValidation}
              error={!!errors.cndnameonhscmarksheet}
              helperText={errors.cndnameonhscmarksheet?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("cndnameonhscmarksheet"),
              }}
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.CANDIDATE_NAME_ON_HSC)}
              disabled={getValues('appearingfor') == 0 ? true :false}
          
          />
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Candidate's Mother Name as on HSC Marksheet*"
              {...register("cndmothernameonhscmarksheet", {
                required: ( (getValues("appearingfor") != 1 ) || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC
                )?.ismandatory != "1" )?false : "Candidate's Mother Name as on HSC Marksheet is required",
              })}
              onInput={AlphabetsValidation}
              error={!!errors.cndmothernameonhscmarksheet}
              helperText={errors.cndmothernameonhscmarksheet?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("cndmothernameonhscmarksheet"),
              }}
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC)}
              disabled={getValues('appearingfor') == 0 ? true :false}
          />
          </Grid>
        )}

        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : null,
          }}
        >
          <BackButton handleBack={handleBack} />
          {/* <EditButton /> */}
          <SubmitButton
            name={buttonLoader ? "loader" : "Proceed"}
            handleSubmit={handleSubmit(handleProcced)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
