import React, { useState } from "react";
import { Box, Grid, IconButton, Modal, Typography, InputAdornment, TextField, Button } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import { PiX } from "react-icons/pi";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { InputTrimFunction, passwordValidation } from "../../utils/validations";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: '24px',
    borderRadius: '8px',
};

export default function ChangePassword(props) {
    const { handleClose, showModal } = props;
    const [passState, setPassState] = useState(false);
    const [passState2, setPassState2] = useState(false);
    const [pass, setPass] = useState(false);

    const handlePasswordChange = async (e) => {
        const { name } = e.target;

        await trigger(name);
    };
    const handleFieldFocus = async (currentFieldName, previousFieldName) => {
        if (previousFieldName) {
            const isValid = await trigger(previousFieldName);
            if (!isValid) {
                // If the previous field is invalid, focus back on it
                const previousField = document.querySelector(
                    `input[name="${previousFieldName}"]`
                );
                if (previousField) {
                    previousField.focus();
                }
                return false; // Prevent focus on the current field
            }
        }
        return true; // Allow focus on the current field
    };
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        trigger,
        setValue,
        getValues,
        setError,
        clearErrors,
    } = useForm({
        mode: "onChange",
    });
    const textFieldTitleStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "16.94px",
        textAlign: "left",
        color: `${cssProperties?.color?.tertiary}`,
        paddingBottom: "4px",
    };

    const textFieldInfoStyle = {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "14.52px",
        textAlign: "left",
        color: `${cssProperties?.color?.primary2}`,
    };
    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>


                <Box display="flex" alignItems="center" marginBottom='24px' style={{ justifyContent: "space-between" }}>
                    <Box flexGrow={1}>
                        <Typography variant="h5" sx={{
                            color: `#504E50`, fontSize: '20px', fontWeight: '500'
                        }}>
                            Change Password
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <PiX />
                        </IconButton>
                    </Box>
                </Box>
                <Grid >
                    <Grid item sx={{ justifyContent: "center", alignItems: "center", fontWeight: 600 }}>
                        <Typography variant="h5" sx={{
                            marginBottom: 1, color: `#504E50`, fontSize: '14px', fontWeight: '500', alignItems: "left"
                        }}>
                            Old Password
                        </Typography>
                        <TextField
                            helperText={errors.password ? errors.password.message : ""}
                            fullWidth
                            InputProps={{
                                // style: { height: "56px", boxSizing: "border-box" },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setPass(!passState2)}
                                            sx={{ paddingRight: "0" }}
                                        >
                                            {pass ? (
                                                <FiEyeOff size={18} />
                                            ) : (
                                                <FiEye size={18} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            sx={{
                                marginBottom: 2,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    minHeight: "52px",
                                    "& fieldset": {
                                        border: "2px solid rgba(189, 216, 254, 1)",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "rgba(100, 150, 255, 1)",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "rgba(50, 100, 255, 1)",
                                    },
                                    "&.Mui-error fieldset": {
                                        borderColor: "red",
                                    },
                                },
                                "& .MuiFormHelperText-root": {
                                    margin: 0,
                                    color: "red",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item sx={{ justifyContent: "center", marginBottom: 1, alignItems: "center", fontWeight: 600 }}>
                        <Typography variant="h5" sx={{
                            color: `#504E50`, fontSize: '14px', fontWeight: '500', alignItems: "left"
                        }}>
                            New Password
                        </Typography>
                        <TextField
                            helperText={errors.password ? errors.password.message : ""}
                            fullWidth
                            type={passState ? "text" : "password"}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setPassState(!passState)}
                                            sx={{ paddingRight: "0" }}
                                        >
                                            {passState ? (
                                                <FiEyeOff size={18} />
                                            ) : (
                                                <FiEye size={18} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            {...register("temppassword", {
                                validate: { passwordValidation },
                                required: "Password is required",
                            })}
                            sx={{
                                marginBottom: 2,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    minHeight: "52px",
                                    "& fieldset": {
                                        border: "2px solid rgba(189, 216, 254, 1)",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "rgba(100, 150, 255, 1)",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "rgba(50, 100, 255, 1)",
                                    },
                                    "&.Mui-error fieldset": {
                                        borderColor: "red",
                                    },
                                },
                                "& .MuiFormHelperText-root": {
                                    margin: 0,
                                    color: "red",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item sx={{ justifyContent: "center", alignItems: "center", fontWeight: 600 }}>
                        <Typography variant="h5" sx={{
                            marginBottom: 1, color: `#504E50`, fontSize: '14px', fontWeight: '500', alignItems: "left"
                        }}>
                            Re-type New Password
                        </Typography>
                        <TextField
                            helperText={errors.password ? errors.password.message : ""}
                            fullWidth
                            {...register("confirmpassword", {
                                required: "Confirm password is required",
                                validate: (val) => {
                                    if (watch("temppassword") !== val) {
                                        return "Passwords do not match";
                                    }
                                },
                            })}
                            onFocus={() =>
                                handleFieldFocus("confirmpassword", "temppassword")
                            }
                            variant="outlined"
                            type={passState2 ? "text" : "password"}
                            onInput={InputTrimFunction}
                            onKeyUp={handlePasswordChange}
                            InputProps={{
                                // style: { height: "56px", boxSizing: "border-box" },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setPassState2(!passState2)}
                                            sx={{ paddingRight: "0" }}
                                        >
                                            {passState2 ? (
                                                <FiEyeOff size={18} />
                                            ) : (
                                                <FiEye size={18} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                marginBottom: 2,
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    minHeight: "52px",
                                    "& fieldset": {
                                        border: "2px solid rgba(189, 216, 254, 1)",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "rgba(100, 150, 255, 1)",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "rgba(50, 100, 255, 1)",
                                    },
                                    "&.Mui-error fieldset": {
                                        borderColor: "red",
                                    },
                                },
                                "& .MuiFormHelperText-root": {
                                    margin: 0,
                                    color: "red",
                                },
                            }}
                        />
                    </Grid>
                    <Grid container spacing={2} sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Inter",
                        lineHeight: "16.94px",
                        textAlign: "left",
                        color: "#00000099"

                    }}>
                        <Grid item xs={6} sx={{
                            display: "flex",

                            textAlign: "left",

                        }}>
                            <IoCheckmarkCircleOutline size={18} />
                            Password should be minimum 8 characters long
                        </Grid>
                        <Grid item xs={6} sx={{
                            display: "flex",
                            textAlign: "left",

                        }}>
                            <IoCheckmarkCircleOutline size={18} />
                            One lowercase letter
                        </Grid>
                        <Grid item xs={6} sx={{
                            display: "flex",
                            textAlign: "left",

                        }}>
                            <IoCheckmarkCircleOutline size={18} />
                            One uppercase letter
                        </Grid>
                        <Grid item xs={6} sx={{
                            display: "flex",
                            textAlign: "left",

                        }}>
                            <IoCheckmarkCircleOutline size={18} />
                            One number
                        </Grid>
                        <Grid item xs={6} sx={{
                            marginBottom: 2, display: "flex",
                            textAlign: "left",
                        }}>
                            <IoCheckmarkCircleOutline size={18} />
                            One special character
                        </Grid>
                    </Grid>

                    <Button variant="contained" sx={{
                        background: "rgba(15, 79, 150, 1)",
                        color: "#D7E2E9",
                        padding: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                        textTransform: "none",
                        borderRadius: "6px",
                        minHeight: "40px",
                        lineHeight: "16.4px",
                        "&:hover": {
                            backgroundColor: `${cssProperties?.color?.buttonHover}`,
                        },

                    }} color="primary" fullWidth>
                        Update Password
                    </Button>
                </Grid>



            </Box>
        </Modal>
    );
}
