import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  AlphabetsValidation,
  handleNameValidation,
  handleEmailValidation,
} from "../../utils/validations";
import { useSelector, useDispatch } from "react-redux";
import masterActions from "../../redux/master/action";
import BackButton from "../../common/backButton";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import SubmitButton from "../../common/submitButton";
import candidatesActions from "../../redux/candidates/actions";
import { masterFieldLabel,isFieldDisabled } from "../../utils/courseValues";

export default function PersonalDetailsForm({ nextPageName }) {
  const location = useLocation();
  const courseid = location.state?.courseid;
  console.log(courseid);
  const [maritalStatus, setMaritalStatus] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const { gender, region, mothertongue, nationality, annualIncome, religion } =
    useSelector((state) => state.masterReducer);
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { courseFullRegistrationDetails, personalDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const { masterFields } = useSelector((state) => state.candidatesReducer);
  console.log(masterFields);
  useEffect(() => {
    dispatch({ type: masterActions.GET_GENDER });
    dispatch({ type: masterActions.GET_REGION });
    dispatch({ type: masterActions.GET_MOTHERTONGUE });
    dispatch({ type: masterActions.GET_NATIONALITY });
    dispatch({ type: masterActions.GET_ANNUALINCOME });
    dispatch({ type: masterActions.GET_RELIGION });
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: {
          courseid: courseid,
        },
      });
    }
  }, [candidateid]);

  useEffect(() => {
    if (courseFullRegistrationDetails?.length > 0) {
      reset({ ...courseFullRegistrationDetails[0] });
    } else if (personalDetails?.length > 0) {
      reset({ ...personalDetails[0] });
    }
    setValue("candidatename", personalDetails[0]?.candidatename);
    setValue("dob", personalDetails[0]?.dob);
    setValue("emailid", personalDetails[0]?.emailid);
    setValue("mobileno", personalDetails[0]?.mobileno);
    setValue("fathername", courseFullRegistrationDetails[0]?.fathersname);
  }, [courseFullRegistrationDetails, personalDetails]);
  const handleMaritalStatusChange = (e) => {
    setValue("maritalstatusid", parseInt(e.target.value));
    trigger("maritalstatusid");
    setMaritalStatus(e.target.value);
  };

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  const handleProcced = (data) => {
    const corcandidateid = courseFullRegistrationDetails[0]?.corcandidateid;
    console.log(data);
    dispatch({
      type: candidatesActions.COURSE_PERSONAL_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidateid ? parseInt(corcandidateid) : 0,
          status: 1,
          fathersname: getValues("fathername"),
          cadidateid: parseInt(candidateid),
          courseid: courseid,
          createdby: parseInt(candidateid),
          updatedby: null,
        },
        navigate: navigate,
        nextPageName: nextPageName,
      },
    });
    dispatch({ type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS });
  };
  console.log(nextPageName);
  return (
    <Grid
      p={3}
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={2}>
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.FULL_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.FULL_NAME
                    )?.fieldlabel
                  }

                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.FULL_NAME
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter name"
              {...register("candidatename", {
                required: "Name is required",
                validate: handleNameValidation,
              })}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.FULL_NAME)}
              onInput={AlphabetsValidation}
              InputLabelProps={{
                shrink: !!watch("candidatename")?.trim(),
              }}
            />
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.DOB
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.DOB
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) => field?.masterfieldlabel === masterFieldLabel.DOB
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              size="small"
              placeholder="Click to set date"
              InputLabelProps={{
                shrink: !!watch("dob"),
              }}
              type="date"
              {...register("dob", {
                required: "DOB is required",
              })}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.DOB)}
            />
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.EMAIL
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.EMAIL
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.EMAIL
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter email"
              {...register("emailid", {
                required: "Email is required",
                validate: handleEmailValidation,
              })}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.EMAIL)}
              InputLabelProps={{
                shrink: !!watch("emailid")?.trim(),
              }}
            />
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.MOBILE_NO
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.MOBILE_NO
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.MOBILE_NO
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter mobile number"
              {...register("mobileno", {
                required: "Mobile number is required",
              })}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.MOBILE_NO)}
              InputLabelProps={{
                shrink: !!watch("mobileno")?.trim(),
              }}
            />
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.FATHERS_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FATHERS_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.FATHERS_NAME
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Father's Name (First Name Only)*"
              {...register("fathername", {
                required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.FATHERS_NAME
                )?.ismandatory === "1" ? "Father's Name is required" : false,
                validate: handleNameValidation,
              })}
              onInput={AlphabetsValidation}
              error={!!errors.fathername}
              helperText={errors.fathername?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("fathername")?.trim(),
              }}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.FATHERS_NAME)}
            />
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.MOTHERS_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.MOTHERS_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.MOTHERS_NAME
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Mother's Name (First Name Only)*"
              {...register("mothername", {
                required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.MOTHERS_NAME
                )?.ismandatory === "1" ? "Mother's Name is required" : false,
                validate: handleNameValidation,
              })}
              error={!!errors.mothername}
              helperText={errors.mothername?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={AlphabetsValidation}
              InputLabelProps={{
                shrink: "mothername"?.trim(),
              }}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.MOTHERS_NAME)}
            />
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.GENDER
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.genderid}>
              <InputLabel shrink={!!watch("genderid")} htmlFor="genderid">
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.GENDER
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.GENDER
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="genderid"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.GENDER
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.GENDER
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("genderid", { required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.GENDER
                )?.ismandatory === "1" ? "Gender is required" : false})}
                onChange={(e) => {
                  setValue("genderid", e.target.value);
                  trigger("genderid");
                }}
                value={watch("genderid") || ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.GENDER)}
              >
                <MenuItem value="" disabled>Select One</MenuItem>
                {gender?.map((val, i) => (
                  <MenuItem value={val?.genderid} key={i}>
                    {val?.gender}
                  </MenuItem>
                ))}
              </Select>
              {errors.genderid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.genderid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.REGION
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.regionid}>
              <InputLabel shrink={!!watch("regionid")} htmlFor="regionid">
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.REGION
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.REGION
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.REGION
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.REGION
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("regionid", { required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.REGION
                )?.ismandatory === "1" ? "Region is required" : false })}
                onChange={(e) => {
                  setValue("regionid", e.target.value);
                  trigger("regionid");
                }}
                value={watch("regionid") || ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.REGION)}
              >
                <MenuItem value="" disabled>Select One</MenuItem>
                {region?.map((val, i) => (
                  <MenuItem value={val?.regionid} key={i}>
                    {val?.region}
                  </MenuItem>
                ))}
              </Select>
              {errors.regionid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.regionid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.RELIGION
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.religionid}>
              <InputLabel shrink={!!watch("religionid")} htmlFor="religionid">
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.RELIGION
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.RELIGION
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.RELIGION
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.RELIGION
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("religionid", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.RELIGION
                  )?.ismandatory === "1" ? "Religion is required" : false,
                })}
                onChange={(e) => {
                  setValue("religionid", e.target.value);
                  trigger("religionid");
                }}
                value={watch("religionid") || ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.RELIGION)}
              >
                <MenuItem value="" disabled>Select One</MenuItem>
                {religion?.map((val, i) => (
                  <MenuItem value={val?.religionid} key={i}>
                    {val?.religion}
                  </MenuItem>
                ))}
              </Select>
              {errors.religionid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.religionid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.MOTHER_TONGUE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.mothertongueid}>
              <InputLabel
                shrink={!!watch("mothertongueid")}
                htmlFor="mothertongueid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.MOTHER_TONGUE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.MOTHER_TONGUE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.MOTHER_TONGUE
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.MOTHER_TONGUE
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("mothertongueid", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.MOTHER_TONGUE
                  )?.ismandatory === "1" ? "Mother Tongue is required" : false,
                })}
                onChange={(e) => {
                  setValue("mothertongueid", e.target.value);
                  trigger("mothertongueid");
                }}
                value={watch("mothertongueid") || ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.MOTHER_TONGUE)}
              >
                <MenuItem value="" disabled>Select One</MenuItem>
                {mothertongue?.map((val, i) => (
                  <MenuItem value={val?.mothertongueid} key={i}>
                    {val?.mothertongue}
                  </MenuItem>
                ))}
              </Select>
              {errors.mothertongueid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.mothertongueid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.NATIONALITY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.nationalityid}>
              <InputLabel
                shrink={!!watch("nationalityid")}
                htmlFor="nationalityid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.NATIONALITY
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.NATIONALITY
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.NATIONALITY
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.NATIONALITY
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("nationalityid", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.NATIONALITY
                  )?.ismandatory === "1" ? "Nationality is required" : false,
                })}
                onChange={(e) => {
                  setValue("nationalityid", e.target.value);
                  trigger("nationalityid");
                }}
                value={watch("nationalityid") || ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.NATIONALITY)}
              >
                <MenuItem value="" disabled>Select One</MenuItem>
                {nationality?.map((val, i) => (
                  <MenuItem value={val?.nationalityid} key={i}>
                    {val?.nationality}
                  </MenuItem>
                ))}
              </Select>
              {errors.nationalityid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.nationalityid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.ANNUAL_FAMILY_INCOME
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.familyincomeid}>
              <InputLabel
                shrink={!!watch("familyincomeid")}
                htmlFor="familyincomeid"
              >
                {" "}
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.ANNUAL_FAMILY_INCOME
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.ANNUAL_FAMILY_INCOME
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.ANNUAL_FAMILY_INCOME
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.ANNUAL_FAMILY_INCOME
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("familyincomeid", {
                  required:masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.ANNUAL_FAMILY_INCOME
                  )?.ismandatory === "1" ? "Annual Family Income is required" : false,
                })}
                onChange={(e) => {
                  setValue("familyincomeid", e.target.value);
                  trigger("familyincomeid");
                }}
                value={watch("familyincomeid") || ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.ANNUAL_FAMILY_INCOME)}
              >
                <MenuItem value="" disabled>Select One</MenuItem>
                {annualIncome?.map((val, i) => (
                  <MenuItem value={val?.familyincomeid} key={i}>
                    {val?.incomerange}
                  </MenuItem>
                ))}
              </Select>
              {errors.familyincomeid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.familyincomeid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.MARITAL_STATUS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.maritalstatusid}
            >
              <InputLabel
                shrink={
                  !!watch("maritalstatusid") || watch("maritalstatusid") === 0
                }
                htmlFor="maritalstatusid"
              >
                {" "}
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.MARITAL_STATUS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.MARITAL_STATUS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                size="small"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.MARITAL_STATUS
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.MARITAL_STATUS
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("maritalstatusid", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.MARITAL_STATUS
                  )?.ismandatory === "1" ? "Marital status is required" : false,
                })}
                onChange={handleMaritalStatusChange}
                value={watch("maritalstatusid") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.MARITAL_STATUS)}
              >
                <MenuItem value="" disabled>Select One</MenuItem>
                <MenuItem value={0}>Unmarried</MenuItem>
                <MenuItem value={1}>Married</MenuItem>
              </Select>
              {errors.maritalstatusid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.maritalstatusid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPOUSE_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.SPOUSE_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SPOUSE_NAME
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter spousename"
              {...register("spousename", {
                validate: handleNameValidation,
              })}
              disabled={
                maritalStatus === 0 || getValues("maritalstatusid") === 0
              }
              onInput={AlphabetsValidation}
              InputLabelProps={{
                shrink: !!watch("spousename")?.trim(),
              }}
            />
          </Grid>
        )}

        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : null,
          }}
        >
          {/* <BackButton handleBack={handleBack} /> */}
          {/* <EditButton /> */}
          <SubmitButton
            name={buttonLoader ? "loader" : "Proceed"}
            handleSubmit={handleSubmit(handleProcced)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
