import { all } from "redux-saga/effects";

import candidatesSaga from "./candidates/sagas";
import masterSaga from "./master/sagas";
import authSagas from "./auth/sagas";

export default function* rootSaga() {
    yield all([
        candidatesSaga(),
        masterSaga(),
        authSagas(),
    ])
};