import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MdOutlineModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import candidatesActions from "../../redux/candidates/actions";
import dayjs from "dayjs";
import authActions from "../../redux/auth/actions";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";

export default function PreviewForm({ targetRef, courseId }) {
  const [CheckboxValue, setCheckBoxValue] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );

  const { courseFullRegistrationDetails, personalDetails } = useSelector(
    (state) => state.candidatesReducer
  );

  const candidateDetails = useSelector(
    (state) => state.candidatesReducer.FullCandidateDetails
  );
  console.log(candidateDetails);
  console.log(
    "courseFullRegistrationDetails---",
    courseFullRegistrationDetails
  );

  const applicantid = useSelector(
    (state) => state.candidatesReducer.applicantid.cnd_getapplicantid_bycourseid
  );

  console.log("applicantid----------", applicantid);
  console.log("courseid----------", courseid);
  // const [searchParams] = useSearchParams();
  // const  applicationid  = searchParams.get('applicationid')

  // const  candidateDetails  = useSelector((state) => state.candidatesReducer.FullCandidateDetails);
  // console.log(candidateDetails);

  useEffect(() => {
    dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  }, []);

  const { uploadDetails } = useSelector((state) => state.candidatesReducer);
  console.log(uploadDetails, "uploadDetails");

  const handleProcced = () => {
    if (CheckboxValue) {
      navigate(
        `/${Cookies.get(
          "mhet_cnd_project"
        )}/course-registration?page=applicationfee`,
        { state: { courseid: courseid ? courseid : courseId } }
      );
    } else {
      if (!CheckboxValue) {
        setError("Select the checkbox is required");
      }
    }
  };
  const handleCheckbox = (e) => {
    const checked = e.target.checked;
    setCheckBoxValue(checked);
    setError("");
  };

  useEffect(() => {
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_FULL_CANDIDATE_DETAILS });
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: { courseid: courseid ? courseid : courseId },
      });
      dispatch({
        type: candidatesActions.GET_COURSE_APPLICANTID,
        payload: {
          courseid: courseid ? courseid : courseId,
          candidateid: candidateid,
        },
      });
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  return (
    <Grid
      p={3}
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
      ref={targetRef}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {location.pathname + location.search ===
          "/mhcet/course-registration?page=printapplication" &&
          applicantid !== "" && (
            <Typography
              variant="h4"
              sx={{
                textWrap: "wrap",
                fontSize: 28,
                fontWeight: "bold",
                color: "#3F41D1",
              }}
            >
              Application No - {applicantid}
            </Typography>
          )}
      </Box>
      <Grid container mt={1}>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Personal Details</Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=personalinformation`,
                    { state: { courseid: courseid } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Candidate’s Full name
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {personalDetails[0]?.candidatename}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Father’s Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.fathersname}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Mother’s Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.mothername}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Marital Status
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {parseInt(courseFullRegistrationDetails[0]?.maritalstatusid) ===
                1
                  ? "Married"
                  : "Unmarried"}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Spouse’s Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (courseFullRegistrationDetails[0]?.spousename || "-----")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Gender
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.gender}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Date of Birth
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {personalDetails[0]?.dob &&
                  dayjs(personalDetails[0]?.dob).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Religion
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.religion}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Region
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.region}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Mother Tongue
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.mothertongue}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Annual Income
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.incomerange}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Nationality
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.nationality}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Permanent Address</Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=address`,
                    { state: { courseid: courseid } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Address Line 1
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.paddressline1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 2
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.paddressline2}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 3
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (courseFullRegistrationDetails[0]?.paddressline3 || "-----")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                State
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.pstate}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                District
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.pdistrict}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Taluka
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.ptaluka}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Village
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.pvillage}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Pin code
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.ppincode}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Address for Correspondence
              </Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=address`,
                    { state: { courseid: courseid } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                {" "}
                Address Line 1
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.caddressline1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 2
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.caddressline2}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Address Line 3
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (courseFullRegistrationDetails[0]?.caddressline3 || "-----")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                State
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.cstate}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                District
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.cdistrict}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Taluka
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.ctaluka}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Village
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.cvillage}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Pin code
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.cpincode}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Telephone No
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.phoneno}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Academic Details</Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=academicdetails`,
                    { state: { courseid: courseid } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC School State
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.sscschoolstate}
              </Typography>
            </Grid>
            {/* </Grid>
          <Grid container md={12}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Select SSC/Equivalent Passing Year
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.sscpassingyear}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC/Equivalent Board
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.sscboard}
              </Typography>
            </Grid>
            {/* </Grid>
          <Grid container md={12}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                SSC/Equivalent Total Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.ssctotalpercentage}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Select HSC/Diploma Passing Year
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.hscpassingyear}
              </Typography>
            </Grid>
            {/* </Grid>
          <Grid container md={12}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Select HSC/Diploma/Equivalent Board
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.hscboard}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Marks Type
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (parseInt(courseFullRegistrationDetails[0]?.markstype) === 1
                    ? "Percentage"
                    : "CGPA")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC/Diploma Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.hscmarksobtained}
              </Typography>
            </Grid>
            {/* </Grid>
          <Grid container md={12}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC/Diploma Marks Out Of
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.hscmarksoutof}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                HSC/Diploma Calculated/Equivalent Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.hscpercentage}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Are you Appearing/Appeared for 12th HSC/Diploma exam in 2024
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (parseInt(courseFullRegistrationDetails[0]?.appearingfor) ===
                  1
                    ? "Yes"
                    : "No")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Candidate's Name as on HSC Marksheet
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.cndnameonhscmarksheet}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Candidate's Mother Name as on HSC Marksheets
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.cndmothernameonhscmarksheet}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Reservation Deatils</Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=domicileandcategorydetails`,
                    { state: { courseid: courseid } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Do you have Domicile Certificate?
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (parseInt(
                    courseFullRegistrationDetails[0]?.domiicilecertificatestatus
                  ) === 1
                    ? "Yes"
                    : "No")}
              </Typography>
            </Grid>
            {/* </Grid>
          <Grid container md={6}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Do you belong to Minority Category?
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (parseInt(courseFullRegistrationDetails[0]?.isminority) === 1
                    ? "Yes"
                    : "No")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Linguistic Minority
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (courseFullRegistrationDetails[0]?.linguisticminority ||
                    "-----")}
              </Typography>
            </Grid>
            {/* </Grid>
          <Grid container md={12}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Category of Candidate
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (courseFullRegistrationDetails[0]?.category || "-----")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Caste of Candidate
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  courseFullRegistrationDetails[0]?.caste}
              </Typography>
            </Grid>
            {/* </Grid>
          <Grid container md={12}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Religious Minority
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  courseFullRegistrationDetails[0]?.religiousminority}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Do you possess Caste Certificate?
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (parseInt(
                    courseFullRegistrationDetails[0]?.castecertificatestatus
                  ) === 1
                    ? "Yes"
                    : "No")}
              </Typography>
            </Grid>
            {/* </Grid>
          <Grid container md={12}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Caste Certificate No
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (courseFullRegistrationDetails[0]?.castecertificateno ||
                    "-----")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Do you possess Caste Validity?
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (parseInt(
                    courseFullRegistrationDetails[0]?.castevaliditystatus
                  ) === 1
                    ? "Yes"
                    : "No")}
              </Typography>
            </Grid>
            {/* </Grid>
          <Grid container md={12}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Caste valdity
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (dayjs(
                    courseFullRegistrationDetails[0]
                      ?.castecertificatevaliditydate
                  ).format("DD-MM-YYYY") ||
                    "-----")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Caste Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  courseFullRegistrationDetails[0]?.castename}
              </Typography>
            </Grid>
            {/* <Grid container md={12}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Are you Orphan?
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (parseInt(courseFullRegistrationDetails[0]?.isorphan) === 1
                    ? "Yes"
                    : "No")}
              </Typography>
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Are you person with Disability?
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (parseInt(courseFullRegistrationDetails[0]?.isdisability) ===
                  1
                    ? "Yes"
                    : "No")}
              </Typography>
            </Grid>
            {/* </Grid>
          <Grid container md={12}> */}
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Type of Disability
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (courseFullRegistrationDetails[0]?.disabilitytypeid ||
                    "-----")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Do you have Non creamy layer certificate valid upto March 31st?
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (parseInt(
                    courseFullRegistrationDetails[0]?.noncreamylayercertificate
                  ) === 1
                    ? "Yes"
                    : "No")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                EWS (Applicable only for Open Category Candidates)
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (parseInt(courseFullRegistrationDetails[0]?.appliedforews) ===
                  1
                    ? "Yes"
                    : "No")}
              </Typography>
            </Grid>
          </Grid>

          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Exam Centre Selection Details
              </Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=examinationdetails`,
                    { state: { courseid: courseid } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Subject Group for Exam
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.subjectgroup}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Examination Centre State
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.examstate}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Language / Medium of the Question Paper
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              {courseFullRegistrationDetails[0]?.language?.map((val, i) => (
                <Typography
                  key={i}
                  sx={{
                    fontSize: 15,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {i + 1}.{val}
                </Typography>
              ))}
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Examination Center District at Preference Number 1
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.preference1district}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Examination Center District at Preference Number 2
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.preference2district}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Examination Center District at Preference Number 3
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails[0]?.preference3district}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
              >
                Examination Center District at Preference Number 4
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFullRegistrationDetails?.length > 0 &&
                  (courseFullRegistrationDetails[0]?.preference4district ||
                    "-----")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Photograph and Signature
              </Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/registration?page=uploads`,
                    { state: { courseid: courseid } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                Photograph
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Signature
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{
                border: "1px solid #F7F7F7",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "140px", width: "auto" }}
                src={uploadDetails[0]?.document}
                alt="photo"
              />
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{
                border: "1px solid #F7F7F7",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "53px", width: "auto" }}
                src={uploadDetails[1]?.document}
                alt="signatute"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Document Uploaded</Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/registration?page=uploads`,
                    { state: { courseid: courseid } }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={2}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "#3F41D1",
                  fontWeight: 500,
                }}
              >
                S.No
              </Typography>
            </Grid>
            <Grid
              item
              md={10}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              {/* <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "#3F41D1",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Signature
              </Typography> */}
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={2}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                1
              </Typography>
            </Grid>
            <Grid
              item
              md={10}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {uploadDetails[0]?.documentname}
              </Typography>
            </Grid>
            {location.pathname + location.search ===
              "/mhcet/course-registration?page=preview" && (
              <>
                <Grid
                  item
                  md={12}
                  p={1}
                  xs={12}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    Note :
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  p={1}
                  xs={12}
                  sx={{ border: "1px solid #F7F7F7" }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "red",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    *Candidate Shall Carry Disability Certificate at the time of
                    Examination.( For All PWD Candidate)
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  p={1}
                  xs={12}
                  sx={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                  >
                    <Checkbox
                      onChange={handleCheckbox}
                      checked={CheckboxValue}
                      sx={{ margin: 0, padding: 0 }}
                    />
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "red",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      I have read all important Instructions.
                    </Typography>
                  </Box>
                </Grid>
                {error && (
                  <small style={{ marginTop: ".5rem" }} className="error">
                    {error}
                  </small>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {location.pathname + location.search ===
        "/mhcet/course-registration?page=preview" && (
        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : null,
          }}
        >
          <MauvelousPrimaryButton
            title={buttonLoader ? "loader" : "Continue to payment"}
            handleClick={handleProcced}
            disabled={!CheckboxValue}
          />
        </Grid>
      )}
    </Grid>
  );
}
