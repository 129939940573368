import { Button } from "@mui/material";
import React from "react";


export default function PrintDownloadButton({name,handleSubmit,startIcon,disabled}) {
return(
    <Button
    disabled={disabled}
      variant="contained"
      sx={{
        boxShadow: "none",
        whiteSpace: "nowrap",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "15.4px",
        borderRadius: "40px",
        padding: "10px 24px",
        boxShadow: "none",
        backgroundColor: "#F0F3F7",
        color: "#3F41D1",
        minWidth: "3rem",
        height: "40px",
        textAlign: "center",
        border:"1px solid #3F41D1",
        "&:hover": {
          backgroundColor: "#F0F3F7",
          boxShadow: "none",
        },
      }}
      onClick={handleSubmit}
      startIcon={startIcon}
    >
      {name}
    </Button>)
}