import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";

export default function PageHeading({ page, align }) {
  return (
    <Grid pb={1} sx={{ display: "flex", justifyContent: "space-between",width:'100%' }}>
      <Box sx={{width:'100%' }}>
        <Typography
          variant="h5"
          sx={{
            color: `${cssProperties?.fontcolor?.primary}`,
            fontWeight: "500",
            fontSize: "20px",
            textAlign: align ? align : 'start',
          }}
        >
          {page}
        </Typography>
      </Box>
    </Grid>
  );
}
