import React, { useEffect, useState } from "react";
import DataTable from "../../common/dataTable";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import ViewImageModal from "../modals/viewImageModal";
import TablePagination from "../../common/tablePagination";
import { Box, Grid } from "@mui/material";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { useNavigate } from "react-router-dom";
import  Cookies from 'js-cookie';

export default function Index() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { queryByCanidate } = useSelector((state) => state.candidatesReducer);
  const { candidateTokenDetails } = useSelector((state) => state.authReducer);

  const [showModal, setShowModal] = useState({
    show: false,
    document: null,
    documentname: null,
  });

  useEffect(() => {
    if (candidateTokenDetails?.candidateid) {
      dispatch({ type: candidatesActions.GET_QUERY_STATUS_BY_CANDIDATEID });
    }
  }, [candidateTokenDetails]);

  const tableKeys = [
    {
      name: "SI.NO",
      accessor: "id",
      sortOption: false,
    },
    {
      name: "Ticket Id",
      accessor: "ticketcode",
      sortOption: false,
    },
    {
      name: "Ticket raised date",
      accessor: "ticketraisedon",
      sortOption: false,
    },
    {
      name: "# of days",
      accessor: "noofdays",
      sortOption: false,
    },
    {
      name: "Details",
      accessor: "grievancedetails",
      sortOption: false,
    },
    {
      name: "Document",
      accessor: "documentname",
      sortOption: false,
    },
    {
      name: "Current status",
      accessor: "status",
      sortOption: false,
      colorChange: "ticketStatus",
    },
    {
      name: "Ticket closed date",
      accessor: "ticketClosedOn",
      sortOption: false,
      colorChange: "ticketClosedDate",
    },
  ];

  const handleViewImage = (value) => {
    setShowModal({
      show: true,
      document: value?.document,
      documentname: value?.documentname,
    });
  };

  const handlePageChange = (event, value) => {
    const newOffset = (value - 1) * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(value);
  };

const endOffset = itemOffset + itemsPerPage;
const currentItems = queryByCanidate?.slice(itemOffset, endOffset);
const pageCount = Math.ceil(queryByCanidate?.length / itemsPerPage);

const handleRaiseAQuery = () =>{  navigate(`/${Cookies.get('mhet_cnd_project')}/raise-query`) }
  return (
    <Grid>
       <Box sx={{display:"flex",justifyContent:"flex-end"}}>
        <MauvelousPrimaryButton title={"Raise a query"} handleClick={handleRaiseAQuery} />
      </Box>
      <DataTable
        keys={tableKeys}
        values={currentItems}
        page={"queryList"}
        handleViewImage={handleViewImage}
      />
      <TablePagination
        pageCount={pageCount}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />
      <ViewImageModal
        showModal={showModal?.show}
        document={showModal?.document}
        documentname={showModal?.documentname}
        handleClose={() =>
          setShowModal({ show: false, document: null, documentname: null })
        }
      />
    </Grid>
  );
}
