import React, { useEffect, useState } from "react";
import DataTable from "../../common/dataTable";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import ViewImageModal from "../modals/viewImageModal";
import TablePagination from "../../common/tablePagination";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Index() {
  const navigate = useNavigate();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemOffset, setItemOffset] = useState(0);
  // const itemsPerPage = 5;



  // const [showModal, setShowModal] = useState({
  //   show: false,
  //   document: null,
  //   documentname: null,
  // });


  const tableKeys = [
    {
      name: "SI.NO",
      accessor: "id",
      sortOption: false,
    },
    {
      name: "Course Name",
      accessor: "coursename",
      sortOption: false,
    },
    {
      name: "Applied on",
      accessor: "appliedon",
      sortOption: false,
    },
    {
      name: "Current status",
      accessor: "currentStatus",
      sortOption: false
    }
  ];

const values = [
  {id:"01",coursename:"Test course 01",appliedon:"test date 1",currentStatus:"generated"},
  {id:"02",coursename:"Test course 02",appliedon:"test date 2",currentStatus:"generated"},
  {id:"03",coursename:"Test course 03",appliedon:"test date 3",currentStatus:"generated"},
  {id:"04",coursename:"Test course 04",appliedon:"test date 4",currentStatus:"not generated"},
  {id:"05",coursename:"Test course 05",appliedon:"test date 5",currentStatus:"not generated"},
]

const handleViewImageAdmitCard = () => {
  navigate(`/${Cookies.get("mhet_cnd_project")}/admit-card`);
}

//   const handlePageChange = (event, value) => {
//     const newOffset = (value - 1) * itemsPerPage;
//     setItemOffset(newOffset);
//     setCurrentPage(value);
// };
// const endOffset = itemOffset + itemsPerPage;
const currentItems = values
// const pageCount = Math.ceil(queryByCanidate?.length / itemsPerPage);

  return (
    <Grid>
      <DataTable
        keys={tableKeys}
        values={currentItems}
        page={"Admit Card List"}
        handleViewImageAdmitCard={handleViewImageAdmitCard}
      />
      <TablePagination
        // pageCount={pageCount}
        // handlePageChange={handlePageChange}
        // currentPage={currentPage}
      />
      
    </Grid>
  );
}
