
import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useForm } from "react-hook-form";
import {
    Typography,
    TextField,
    Grid,
    Button,
    Box
} from "@mui/material";
import { FaArrowLeftLong } from "react-icons/fa6";
import { cssProperties } from "../../utils/commonCssProperties";
import { useDispatch } from "react-redux";
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import authActions from "../../redux/auth/actions";
import { MobileNumberFirstNumberValidation, MobileNumberValidation } from "../../utils/validations";
import verifiedIcon from "../../assets/images/icons/verified.svg";

const ForgetEmail = (props) => {
    const { setemailForget, emailForget, setReset, setShow, setVerify } = props
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [Forget, setForget] = useState(true)
    const dispatch = useDispatch();
    const [email, setEmail] = useState();
    const [focusedInput, setFocusedInput] = useState(null);
    const [resendCountdown, setResendCountdown] = useState(60);
    const [verified, setVerified] = useState(true)
    const [error, setError] = useState(""); const [otp, setOtp] = useState("");
    const inputStyle = (isFocused) => ({
        width: "38px",
        height: "40px",
        margin: "5px",
        borderRadius: "8px",
        outline: "none",
        border: ` ${isFocused ? "5px solid #BDD8FE" : "1px solid #504E50"}`,
        textAlign: "center"
    });
    const onSubmit = (data) => {
        Cookies.set('mhet_cnd_forgetemail_mobileno', EncryptFunction(data?.mobileno))
        dispatch({ type: authActions.CHECK_CANDIDATE_MOBILENO, payload: { data: data?.mobileno, setForget: setForget, setResendCountdown: setResendCountdown } });

    };
    useEffect(() => {
        const cookieData = Cookies.get('mhet_cnd_tempdata');
        if (cookieData) {
            const decryptedData = DecryptFunction(cookieData);
            try {
                const parsedData = JSON.parse(decryptedData);
                setEmail(parsedData.emailid);
            } catch (error) {
                console.error('Error parsing decrypted data:', error);
            }
        }
    }, []);

    useEffect(() => {
        let timer;
        if (resendCountdown > 0) {
            timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [resendCountdown]);

    useEffect(() => {
        if (otp.length === 6) {
            handleVerify()
        }
    }, [otp])

    const handleVerify = () => {
        const otpValue = DecryptFunction(Cookies.get('mhet_cnd_forgetemail_otp'));
        let mobileno = DecryptFunction(Cookies.get('mhet_cnd_forgetemail_mobileno'))
        const modifyOtp = otp.split("");
        if (modifyOtp.length === 6) {
            if (modifyOtp.join("") === otpValue) {
                Cookies.remove('mhet_cnd_forgetemail_otp')
                dispatch({ type: authActions.FORGOTPASSWORD_MOBILEVERIFICATION, payload: { validated: true } });
                dispatch({ type: authActions.FORGOTPASSWORD_MOBILEVERIFICATION_SENDMAILID, payload: { data: mobileno } });
                Cookies.remove('mhet_cnd_forgetemail_mobileno')
                setVerified(false)
                dispatch({ type: authActions.VERIFY_MOBILENO_FOR_SIGNUP, payload: { data: { token: Cookies.get('mhet_cnd_temptoken') } } })
                // navigate(`/${Cookies.get('mhet_cnd_project')}/mobileno-verification`);
            } else {
                setError("Invalid OTP");
            }
        } else {
            setError("Enter valid OTP");
        }
    };

    const handleResendOTP = () => {
        setResendCountdown(60);
    };
    return (
        <div>
            <Grid container spacing={2} p={3} direction="column" alignItems="center">

                {Forget ? (
                    <Grid container spacing={2} p={3} direction="column" alignItems="center">
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    color: `#504E50`,
                                    lineHeight: "24px",
                                    textAlign: "left",
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <FaArrowLeftLong
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setReset(true); // Show ForgetPassword component
                                        setemailForget(false); // Hide ForgetEmail component
                                    }}
                                    style={{ cursor: "pointer", marginRight: "8px" }} // Adds space between arrow and text
                                />
                                Forgot Email ID
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: `#504E50`,
                                    lineHeight: "16.94px"
                                }}
                            >
                                Registered mobile number
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: `rgba(80, 78, 80, 0.7)`,
                                    lineHeight: "14.54px",
                                }}
                            >
                                To retrieve your Email ID, please enter your registered mobile number.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <TextField
                                size="small"
                                {...register("mobileno", {
                                    required: "Mobile number is required",
                                    minLength: {
                                        value: 10,
                                        message: "Enter valid mobile number",
                                    },
                                    validate: MobileNumberFirstNumberValidation,
                                })}
                                error={!!errors.mobileno}
                                helperText={errors.mobileno ? errors.mobileno.message : ""}
                                fullWidth
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "10px",
                                        minHeight: "52px",
                                        "& fieldset": {
                                            border: "2px solid rgba(189, 216, 254, 1)",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "rgba(100, 150, 255, 1)",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "rgba(50, 100, 255, 1)",
                                        },
                                        "&.Mui-error fieldset": {
                                            borderColor: "red",
                                        },
                                    },
                                    "& .MuiFormHelperText-root": {
                                        margin: 0,
                                        color: "red",
                                    },
                                }}
                                onInput={MobileNumberValidation}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%", textAlign: "right" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: `rgba(15, 79, 150, 1)`,
                                    lineHeight: "16.94px",

                                }}

                            >
                                <span
                                    style={{
                                        color: `rgba(15, 79, 150, 1)`,
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault()

                                        setReset(true);
                                        setShow(true);
                                    }}>

                                    Go back to login page
                                </span>

                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={
                                    handleSubmit(onSubmit)
                                }
                                sx={{
                                    background: "rgba(15, 79, 150, 1)",
                                    color: "#D7E2E9",
                                    padding: "10px",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    minHeight: "40px",
                                    lineHeight: "16.4px",
                                    "&:hover": {
                                        backgroundColor: "rgba(15, 79, 150, 0.8)",
                                    },
                                }}
                            >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                ) : (verified ?
                    <Grid container p={3} direction="column" alignItems="center">
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    color: `#504E50`,
                                    lineHeight: "24px",
                                    textAlign: "left",
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <FaArrowLeftLong
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setReset(true); // Show ForgetPassword component
                                        setemailForget(false); // Hide ForgetEmail component
                                    }}
                                    style={{ cursor: "pointer", marginRight: "8px" }} // Adds space between arrow and text
                                />
                                Forgot Email ID
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: `#504E50`,
                                    lineHeight: "16.94px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                Enter OTP
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: `rgba(80, 78, 80, 0.6);
`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                We've sent a 6-digit verification code to your registered mobile number. <br />
                                Please enter it below to confirm your identity.
                            </Typography>
                        </Grid>
                        <Box my={2}>
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderInput={(props, index) => (
                                    <input
                                        {...props}
                                        style={inputStyle(focusedInput === index)}
                                        onFocus={() => setFocusedInput(index)}
                                        onBlur={() => setFocusedInput(null)}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Typography variant="body2" sx={{ color: 'red', marginTop: '12px' }}>
                                {error && <small className="error">{error}</small>}
                            </Typography>
                        </Box>
                        <Grid item xs={12} lg={3} sx={{
                            // justifyContent: "center",
                            // alignItems: "center",
                            // textAlign: "center"
                            padding: 0
                        }}>
                            <Button
                                variant="contained"
                                fullWidth
                                // disabled={resendCountdown === 0 ? true : false}
                                onClick={handleVerify}
                                sx={{
                                    background: "rgba(15, 79, 150, 1)",
                                    color: "#D7E2E9",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    minHeight: "40px",
                                    maxwidth: "320px",
                                    width: "290px",
                                    lineHeight: "16.4px",
                                    "&:hover": {
                                        backgroundColor: `${cssProperties?.color?.buttonHover}`,
                                    },

                                }}
                            >
                                Continue
                            </Button>
                        </Grid>
                        <Grid
                            sx={{
                                width: "18rem",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {!resendCountdown > 0 && (
                                <Typography
                                    mt={2}
                                    sx={{
                                        cursor: "pointer",
                                        color: `${cssProperties?.color?.primary}`,
                                        fontWeight: "bold",
                                    }}
                                    onClick={handleResendOTP}
                                >
                                    Resend OTP
                                </Typography>
                            )}

                            {resendCountdown > 0 && (

                                <Typography
                                    sx={{
                                        color: `6B6B6B`,
                                        fontWeight: "bold",
                                    }}
                                    textAlign={"center"}
                                    mt={2}

                                >
                                    RESEND OTP IN &nbsp;
                                    {resendCountdown > 0 ? `(${resendCountdown}) SEC` : "0 SEC"}
                                </Typography>

                            )}
                            <Typography
                                mt={2}
                                sx={{
                                    color: `6B6B6B`,
                                    fontWeight: "bold",
                                }}
                            >
                                {otp?.length} / 6 &nbsp;
                            </Typography>

                        </Grid>
                    </Grid> :
                    <Grid container spacing={2} p={3} direction="column" alignItems="center">
                        <Grid item xs={12} sx={{
                            width: "100%", marginBottom: 2,
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <img src={verifiedIcon} alt="verified" style={{
                                width: "96px",
                                height: "91.73px"
                            }} />
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    color: `rgba(0, 0, 0, 0.87)`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                Verification successful
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: `rgba(0, 0, 0, 0.87)`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                Thank you for verifying your identity. Your registered email is:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    color: `rgba(0, 0, 0, 0.87)`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{
                            width: "100%", display: "flex",
                            justifyContent: "center"
                        }}>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    marginBottom: 2,
                                    background: "rgba(15, 79, 150, 1)",
                                    color: "#D7E2E9",
                                    padding: "10px",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    minHeight: "40px",
                                    width: "300px",
                                    lineHeight: "16.4px",
                                    "&:hover": {
                                        backgroundColor: `${cssProperties?.color?.buttonHover}`,
                                    },

                                }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShow(true)
                                }}
                            >
                                Go to Login Page
                            </Button>
                        </Grid>
                        <Grid>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: `rgba(0, 0, 0, 0.87)`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                OR                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ width: "100%", textAlign: "right" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: `rgba(15, 79, 150, 1)`,
                                    lineHeight: "16.94px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >

                                <span
                                    style={{
                                        color: `rgba(15, 79, 150, 1)`,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setVerify(false);
                                        setReset(true);
                                        setemailForget(false)
                                    }}
                                >
                                    Reset Password
                                </span>
                            </Typography>

                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

export default ForgetEmail;
