import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BackButton from "../../common/backButton";
import SubmitButton from "../../common/submitButton";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import masterActions from "../../redux/master/action";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import candidatesActions from "../../redux/candidates/actions";
import { masterFieldLabel, isFieldDisabled } from "../../utils/courseValues";

export default function ReservationDetailsForm({
  nextPageName,
  previousPageName,
}) {
  const [disability, setDisability] = useState("");
  const location = useLocation();
  const courseid = location.state?.courseid;
  const { masterFields } = useSelector((state) => state.candidatesReducer);
  console.log('masterFields-----',masterFields);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const date = new Date();
  const currentYear = date.getFullYear();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { pwdType, category, caste, mothertongue, religion } = useSelector(
    (state) => state.masterReducer
  );
  console.log(religion);
  const { courseFullRegistrationDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );

  useEffect(() => {
    dispatch({ type: masterActions.GET_BOARD });
    dispatch({ type: masterActions.GET_PWD_TYPE });
    dispatch({ type: masterActions.GET_CATEGORY });
    dispatch({ type: masterActions.GET_CASTE });
    dispatch({ type: masterActions.GET_MOTHERTONGUE });
  }, []);

  useEffect(() => {
    if (courseFullRegistrationDetails?.length > 0) {
      reset({ ...courseFullRegistrationDetails[0] });
    }
  }, [courseFullRegistrationDetails]);

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: {
          courseid: courseid,
        },
      });
    }
  }, [candidateid]);

  const handleDisability = (e) => {
    setValue("isdisability", parseInt(e.target.value));
    setDisability(e.target.value);
    trigger("isdisability");
  };

  const handleBack = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${previousPageName}`,
      { state: { courseid: courseid } }
    );
  };

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  const handleProcced = (data) => {
    console.log('data',data);
    const corcandidatereservationid =
      courseFullRegistrationDetails[0]?.corcandidatereservationid;
    dispatch({
      type: candidatesActions.COURSE_RESERVATION_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidatereservationid
            ? parseInt(corcandidatereservationid)
            : 0,
          candidateid: candidateid,
          status: 1,
          createdby: candidateid,
          updatedby: corcandidatereservationid ? parseInt(candidateid) : "",
          courseid: courseid,
        },
        navigate: navigate,
        nextPageName: nextPageName,
      },
    });
  };


  console.log('getValues("castevaliditystatus")-------',getValues("castevaliditystatus"));
  return (
    <Grid
      p={3}
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small" error={!!errors.domiicilecertificatestatus}>
            <InputLabel
              shrink={!!watch("domiicilecertificatestatus") || watch("domiicilecertificatestatus") === 0}
              htmlFor="domiicilecertificatestatus"
            >
              Are you Domiciled in the state of Maharashtra?
              <span className="error">*</span>
            </InputLabel>
            <Select
              id="domiicilecertificatestatus"
              label="Are you Domiciled in the state of Maharashtra?"
              {...register("domiicilecertificatestatus", {
                required: "Is MH Candidate is required",
              })}
              onChange={(e) => {
                setValue("domiicilecertificatestatus", e.target.value);
                trigger("domiicilecertificatestatus");
              }}
              value={watch("domiicilecertificatestatus") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
            {errors.domiicilecertificatestatus && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.domiicilecertificatestatus.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}

{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.IS_MH_CANDIDATE
        ) && (

<Grid item xs={12} md={6}>
            <FormControl fullWidth size="small"  error={!!errors.ismhcandidate}>
              <InputLabel shrink={!!watch("ismhcandidate") || watch("ismhcandidate") === 0} htmlFor="ismhcandidate">
                
              {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel?.IS_MH_CANDIDATE
                  )?.fieldlabel 
                }

{masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.IS_MH_CANDIDATE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
                  
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              // disabled={disabled}
                id="ismhcandidate"
                label="Are you Domiciled in the state of Maharashtra?"
                {...register("ismhcandidate", {
                  required:  masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel?.IS_MH_CANDIDATE
                      )?.ismandatory === "1" ? 'MH Candidate is required':false
                     ,
                })}
                onChange={(e) => {
                  setValue("ismhcandidate", e.target.value);
                  trigger("ismhcandidate");
                }}
                value={watch("ismhcandidate") ?? ""}
            
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.ismhcandidate && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.ismhcandidate.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
       
       )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.DOMICILE_CERTIFICATE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.domiicilecertificatestatus}
            >
              <InputLabel
                shrink={
                  !!watch("domiicilecertificatestatus") ||
                  watch("domiicilecertificatestatus") === 0
                }
                htmlFor="domiicilecertificatestatus"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.DOMICILE_CERTIFICATE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.DOMICILE_CERTIFICATE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="domiicilecertificatestatus"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.DOMICILE_CERTIFICATE
                )?.ismandatory === "1" ? "Select is required" : false}}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="domiicilecertificatestatus"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.DOMICILE_CERTIFICATE
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.DOMICILE_CERTIFICATE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.DOMICILE_CERTIFICATE)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.domiicilecertificatestatus && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.domiicilecertificatestatus.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.MINORITY_CATEGORY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.isminority}>
              <InputLabel
                shrink={!!watch("isminority") || watch("isminority") === 0}
                htmlFor="isminority"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.MINORITY_CATEGORY
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.MINORITY_CATEGORY
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="isminority"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.MINORITY_CATEGORY
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="isminority"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.MINORITY_CATEGORY
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.MINORITY_CATEGORY
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.MINORITY_CATEGORY)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.isminority && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isminority.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.LINGUISTIC_MINORITY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.linguisticminorityid}
            >
              <InputLabel
                shrink={
                  !!watch("linguisticminorityid") ||
                  watch("linguisticminorityid") === 0
                }
                htmlFor="linguisticminorityid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.LINGUISTIC_MINORITY
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.LINGUISTIC_MINORITY
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="linguisticminorityid"
                control={control}
                rules={{
                  required:
                    ((getValues("isminority") === 0) || masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.LINGUISTIC_MINORITY
                    )?.ismandatory === "1")
                      ? false
                      : "Select Linguistic Minority is required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="linguisticminorityid"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.LINGUISTIC_MINORITY
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.LINGUISTIC_MINORITY
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    disabled={getValues("isminority") !== 1}
                    value={field.value ?? ""}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    {mothertongue?.map((val) => (
                      <MenuItem
                        key={val?.mothertongueid}
                        value={val?.mothertongueid}
                      >
                        {val?.mothertongue}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.linguisticminorityid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.linguisticminorityid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.RELIGIOUS_MINORITY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.categoryid}>
              <InputLabel
                shrink={!!watch("categoryid") || watch("categoryid") === 0}
                htmlFor="categoryid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.RELIGIOUS_MINORITY
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.RELIGIOUS_MINORITY
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="categoryid"
                control={control}
                rules={{
                  required:
                    (getValues("isminority") === 0 || masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.RELIGIOUS_MINORITY
                    )?.ismandatory === "1")
                      ? false
                      : "Category of Candidate is required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="categoryid"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.RELIGIOUS_MINORITY
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.RELIGIOUS_MINORITY
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    disabled={getValues("isminority") !== 1}
                    value={field.value ?? ""}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    {category?.map((val) => (
                      <MenuItem key={val?.categoryid} value={val?.categoryid}>
                        {val?.category}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.categoryid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.categoryid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SELECT_CASTE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.casteid}>
              <InputLabel
                shrink={!!watch("casteid") || watch("casteid") === 0}
                htmlFor="casteid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SELECT_CASTE
                  )?.fieldlabel
                } 
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.SELECT_CASTE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="casteid"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.SELECT_CASTE
                )?.ismandatory === "1" ? "Select casteid is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="casteid"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.SELECT_CASTE
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SELECT_CASTE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.SELECT_CASTE)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    {caste?.map((val) => (
                      <MenuItem key={val?.casteid} value={val?.casteid}>
                        {val?.caste}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.casteid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.casteid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.RELIGIOUS_MINORITY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.religiousminorityid}
            >
              <InputLabel
                shrink={
                  !!watch("religiousminorityid") ||
                  watch("religiousminorityid") === 0
                }
                htmlFor="religiousminorityid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.RELIGIOUS_MINORITY
                  )?.fieldlabel
                }
                <span className="error">*</span>
              </InputLabel>
              <Controller
                name="religiousminorityid"
                control={control}
                rules={{ required: "Select Religion is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="religiousminorityid"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.RELIGIOUS_MINORITY
                            )?.fieldlabel
                          }
                        </span>
                        *
                      </span>
                    }
                    value={field.value ?? ""}
                  >
                    <MenuItem value="" disabled>Select One</MenuItem>
                    {religion?.map((val) => (
                      <MenuItem key={val?.religionid} value={val?.religionid}>
                        {val?.religion}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.religiousminorityid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.religiousminorityid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )} */}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.POSSESS_CASTE_CERTIFICATE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.castecertificatestatus}
            >
              <InputLabel
                shrink={
                  !!watch("castecertificatestatus") ||
                  watch("castecertificatestatus") === 0
                }
                htmlFor="castecertificatestatus"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="castecertificatestatus"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                )?.ismandatory === "1" ? "Select is required" : false}}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="castecertificatestatus"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.POSSESS_CASTE_CERTIFICATE)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Available</MenuItem>
                    <MenuItem value={0}>Applied But Not Received</MenuItem>
                  </Select>
                )}
              />
              {errors.castecertificatestatus && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.castecertificatestatus.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}


        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.POSSESS_CASTE_VALIDITY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.castevaliditystatus}
            >
              <InputLabel
                shrink={
                  !!watch("castevaliditystatus") ||
                  watch("castevaliditystatus") === 0
                }
                htmlFor="castevaliditystatus"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.POSSESS_CASTE_VALIDITY
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.POSSESS_CASTE_VALIDITY
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="castevaliditystatus"
                control={control}
                rules={{ required:masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.POSSESS_CASTE_VALIDITY
                )?.ismandatory === "1" ? "Caste Validity is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="castevaliditystatus"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.POSSESS_CASTE_VALIDITY
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.POSSESS_CASTE_VALIDITY
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.POSSESS_CASTE_VALIDITY)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={2}>Applied But Not Received</MenuItem>
                    <MenuItem value={1}>Available</MenuItem>
                    <MenuItem value={0}>Not Applied</MenuItem>
                  </Select>
                )}
              />
              {errors.castevaliditystatus && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.castevaliditystatus.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}




        

{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_CERTIFICATE_NO
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_CERTIFICATE_NO
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_CERTIFICATE_NO
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter the SSC Total percentage"
              {...register("castecertificateno", {
                required:
                  (getValues("castecertificatestatus") === 0 || masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_CERTIFICATE_NO
                  )?.ismandatory === "0")
                    ? false
                    : "Caste certificate no is required",
                // validate: percentageValidation,
              })}
              // onInput={NumbersValidation}
              disabled={
                getValues("castecertificatestatus") === 0 ? true : false
              }
              error={!!errors.castecertificateno}
              helperText={errors.castecertificateno?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("castecertificateno"),
              }}
            />
          </Grid>
        )}
     
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.CASTE_VALIDITY
        ) && (
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="castecertificatevaliditydate"
                control={control}
                rules={{
                  required:
                    ((getValues("castevaliditystatus") !== 1 )|| masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_VALIDITY
                    )?.ismandatory !== "1")
                      ? false
                      : "Caste valdity date is required",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.CASTE_VALIDITY
                          )?.fieldlabel
                        }
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CASTE_VALIDITY
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    disabled={
                      getValues("castevaliditystatus") == 0 ? true : false
                    }
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      if (date) {
                        const formattedDate = dayjs(date)
                          .hour(18)
                          .minute(30)
                          .second(0)
                          .millisecond(0)
                          .toISOString();
                        field.onChange(formattedDate);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        error: !!errors.castecertificatevaliditydate,
                        helperText:
                          errors.castecertificatevaliditydate?.message,
                        FormHelperTextProps: {
                          style: { margin: 0 },
                        },
                        style: {
                          width: "100%",
                          borderRadius: "8px",
                        },
                      },
                      layout: {
                        sx: {
                          borderRadius: "58px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}

     
  

{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter the SSC Total percentage"
              {...register("castecertificatappeno", {
                required:
                  (getValues("castecertificatappeno") === 0 || masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
                  )?.ismandatory === "0")
                    ? false
                    : "Caste certificate no is required",
                // validate: percentageValidation,
              })}
              // onInput={NumbersValidation}
              disabled={
                getValues("castecertificatestatus") === 0 ? true : false
              }
              error={!!errors.castecertificateno}
              helperText={errors.castecertificateno?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("castecertificatappeno"),
              }}
            />
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
        ) && (
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="castevalidityapplicationdate"
                control={control}
                rules={{
                  required:
                    ((getValues("castevaliditystatus") !== 1 )|| masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
                    )?.ismandatory !== "1")
                      ? false
                      : "Caste valdity date is required",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
                          )?.fieldlabel
                        }
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    disabled={
                      getValues("castevaliditystatus") == 0 ? true : false
                    }
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      if (date) {
                        const formattedDate = dayjs(date)
                          .hour(18)
                          .minute(30)
                          .second(0)
                          .millisecond(0)
                          .toISOString();
                        field.onChange(formattedDate);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        error: !!errors.castecertificatevaliditydate,
                        helperText:
                          errors.castecertificatevaliditydate?.message,
                        FormHelperTextProps: {
                          style: { margin: 0 },
                        },
                        style: {
                          width: "100%",
                          borderRadius: "8px",
                        },
                      },
                      layout: {
                        sx: {
                          borderRadius: "58px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}

  

{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter the SSC Total percentage"
              {...register("castecertificateissueauthename", {
                required:
                  (getValues("castecertificateissueauthename") === 0 || masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
                  )?.ismandatory === "0")
                    ? false
                    : "Caste certificate no is required",
                // validate: percentageValidation,
              })}
              // onInput={NumbersValidation}
              disabled={
                getValues("castecertificateissueauthename") === 0 ? true : false
              }
              error={!!errors.castecertificateissueauthename}
              helperText={errors.castecertificateissueauthename?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("castecertificateissueauthename"),
              }}
            />
          </Grid>
        )}


{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_VALIDITY_ISSUING_DISTRICT
        ) && (

<Grid item xs={12} md={6}>
            <FormControl fullWidth size="small"  error={!!errors.castecertificateissuedist}>
              <InputLabel shrink={!!watch("castecertificateissuedist") || watch("castecertificateissuedist") === 0} htmlFor="castecertificateissuedist">
                
              {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel?.CASTE_VALIDITY_ISSUING_DISTRICT
                  )?.fieldlabel 
                }

{masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CASTE_VALIDITY_ISSUING_DISTRICT
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
                  
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              // disabled={disabled}
                id="castecertificateissuedist"
                label="Are you Domiciled in the state of Maharashtra?"
                {...register("castecertificateissuedist", {
                  required:  masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel?.CASTE_VALIDITY_ISSUING_DISTRICT
                      )?.ismandatory === "1" ? 'MH Candidate is required':false
                     ,
                })}
                onChange={(e) => {
                  setValue("castecertificateissuedist", e.target.value);
                  trigger("castecertificateissuedist");
                }}
                value={watch("castecertificateissuedist") ?? ""}
            
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.castecertificateissuedist && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.castecertificateissuedist.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
       
       )}


       
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter the SSC Total percentage"
              {...register("candidatenameasccertificate", {
                required:
                  (getValues("candidatenameasccertificate") === 0 || masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
                  )?.ismandatory === "0")
                    ? false
                    : "Caste certificate no is required",
                // validate: percentageValidation,
              })}
              // onInput={NumbersValidation}
              disabled={
                getValues("candidatenameasccertificate") === 0 ? true : false
              }
              error={!!errors.candidatenameasccertificate}
              helperText={errors.candidatenameasccertificate?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("candidatenameasccertificate"),
              }}
            />
          </Grid>
        )}



        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.ENTER_CASTE_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.ENTER_CASTE_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.ENTER_CASTE_NAME
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter Caste Name"
              {...register("castename", {
                required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.ENTER_CASTE_NAME
                )?.ismandatory === "1" ? "Caste name is required" : false,
              })}
              // onInput={AlphabetsValidation}
              error={!!errors.castename}
              helperText={errors.castename?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("castename"),
              }}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.ENTER_CASTE_NAME)}
            />
          </Grid>
        )}

        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small" error={!!errors.hscboardid}>
            <InputLabel shrink={!!watch("hscboardid")} htmlFor="hscboardid">
              HSC Board<span className="error">*</span>
            </InputLabel>
            <Select
              id="hscboardid"
              label="HSC Board"
              {...register("hscboardid", {
                required: " HSC Board is required",
              })}
              onChange={(e) => {
                setValue("hscboardid", e.target.value);
                trigger("hscboardid");
              }}
              value={watch("hscboardid") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {board?.map((val, i) => (
                <MenuItem value={val?.boardid} key={i}>
                  {val?.board}
                </MenuItem>
              ))}
            </Select>
            {errors.hscboardid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.hscboardid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.noncreamylayercertificate}
            >
              <InputLabel
                shrink={
                  !!watch("noncreamylayercertificate") ||
                  watch("noncreamylayercertificate") === 0
                }
                htmlFor="noncreamylayercertificate"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                  )?.fieldlabel
                }{" "}
                {currentYear}?
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="noncreamylayercertificate"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                )?.ismandatory === "1" ?  "Select is required" : false}}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="noncreamylayercertificate"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.noncreamylayercertificate && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.noncreamylayercertificate.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}



{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter Non Creamy Layer Application Number"
              {...register("noncreamylayercertificateappno", {
                required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
                )?.ismandatory === "1" ? "Caste name is required" : false,
              })}
              // onInput={AlphabetsValidation}
              error={!!errors.noncreamylayercertificate}
              helperText={errors.noncreamylayercertificate?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("noncreamylayercertificate"),
              }}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO)}
            />
          </Grid>
        )}


{masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
        ) && (
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="noncreamylayercertificateappdate"
                control={control}
                rules={{
                  required:
                    ((getValues("noncreamylayercertificateappdate") !== 1 )|| masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
                    )?.ismandatory !== "1")
                      ? false
                      : "Caste valdity date is required",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
                          )?.fieldlabel
                        }
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    disabled={
                      getValues("noncreamylayercertificateappdate") == 0 ? true : false
                    }
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      
                      if (date) {
                        const formattedDate = dayjs(date)
                          .hour(18)
                          .minute(30)
                          .second(0)
                          .millisecond(0)
                          .toISOString();
                        field.onChange(formattedDate);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        error: !!errors.noncreamylayercertificateappdate,
                        helperText:
                          errors.noncreamylayercertificateappdate?.message,
                        FormHelperTextProps: {
                          style: { margin: 0 },
                        },
                        style: {
                          width: "100%",
                          borderRadius: "8px",
                        },
                      },
                      layout: {
                        sx: {
                          borderRadius: "58px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}

  

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.PERSON_WITH_DISABILITY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.isdisability}>
              <InputLabel
                shrink={!!watch("isdisability") || watch("isdisability") === 0}
                htmlFor="isdisability"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.PERSON_WITH_DISABILITY
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.PERSON_WITH_DISABILITY
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="isdisability"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PERSON_WITH_DISABILITY
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.PERSON_WITH_DISABILITY
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("isdisability", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.PERSON_WITH_DISABILITY
                  )?.ismandatory === "1" ? "Disability status is required" : false,
                })}
                onChange={handleDisability}
                value={watch("isdisability") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.PERSON_WITH_DISABILITY)}
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.isdisability && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isdisability.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.TYPE_OF_DISABILITY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.disabilitytypeid}
            >
              <InputLabel
                shrink={!!watch("disabilitytypeid")}
                htmlFor="disabilitytypeid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TYPE_OF_DISABILITY
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.TYPE_OF_DISABILITY
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select
                id="disabilitytypeid"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.TYPE_OF_DISABILITY
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TYPE_OF_DISABILITY
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("disabilitytypeid", {
                  required:
                    (getValues("isdisability") !== 1 || masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TYPE_OF_DISABILITY
                    )?.ismandatory !== "1")
                      ? false
                      : "Type of  is required",
                })}
                disabled={getValues("isdisability") == 0 ? true : false}
                onChange={(e) => {
                  setValue("disabilitytypeid", e.target.value);
                  trigger("disabilitytypeid");
                }}
                value={watch("disabilitytypeid") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {disability !== "0" &&
                  pwdType?.map((val, i) => (
                    <MenuItem value={val?.pwdtypeid} key={i}>
                      {val?.pwdtype}
                    </MenuItem>
                  ))}
              </Select>
              {errors.disabilitytypeid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.disabilitytypeid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small" error={!!errors.isscriberequired}>
            <InputLabel
              shrink={
                !!watch("isscriberequired") || watch("isscriberequired") === 0
              }
              htmlFor="isscriberequired"
            >
              Do You Require Scribe?<span className="error">*</span>
            </InputLabel>
            <Select
              id="isscriberequired"
              label="Do You Require Scribe?"
              {...register("isscriberequired", {
                required: "Require Scribe is required",
              })}
              onChange={handleScribe}
              value={watch("isscriberequired") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
            {errors.isscriberequired && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.isscriberequired.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.isextratimerequired}
          >
            <InputLabel
              shrink={!!watch("isextratimerequired")}
              htmlFor="isextratimerequired"
            >
              Do You Require Extra Time?
              {scribe !== 0 && <span className="error">*</span>}
            </InputLabel>
            <Select
              id="isextratimerequired"
              label="Do You Require Extra Time?"
              {...register("isextratimerequired", {
                required:
                  scribe !== 0 ? "Require extra time  is required" : false,
              })}
              disabled={scribe === 0 || getValues("isscribe") === 0}
              onChange={(e) => {
                setValue("isextratimerequired", e.target.value);
                trigger("isextratimerequired");
              }}
              value={watch("isextratimerequired") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
            {errors.isextratimerequired && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.isextratimerequired.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.EWS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.appliedforews}>
              <InputLabel
                shrink={
                  !!watch("appliedforews") || watch("appliedforews") === 0
                }
                htmlFor="appliedforews"
              >
                {
                  masterFields?.find(
                    (field) => field?.masterfieldlabel === masterFieldLabel.EWS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.EWS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="appliedforews"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.EWS
                )?.ismandatory === "1" ? "Select is required" : false}}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="appliedforews"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel === masterFieldLabel.EWS
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.EWS
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.EWS)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.appliedforews && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.appliedforews.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
         )} 
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.appliedforews}>
              <InputLabel
                shrink={
                  !!watch("appliedforews") || watch("appliedforews") === 0
                }
                htmlFor="appliedforews"
              >
                {
                  masterFields?.find(
                    (field) => field?.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="appliedforews"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
                )?.ismandatory === "1" ? "Select is required" : false}}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="appliedforews"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    // disabled={isFieldDisabled(masterFields, masterFieldLabel.EWS)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Available</MenuItem>
                    <MenuItem value={0}> Applied But Not Received</MenuItem>
                  </Select>
                )}
              />
              {errors.appliedforews && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.appliedforews.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
         )} 


{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.EWS_Certificate_Application_Number
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.EWS_Certificate_Application_Number
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.EWS_Certificate_Application_Number
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Enter Ews Certificate Application Number"
              {...register("ewsCertificateAppNumber", {
                required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EWS_Certificate_Application_Number
                )?.ismandatory === "1" ? "EWS application number is required" : false,
              })}
              // onInput={AlphabetsValidation}
              error={!!errors.ewsCertificateAppNumber}
              helperText={errors.ewsCertificateAppNumber?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("ewsCertificateAppNumber"),
              }}
              disabled={isFieldDisabled(masterFields, masterFieldLabel.ewsCertificateAppNumber)}
            />
          </Grid>
        )}
 {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.EWS_Certificate_Application_Date
        ) && (
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="ewscertificateapplicationdate"
                control={control}
                rules={{
                  required:
                    ((getValues("ewscertificateapplicationdate") !== 1 )|| masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.EWS_Certificate_Application_Date
                    )?.ismandatory !== "1")
                      ? false
                      : "Ews application date is required",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EWS_Certificate_Application_Date
                          )?.fieldlabel
                        }
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.EWS_Certificate_Application_Date
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    disabled={
                      getValues("ewscertificateapplicationdate") == 0 ? true : false
                    }
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => {
                      if (date) {
                        const formattedDate = dayjs(date)
                          .hour(18)
                          .minute(30)
                          .second(0)
                          .millisecond(0)
                          .toISOString();
                        field.onChange(formattedDate);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        error: !!errors.ewscertificateapplicationdate,
                        helperText:
                          errors.ewscertificateapplicationdate?.message,
                        FormHelperTextProps: {
                          style: { margin: 0 },
                        },
                        style: {
                          width: "100%",
                          borderRadius: "8px",
                        },
                      },
                      layout: {
                        sx: {
                          borderRadius: "58px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}


{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.EWS_Certificate_Issuing_District
        ) && (

<Grid item xs={12} md={6}>
            <FormControl fullWidth size="small"  error={!!errors.ewscertificateissuingdistrict}>
              <InputLabel shrink={!!watch("ewscertificateissuingdistrict") || watch("ewscertificateissuingdistrict") === 0} htmlFor="ewscertificateissuingdistrict">
                
              {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel?.EWS_Certificate_Issuing_District
                  )?.fieldlabel 
                }

{masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EWS_Certificate_Issuing_District
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
                  
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              // disabled={disabled}
                id="ismhcandidate"
                label="Are you Domiciled in the state of Maharashtra?"
                {...register("ewscertificateissuingdistrict", {
                  required:  masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel?.EWS_Certificate_Issuing_District
                      )?.ismandatory === "1" ? 'ews certificate issuing district details is required':false
                     ,
                })}
                onChange={(e) => {
                  setValue("ewscertificateissuingdistrict", e.target.value);
                  trigger("ewscertificateissuingdistrict");
                }}
                value={watch("ewscertificateissuingdistrict") ?? ""}
            
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.ewscertificateissuingdistrict && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.ewscertificateissuingdistrict.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
       
       )}
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.EWS_Certificate_Issuing_Taluka
        ) && (

<Grid item xs={12} md={6}>
            <FormControl fullWidth size="small"  error={!!errors.ewscertificateissuingtaluka}>
              <InputLabel shrink={!!watch("ewscertificateissuingtaluka") || watch("ewscertificateissuingtaluka") === 0} htmlFor="ewscertificateissuingtaluka">
                
              {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel?.EWS_Certificate_Issuing_Taluka
                  )?.fieldlabel 
                }

{masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EWS_Certificate_Issuing_Taluka
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
                  
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              // disabled={disabled}
                id="ewscertificateissuingtaluka"
                label="EWS Certificate Issuing Taluka"
                {...register("ewscertificateissuingtaluka", {
                  required:  masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel?.EWS_Certificate_Issuing_Taluka
                      )?.ismandatory === "1" ? 'ews certificate issuing taluka details is required':false
                     ,
                })}
                onChange={(e) => {
                  setValue("ewscertificateissuingtaluka", e.target.value);
                  trigger("ewscertificateissuingtaluka");
                }}
                value={watch("ewscertificateissuingtaluka") ?? ""}
            
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.ewscertificateissuingtaluka && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.ewscertificateissuingtaluka.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
       
       )}


        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.ORPHAN
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.isorphan}>
              <InputLabel
                shrink={!!watch("isorphan") || watch("isorphan") === 0}
                htmlFor="isorphan"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.ORPHAN
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.ORPHAN
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="isorphan"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.ORPHAN
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="isorphan"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.ORPHAN
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.ORPHAN
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.ORPHAN)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.isorphan && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isorphan.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.ORPHAN_TYPE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.orphantype}>
              <InputLabel
                shrink={!!watch("orphantype") || watch("orphantype") === 0}
                htmlFor="orphantype"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.ORPHAN_TYPE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.ORPHAN_TYPE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="orphantype"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.ORPHAN_TYPE
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="orphantype"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.ORPHAN_TYPE
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.ORPHAN_TYPE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.ORPHAN_TYPE)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.orphantype && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.orphantype.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

{masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_SPORT
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.issport}>
              <InputLabel
                shrink={!!watch("issport") || watch("issport") === 0}
                htmlFor="issport"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="issport"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="issport"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.IS_SPORT
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.IS_SPORT)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.issport && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.issport.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}



{masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_LEVEL
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.sportpartlevel}>
              <InputLabel
                shrink={!!watch("sportpartlevel") || watch("sportpartlevel") === 0}
                htmlFor="sportpartlevel"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="sportpartlevel"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="sportpartlevel"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.SPORT_PARTICIPATION_LEVEL)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.sportpartlevel && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sportpartlevel.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_YEAR
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.sportpartyear}>
              <InputLabel
                shrink={!!watch("sportpartyear") || watch("sportpartyear") === 0}
                htmlFor="sportpartyear"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_YEAR
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_YEAR
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="sportpartyear"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_YEAR
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="sportpartyear"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.SPORT_PARTICIPATION_YEAR
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_YEAR
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.SPORT_PARTICIPATION_YEAR)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.sportpartyear && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sportpartyear.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}


{masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_RANK
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.sportrank}>
              <InputLabel
                shrink={!!watch("requireextratime") || watch("requireextratime") === 0}
                htmlFor="requireextratime"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SPORT_RANK
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_RANK
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="requireextratime"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_RANK
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="requireextratime"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.SPORT_RANK
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.SPORT_RANK
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.SPORT_RANK)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.requireextratime && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.requireextratime.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}


        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_NAME
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.sportsname}>
              <InputLabel
                shrink={!!watch("requireextratime") || watch("requireextratime") === 0}
                htmlFor="requireextratime"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SPORT_NAME
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_NAME
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="requireextratime"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_NAME
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="requireextratime"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.SPORT_NAME
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.SPORT_NAME
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.SPORT_NAME)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.requireextratime && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.requireextratime.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_BODY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.sportsbody}>
              <InputLabel
                shrink={!!watch("sportsbody") || watch("sportsbody") === 0}
                htmlFor="sportsbody"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SPORT_BODY
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_BODY
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="sportsbody"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_BODY
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="sportsbody"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.SPORT_BODY
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.SPORT_BODY
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.SPORT_BODY)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.sportsbody && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sportsbody.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}


        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.isstudiedphyedu}>
              <InputLabel
                shrink={!!watch("isstudiedphyedu") || watch("isstudiedphyedu") === 0}
                htmlFor="isstudiedphyedu"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="isstudiedphyedu"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="isstudiedphyedu"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.IS_STUDIED_PHY_EDU
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.IS_STUDIED_PHY_EDU)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.isstudiedphyedu && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isstudiedphyedu.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}


        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.isteachexp}>
              <InputLabel
                shrink={!!watch("isteachexp") || watch("isteachexp") === 0}
                htmlFor="isteachexp"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="isteachexp"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="isteachexp"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.IS_TEACHING_EXP
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.IS_TEACHING_EXP)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.isteachexp && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isteachexp.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.isappliedeltc}>
              <InputLabel
                shrink={!!watch("isappliedeltc") || watch("isappliedeltc") === 0}
                htmlFor="isappliedeltc"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="isappliedeltc"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="isappliedeltc"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.IS_APPLIED_ELCT
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.IS_APPLIED_ELCT)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.isappliedeltc && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isappliedeltc.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.isappliedeksu}>
              <InputLabel
                shrink={!!watch("isappliedeksu") || watch("isappliedeksu") === 0}
                htmlFor="isappliedeksu"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="isappliedeksu"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="isappliedeksu"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.IS_APPLIED_KSU
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.IS_APPLIED_KSU)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.isappliedeksu && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isappliedeksu.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.isappliedeksu}>
              <InputLabel
                shrink={!!watch("isappliedspecialedu") || watch("isappliedspecialedu") === 0}
                htmlFor="isappliedspecialedu"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="isappliedspecialedu"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="isappliedspecialedu"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.IS_APPLIED_SPECIAL_EDU)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.isappliedspecialedu && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isappliedspecialedu.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}



        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.isappliedspecialedumethods}>
              <InputLabel
                shrink={!!watch("isappliedspecialedumethods") || watch("isappliedspecialedumethods") === 0}
                htmlFor="isappliedspecialedumethods"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="isappliedspecialedumethods"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="isappliedspecialedumethods"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.SPECIAL_EDU_METHODS
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.SPECIAL_EDU_METHODS)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.isappliedspecialedumethods && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isappliedspecialedumethods.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}



        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.orphantype}>
              <InputLabel
                shrink={!!watch("requirescribe") || watch("requirescribe") === 0}
                htmlFor="requirescribe"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="requirescribe"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="requirescribe"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.REQUIRE_SCRIBE
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.REQUIRE_SCRIBE)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.requirescribe && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.requirescribe.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}



{masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.orphantype}>
              <InputLabel
                shrink={!!watch("requireextratime") || watch("requireextratime") === 0}
                htmlFor="requireextratime"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="requireextratime"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) => field?.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
                )?.ismandatory === "1" ? "Select is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="requireextratime"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.REQUIRE_EXTRA_TIME
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.REQUIRE_EXTRA_TIME)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.requireextratime && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.requireextratime.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}



        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : null,
          }}
        >
          <BackButton handleBack={handleBack} />
          {/* <EditButton /> */}
          <SubmitButton
            name={buttonLoader ? "loader" : "proceed"}
            handleSubmit={handleSubmit(handleProcced)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
