import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DecryptFunction, DecryptObjectFunction, EncryptFunction } from "../../utils/cryptoFunction";
import moment from "moment-timezone";
import { MdOutlineArrowBack } from "react-icons/md";
import BackButton from "../../common/backButton";
import Cookies from "js-cookie";
import candidatesActions from "../../redux/candidates/actions";
import { Box, Container, Grid, TextField, Typography } from "@mui/material";

export default function Index() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: candidatesActions.GET_FULL_CANDIDATE_DETAILS });
    }, []);


    const personalDetails = useSelector(state => state.candidatesReducer.FullCandidateDetails);
    console.log(personalDetails);


    return (

        // <Container fluid>
        //     <div className="section_background_color" style={{ margin: "1rem auto" }}>
        //         <div className="form-container-div">
        //             <div className="table_heading_cls">Profile Info</div>
        //             <Row>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Name</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.candidatename}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">E-mail ID</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.emailid}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Mobile No</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.mobileno}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">DOB</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.dob ? moment(personalDetails[0]?.dob, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Gender</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.gender}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Father's Name</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.fathername}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Mother's Name</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.mothername}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Religion</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.religion}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Region</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.region}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Mother Tongue</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.mothertongue}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Nationality</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.nationality}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Annual Family Income</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.familyincome}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Marital Status</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.maritalstatusid === 1 ? 'Married' : 'Unmarried'}
        //                     />
        //                 </Col>
        //                 {personalDetails[0]?.spousename &&
        //                     <Col md={6} >
        //                         <label className="create_master_label">Spouse Name</label>
        //                         <input
        //                             className="form-control form-control-disabled create_master_input"
        //                             disabled
        //                             value={personalDetails[0]?.spousename}
        //                         />
        //                     </Col>
        //                 }
        //             </Row>
        //             <div className="table_heading_cls">Permanent Address</div>
        //             <Row>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Address Line1</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.paddressline1}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Address Line2</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.paddressline2}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Address Line3</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.paddressline3}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">State</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.pstate}
        //                     // value={personalDetails[0]?.pState}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">District</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.pdistrict}
        //                     // value={personalDetails[0]?.pDistrict}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Taluka</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.ptaluka}
        //                     // value={personalDetails[0]?.pTaluka}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Village</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.pvillage}
        //                     // value={personalDetails[0]?.pVillage}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Pincode</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.ppincode}
        //                     />
        //                 </Col>
        //             </Row>
        //             <div className="table_heading_cls">Address for Correspondence</div>
        //             <Row>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Address Line1</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.caddressline1}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Address Line2</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.caddressline2}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Address Line3</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.caddressline3}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">State</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.cstate}
        //                     // value={personalDetails[0]?.cState}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">District</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.cdistrict}
        //                     // value={personalDetails[0]?.cDistrict}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Taluka</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.ctaluka}
        //                     // value={personalDetails[0]?.cTaluka}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Village</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.cvillage}
        //                     // value={personalDetails[0]?.cVillage}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Pincode</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.cpincode}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Std Code</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.stdcode}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Phone number</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.phoneno}
        //                     />
        //                 </Col>
        //             </Row>
        //             <div className="table_heading_cls">Other Details</div>
        //             <Row>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Are you Domiciled in the state of Maharashtra?</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.ismhcandidate === '1' ? 'Yes' : 'No'}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Minority Details</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.isminority === '1' ? 'Yes' : 'No'}
        //                     />
        //                 </Col>
        //                 {personalDetails[0]?.isminority === '1' &&
        //                     <Col md={6} >
        //                         <label className="create_master_label">Linguistic Minority</label>
        //                         <input
        //                             className="form-control form-control-disabled create_master_input"
        //                             disabled
        //                             value={personalDetails[0]?.linguisticminority}
        //                         />
        //                     </Col>
        //                 }
        //                 {personalDetails[0]?.isminority === '1' &&
        //                     <Col md={6} >
        //                         <label className="create_master_label">Religious Minority</label>
        //                         <input
        //                             className="form-control form-control-disabled create_master_input"
        //                             disabled
        //                             value={personalDetails[0]?.religiousminority}
        //                         />
        //                     </Col>
        //                 }
        //                 <Col md={6} >
        //                     <label className="create_master_label">Are you person with disability?</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.isdisability === '1' ? 'Yes' : 'No'}
        //                     />
        //                 </Col>
        //                 {personalDetails[0]?.isdisability === '1' &&
        //                     <Col md={6} >
        //                         <label className="create_master_label">Type of  Disability?</label>
        //                         <input
        //                             className="form-control form-control-disabled create_master_input"
        //                             disabled
        //                             value={personalDetails[0]?.disabilitytype}
        //                         />
        //                     </Col>
        //                 }
        //                 <Col md={6} >
        //                     <label className="create_master_label">Do You Require Scribe?</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.isscriberequired === '1' ? 'Yes' : 'No'}
        //                     />
        //                 </Col>
        //                 {personalDetails[0]?.isscriberequired === '1' &&
        //                     <Col md={6} >
        //                         <label className="create_master_label">Do You Require Extra Time?</label>
        //                         <input
        //                             className="form-control form-control-disabled create_master_input"
        //                             disabled
        //                             value={personalDetails[0]?.isextratimerequired === '1' ? 'Yes' : 'No'}
        //                         />
        //                     </Col>
        //                 }
        //             </Row>
        //             <div className="table_heading_cls">Educational Details</div>
        //             <Row>
        //                 <Col md={6} >
        //                     <label className="create_master_label">SSC Passing Year</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.sscpassingyear}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">SSC Total percentage</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.ssctotalpercentage}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">SSC Board</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.sscboard}
        //                     // value={personalDetails[0]?.hscBoard}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">SSC School State</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.sscschoolstate}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">Are you appearing/applied for 12th HSC Diploma exam in 2024</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.appearingfor === '1' ? 'Yes' : 'No'}
        //                     />
        //                 </Col>
        //                 <Col md={6} >
        //                     <label className="create_master_label">HSC Board</label>
        //                     <input
        //                         className="form-control form-control-disabled create_master_input"
        //                         disabled
        //                         value={personalDetails[0]?.hscboard}
        //                     />
        //                 </Col>
        //             </Row>
        //         </div>
        //     </div>
        // </Container>
        <Grid>
            <Box  sx={{bgcolor:"#fff",px:3,pb:3,pt:1, borderRadius:"8px"}}>
                <Typography pb={1} mt={3} variant="h5"  >Profile Info</Typography>
                <Grid container spacing={3}>
                    <Grid item md={6} sm={12}>
                        <TextField
                            sx={{}}
                            label="Name"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.candidatename || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="E-mail ID"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.emailid || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Mobile No"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.mobileno || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="DOB"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.dob ? moment(personalDetails.dob, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Gender"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.gender || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Father's Name"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.fathername || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Mother's Name"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.mothername || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Religion"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.religion || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Region"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.region || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Mother Tongue"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.mothertongue || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Nationality"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.nationality || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Annual Family Income"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.familyincome || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Marital Status"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.maritalstatusid === 1 ? 'Married' : 'Unmarried'}
                            size="small"
                        />
                    </Grid>
                    {personalDetails?.spousename && (
                        <Grid item md={6} sm={12}>
                            <TextField
                                label="Spouse Name"
                                variant="outlined"
                                fullWidth
                                disabled
                                value={personalDetails?.spousename || ''}
                                size="small"
                            />
                        </Grid>
                    )}
                </Grid>
                <Typography pb={1} mt={3} variant="h5"  >Permanent Address</Typography>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Address Line1"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.paddressline1 || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Address Line2"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.paddressline2 || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Address Line3"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.paddressline3 || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="State"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.pstate || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="District"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.pdistrict || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Taluka"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.ptaluka || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Village"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.pvillage || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Pincode"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.ppincode || ''}
                            size="small"
                        />
                    </Grid>
                </Grid>
                <Typography pb={1} mt={3} variant="h5" >Address for Correspondence</Typography>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Address Line1"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.caddressline1 || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Address Line2"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.caddressline2 || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Address Line3"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.caddressline3 || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="State"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.cstate || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="District"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.cdistrict || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Taluka"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.ctaluka || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Village"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.cvillage || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Pincode"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.cpincode || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Std Code"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.stdcode || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                            label="Phone number"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.phoneno || ''}
                            size="small"
                        />
                    </Grid>
                </Grid>
                <Typography pb={1} mt={3} variant="h5" >Other Details</Typography>
                <Grid container spacing={3}>
                    <Grid item md={6} sm={12}>
                        
                        <TextField
                            label="Are you Domiciled in the state of Maharashtra?"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.ismhcandidate === '1' ? 'Yes' : 'No'}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        
                        <TextField
                            label="Minority Details"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.minoritydetails || ''}
                            size="small"
                        />
                    </Grid>


                    {personalDetails?.isminority === '1' &&
                        <Grid item md={6} >
                            <TextField
                                label="Linguistic Minority"
                                variant="outlined"
                                fullWidth
                                disabled
                                value={personalDetails?.linguisticminority}
                                size="small"
                            />
                        </Grid>
                    }
                    {personalDetails?.isminority === '1' &&
                        <Grid item md={6} >
                            
                            <TextField
                                label="Religious Minority"
                                variant="outlined"
                                fullWidth
                                disabled
                                size="small"
                                value={personalDetails?.religiousminority}
                            />
                        </Grid>
                    }

                    <Grid item md={6} >
                        <TextField
                            label="Are you person with disability?"
                            variant="outlined"
                            fullWidth
                            disabled
                            size="small"
                            value={personalDetails?.isdisability === '1' ? 'Yes' : 'No'}
                        />
                    </Grid>
                    {/* {personalDetails?.isdisability === '1' &&
                            <Grid item md={6} >
                                <label className="create_master_label">Type of  Disability?</label>
                                <TextField
                                   label="Type of  Disability?"
                                 variant="outlined"
                                 fullWidth
                                    size="small"
                                    value={personalDetails?.disabilitytype}
                                />
                            </Grid>
                        } */}
                    <Grid item md={6} >
                        
                        <TextField
                            variant="outlined"
                            label="Do You Require Scribe?"
                            size="small"
                            disabled
                            fullWidth
                            value={personalDetails?.isscriberequired === '1' ? 'Yes' : 'No'}
                        />
                    </Grid>
                    {personalDetails?.isscriberequired === '1' &&
                        <Grid item md={6} >
                            <TextField
                                variant="outlined"
                                label="Do You Require Extra Time?"
                                size="small"
                                disabled
                                fullWidth
                                value={personalDetails?.isextratimerequired === '1' ? 'Yes' : 'No'}
                            />
                        </Grid>
                    }

                </Grid>
                <Typography pb={1} mt={3} variant="h5">Educational Details</Typography>
                <Grid container spacing={2}>
                    <Grid item md={6} >
                        
                        <TextField
                            label="SSC Passing Year"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.sscpassingyear || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} >
                        <TextField
                            label="SSC Total Percentage"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.ssctotalpercentage || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} >
                        <TextField
                            label="SSC Board"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.sscboard || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} >
                        <TextField
                            label="SSC School State"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.sscschoolstate || ''}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} >
                        <TextField
                            label="Are you appearing/applied for 12th HSC Diploma exam in 2024"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={personalDetails?.appearingfor === '1' ? 'Yes' : 'No'}
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} >
                        <TextField
                            label="HSC Board"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled
                            value={personalDetails?.hscboard || ''}
                        />
                    </Grid>
                </Grid>
            </Box>

        </Grid>
    )
};