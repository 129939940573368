import React from "react";
import Index from "../components/candidateDetails";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";

export default function CandidateDetails() {

    return (
        <Layout>
            <Header/>
            <Sidebar/>
            <Index />
        </Layout>
    )
}