import React from "react";
import Layout from "../layouts/layout";
import Index from "../components/raiseQuery";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";
import { cssProperties } from "../utils/commonCssProperties";
import { Link, Typography } from "@mui/material";
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowDown } from "react-icons/md";
import PageHeading from "../layouts/pageHeading";
import Cookies from "js-cookie";

export default function RaiseQuery() {
    const breadcrumbs = [
        <Link underline="hover" key="1" component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/home`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            textDecoration: "none"
        }}>
            Dashboard
        </Link>,
           <Link underline="hover" key="2" component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/query-list`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            textDecoration : "none"
        }}>
            Your Query Status
        </Link>,
        <Typography key="2" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: "bold",
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            Raise a Query <MdKeyboardArrowDown size={16} />
        </Typography>,
    ];
    return (
        <>
            <Header breadcrumbs={breadcrumbs} />
            <Sidebar />
            <Layout>
                <PageHeading page="Raise a Query" />
                <Index />
            </Layout>
        </>
    )
}