import React, { useState } from "react";
import Index from "../components/login";
import BeforeLoginHeader from "../layouts/beforeLoginHeader";
import SupportModal from "../components/modals/supportModal";
import HelpManualVideoModal from "../components/modals/helpManualVideoModal";
import BeforeLoginLayout from "../layouts/beforeLoginLayout";
import BeforeLoginFooter from "../layouts/beforeLoginFooter";

export default function Login() {
  const [showModal, setShowModal] = useState({ show: false, type: null });

  return (
    <>
      <BeforeLoginLayout>
        <BeforeLoginHeader />
        <Index />
      </BeforeLoginLayout>
      <BeforeLoginFooter />

      <SupportModal
        showModal={showModal?.type === "services" && showModal?.show}
        handleClose={() => setShowModal({ show: false, type: null })}
      />
      <HelpManualVideoModal
        showModal={showModal?.type === "video" && showModal?.show}
        handleClose={() => setShowModal({ show: false, type: null })}
      />
    </>
  );
}
