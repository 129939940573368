import React from "react";
import DataTable from "../../common/dataTable";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import TablePagination from "../../common/tablePagination";

export default function AnswerKeysTable() {
  const navigate = useNavigate();

  const answerKeysHeaders = [
    { name: "SI.No", accessor: "slNo" },
    { name: "Course Name", accessor: "courseName" },
    { name: "Exam Date", accessor: "examDate" },
    { name: "Current Status", accessor: "currentStatus" },
    { name: "Answer Key", accessor: "answerKey" },
    { name: "Raise Objection", accessor: "raiseObjection" },
  ];

  const answerKeysData = [
    {
      slNo: 1,
      courseName: "Mathematics",
      examDate: "2023-06-15",
      currentStatus: "Published",
      answerKey: "View Answer Keys",
      raiseObjection: "Raise Objection",
    },
    {
      slNo: 2,
      courseName: "Physics",
      examDate: "2023-06-20",
      currentStatus: "Yet to be published",
      answerKey: "View Answer Keys",
      raiseObjection: "Raise Objection",
    },
    {
      slNo: 3,
      courseName: "Chemistry",
      examDate: "2023-06-25",
      currentStatus: "Published",
      answerKey: "View Answer Keys",
      raiseObjection: "Raise Objection",
    },
  ];

  const handleaAnsKeys = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/answer-key?page=answerlist`);
  };

  const handleObjection = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/answer-key?page=objectiontracker`);
  };

  return (
    <>
      {/* <Typography>View answer keys for the question that you wrote</Typography> */}
      <DataTable
        keys={answerKeysHeaders}
        values={answerKeysData}
        page={"answerKeysList"}
        handleaAnsKeys={handleaAnsKeys}
        handleObjection={handleObjection}
      />
      <TablePagination />
    </>
  );
}
