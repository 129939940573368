import React from "react";
import BeforeLoginLayout from "../layouts/beforeLoginLayout";
import BeforeLoginHeader from "../layouts/beforeLoginHeader";
import Index from "../components/resetPassword";
import BeforeLoginFooter from "../layouts/beforeLoginFooter";

export default function ResetPassword() {
  return (
    <>
      <BeforeLoginLayout>
        <BeforeLoginHeader />
        <Index />
      </BeforeLoginLayout>
      <BeforeLoginFooter />
    </>
  );
}
