import React from "react";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";
import Layout from "../layouts/layout";
import Index from "../components/answerKeys";
import { Link, Typography } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";
import PageHeading from "../layouts/pageHeading";

export default function AnswerKey() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const navigate = useNavigate();

  const handleClick = () => {
    if (
      page === "answerlist" ||
      page === "objectiontracker" ||
      page === "raisedobjection"
    ) {
      navigate(`/${Cookies.get("mhet_cnd_project")}/answer-key?page=tablelist`);
    }
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      component={NavLink}
      to={`/${Cookies.get("mhet_cnd_project")}/home`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        textDecoration: "none",
      }}
    >
      Dashboard
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: page !== "tablelist" ? 400 : "bold",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        cursor: page !== "tablelist" ? "pointer" : "none",
        "&:hover": {
          textDecoration: page !== "tablelist" ? "underline" : "none",
        },
      }}
      onClick={handleClick}
    >
      Answer Key {page === "tablelist" && <MdKeyboardArrowDown size={16} />}
    </Typography>,
  ];

  if (page === "answerlist") {
    breadcrumbs.push(
      <Typography
        key="3"
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          color: cssProperties?.fontcolor?.primary,
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "20px",
          letterSpacing: "0.1px",
        }}
      >
        Answer Key List
        <MdKeyboardArrowDown size={16} />
      </Typography>
    );
  } else if (page === "objectiontracker") {
    breadcrumbs.push(
      <Typography
        key="3"
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          color: cssProperties?.fontcolor?.primary,
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "20px",
          letterSpacing: "0.1px",
        }}
      >
        Objection Tracker
        <MdKeyboardArrowDown size={16} />
      </Typography>
    );
  } else if (page === "raisedobjection") {
    breadcrumbs.push(
        <Typography
        key="3"
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          color: cssProperties?.fontcolor?.primary,
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          letterSpacing: "0.1px",
          cursor : "pointer",
          "&:hover" : {
            textDecoration:"underline"
          }
        }}
        onClick={()=>navigate(`/${Cookies.get('mhet_cnd_project')}/answer-key?page=objectiontracker`)}
      >
        Objection Tracker
      </Typography>,
      <Typography
        key="3"
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          color: cssProperties?.fontcolor?.primary,
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "20px",
          letterSpacing: "0.1px",
        }}
      >
        Raised Objection
        <MdKeyboardArrowDown size={16} />
      </Typography>
    );
  }
  return (
    <>
      <Layout>
        <Header breadcrumbs={breadcrumbs} />
        <Sidebar />
        <PageHeading page={page === "tablelist" || page === "answerlist" ? "Answer Key" : "Objection Tracker"} />
        <Index />
      </Layout>
    </>
  );
}
