import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { cssProperties } from "../utils/commonCssProperties";
import youtubeLogo from "../assets/images/footer/youtube.png";
import facebookLogo from "../assets/images/footer/facebook.png";

export default function BeforeLoginFooter() {
  return (
    <>
      <Grid
        sx={{
          minHeight: "181px",
          background: `${cssProperties?.backgroundcolor?.footer}`,
          padding: "10px 50px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              color: `${cssProperties?.color?.primary}`,
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            Office Address
          </Typography>
          <Typography
            sx={{
              color: `${cssProperties?.color?.tertiary}`,
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            8th Floor, New Excelsior Building, A. K. Nayak Marg, Fort, Mumbai
            400001
          </Typography>
        </Grid>
        <Divider sx={{ padding: "10px 0px" }} />
        <Grid sx={{display:"flex",justifyContent:"space-between"}} pt={4} pb={4}>
          <Typography
            sx={{
              color: `${cssProperties?.color?.tertiary}`,
              fontSize: "12px",
              fontWeight: 400,
            }}
          >
            Copyright © State CET Cell,Maharashtra State, Mumbai. All rights
            reserved.(W2), 2025.
          </Typography>
          <Grid sx={{display:"flex",gap:"24px",alignItems:"center"}}>
            <Grid>
              <Typography
                sx={{
                  color: `${cssProperties?.color?.tertiary}`,
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Follow us on:
              </Typography>
            </Grid>
            <Grid sx={{display:"flex",gap:"16px",justifyContent:"center",gap:"8px"}}>
              <Box src={youtubeLogo} sx={{height:"25px",width:"auto",cursor:"pointer"}} component="img" alt="youtube" onClick={() => window.open("https://www.youtube.com/channel/UCxG62HhJYPs84j1hHxTd_hA", "_blank")} />
              <Box src={facebookLogo} sx={{height:"20px",width:"auto",cursor:"pointer"}} component="img" alt="facebook" onClick={ () => window.open("https://www.facebook.com/statecetcell", "_blank") }/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
