// export const API_URL = 'http://18.143.159.223:5000'; //old url
// export const API_URL = 'http://54.169.144.238:3001'; //TEST url

// export const API_URL = "http://34.93.35.227:3001"; //UAT url
// export const FRONTEND_URL = 'http://34.93.35.227:8080';
// export const BILLDESK_URL = 'http://34.93.35.227:3001';

// export const API_URL = 'http://localhost:3001';
// export const FRONTEND_URL  = 'http://localhost:3000';
// export const BILLDESK_URL = 'http://35.207.221.237/api';

// new UAT
// export const API_URL = "http://35.207.221.237/api";
// export const FRONTEND_URL = "http://35.207.221.237";
// export const BILLDESK_URL = "http://35.207.221.237/api";

// Bill desk test
// export const API_URL = 'http://34.93.35.227:8087';
// export const FRONTEND_URL  = 'http://localhost:3000';
// export const BILLDESK_URL = 'http://34.93.35.227:8087';

// V3 
export const API_URL = 'https://mhcet-reg-uat.hubblehox.ai/api';
export const FRONTEND_URL  = 'https://mhcet-reg-uat.hubblehox.ai';
export const BILLDESK_URL = 'http://35.207.221.237/api';
