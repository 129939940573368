import { Box, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import DataTable from "../../common/dataTable";
import PrintDownloadButton from "../../common/button/printDownloadButton";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const paymentReceiptList = useSelector(
    (state) => state.candidatesReducer?.paymentReceiptList
  );


  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: "GET_PAYMENT_RECEIPT_LIST",
        payload: { candidateid: candidateid },
      });
    }
  }, [candidateid]);
  const handleViewReceipt = (id) => {
    navigate(
      `/${Cookies.get("mhet_cnd_project")}/payment-receipt-view/${id}`,
      {}
    );
  };
  const columnData = [
    {
      name: "S No",
      accessor: "s_no",
    },
    {
      name: "Payment For",
      accessor: "payment_for",
    },
    {
      name: "Amount",
      accessor: "amount",
    },
    {
      name: "Paid On",
      accessor: "paid_on",
    },
    {
      name: "View Receipt",
      accessor: "view_receipt",
    },
  ];
  let tableData = [
    {
      s_no: 1,
      payment_for: "Registration",
      amount: 1000,
      paid_on: "12/04/2024",
      view_receipt: (
        <p
          style={{
            cursor: "pointer",
            textDecoration: "none",
            color: "rgb(241 91 80)",
          }}
          onClick={() => handleViewReceipt(1)}
        >
          View
        </p>
      ),
    },
    {
      s_no: 2,
      payment_for: "Admission",
      amount: 1000,
      paid_on: "12/04/2024",
      view_receipt: (
        <p
          style={{
            cursor: "pointer",
            textDecoration: "none",
            color: "rgb(241 91 80)",
          }}
          onClick={() => handleViewReceipt(2)}
        >
          View
        </p>
      ),
    },
  ];
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const formattedData = (originalData) => {
    return originalData.map((item, index) => ({
      s_no: index + 1, // Serial number (1-based index)
      payment_for: "Registration", // Static value or you can modify based on your logic
      amount: parseFloat(item.amount), // Convert amount to number
      paid_on: formatDate(item.orderdate), // Format date
      view_receipt: (
        <p
          style={{
            cursor: "pointer",
            textDecoration: "none",
            color: "rgb(241 91 80)",
          }}
          onClick={() => handleViewReceipt(parseInt(item.transactionsid))}
        >
          View
        </p>
      ),
    }))
  }


  return (
    <Box>
      {/* <Typography pb={1} variant="h5"  >Receipts</Typography> */}
      <Box paddingTop={2}>
        {/* <Typography pb={1} variant="h6"  >Your Receipts</Typography> */}
        <DataTable
          keys={columnData}
          values={paymentReceiptList ? formattedData(paymentReceiptList) : []}
        />
      </Box>
    </Box>
  );
};

export default Index;
