import React from "react";
import Index from "../components/registration/index";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";
import PageHeading from "../layouts/pageHeading";
import { cssProperties } from "../utils/commonCssProperties";
import { Typography,Link } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import Cookies from "js-cookie";

export default function Registration() {
  const breadcrumbs = [
    <Link underline="hover" key="1" component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/home`} sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.1px',
    }}>
        Dashboard
    </Link>,
    <Typography key="3" color="text.primary"
        sx={{
            display: 'flex', alignItems: 'center', gap: '12px',
            fontSize: '14px',
            fontWeight: "bold",
            color:'#212121',
            lineHeight: '20px',
            letterSpacing: '0.1px'
        }}
        >
            Profile<MdKeyboardArrowDown size={16} />
    </Typography>,
];
  return (
    
    <>
      <Layout>
        <Header breadcrumbs={breadcrumbs} />
        <Sidebar />
        {/* <PageHeading/> */}
        <Index />
      </Layout>
    </>
  );
}
