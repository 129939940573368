import React from "react";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";
import Index from "../components/instructionPage";
import { Link, NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { cssProperties } from "../utils/commonCssProperties";
import { Typography } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";

export default function Instruction() {


    
    const breadcrumbs = [
        <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/home`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
    
        }}>
            Dashboard
        </Link>,
    
        <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/course-selection`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
    
        }}>
            Course selection
        </Link>,
        <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/course-registration`} sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            color:cssProperties?.fontcolor?.primary
        }}>
            Course registration
        </Link>,
    
        <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/confirm-payment`} sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            color:cssProperties?.fontcolor?.primary
        }}>
           Payment confirmation 
        </Link>,
    
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: "bold",
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            Instructions<MdKeyboardArrowDown size={16} />
        </Typography>
    ];

    return (
        <>
            <Layout>
                <Header breadcrumbs={breadcrumbs}/>
                <Sidebar />
                <Index />
            </Layout>
        </>
    )
}