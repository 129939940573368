import React, { useEffect } from "react";
import Cookies from 'js-cookie';
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../layouts/layout";
import { Typography } from "@mui/material";

export default function RouteVerification() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const project = searchParams.get('project');

    useEffect(() => {
        if (project) {
            if (project === 'mhcet') {
                navigate('/mhcet/login');
                window.location.reload();
                Cookies.set('mhet_cnd_project', project, { expires: 7 });
            } else if (project === 'test') {
                navigate('/test/login');
                window.location.reload();
                Cookies.set('mhet_cnd_project', project, { expires: 7 });
            } else {
                navigate('/404');
            };
        } else {
            navigate('/404');
        };
    }, [project]);

    return (
        <Layout>
            <Typography variant="h4">Loading...</Typography>
        </Layout>
    )
}