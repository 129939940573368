import React, { useState } from "react";
import Index from "../components/forgetPassword";
import BeforeLoginHeader from "../layouts/beforeLoginHeader";
import SupportModal from "../components/modals/supportModal";
import { Typography } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
import { MdKeyboardArrowRight } from "react-icons/md";
import HelpManualVideoModal from "../components/modals/helpManualVideoModal";

export const ForgetPassword = () => {

  const [showModal, setShowModal] = useState({ show: false, type: null });

  const breadcrumbs = [
    <Typography key="1" color="text.primary"
      sx={{
        display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
        fontSize: '14px',
        fontWeight: "bold",
        lineHeight: '20px',
        letterSpacing: '0.1px'
      }}
    >
      Forgot Password <MdKeyboardArrowRight size={16} />
    </Typography>
  ];

  return (
    <>
      <BeforeLoginHeader handleShow={(type) => setShowModal({ show: true, type: type })}/>
      <Index />
      <SupportModal showModal={showModal?.type === 'services' && showModal?.show} handleClose={() => setShowModal({ show: false, type: null })} />
      <HelpManualVideoModal showModal={showModal?.type === 'video' && showModal?.show} handleClose={() => setShowModal({ show: false, type: null })} />
    </>
  );
};