import React from "react";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import CancelButton from '../../common/button/cancelButton'
import { PiX } from "react-icons/pi";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: '24px',
    borderRadius: '8px'
};

export default function SupportModal(props) {

    const { handleClose, showModal } = props;

    return (
        <Modal
            open={showModal}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box display="flex" alignItems="center" marginBottom='24px'>
                    <Box flexGrow={1} ><Typography variant="h5" sx={{ color: `#212121`, fontSize: '20px', fontWeight: 'bold', }}>CONTACT US</Typography></Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <PiX />
                        </IconButton>
                    </Box>
                </Box>
                <Grid container>
                    <Grid item>
                        <Typography sx={{
                            fontWeight: 400, fontSize: "14px", marginBottom: 2, lineHeight: "24px", color: "#616161"
                        }}>For the candidates, who are facing any issues in registration and are unable to create their profile, please contact the below helpdesk numbers:</Typography>

                    </Grid>
                    <Grid

                        style={{

                            fontSize: "16px",
                            fontWeight: "bold",
                            lineHeight: "24px",
                            textAlign: "left",
                            textDecoration: "underline",
                            color: "#616161"

                        }}>
                        <li
                            className="list-mob"
                            style={{ marginBottom: 1 }}
                        >07969134401</li>
                        <li className="list-mob" style={{ marginBottom: 1 }}>07969134402</li>
                        <li className="list-mob" style={{ marginBottom: 1 }}>18002090191</li>
                    </Grid>
                </Grid>
                {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 500 }}>Mobile number - <span style={{ color: `${cssProperties?.color?.primary}`, textDecoration: 'underline' }}>+919876543210</span></Typography>
                    <Typography sx={{ fontWeight: 500 }}>Email ID - <span style={{ color: `${cssProperties?.color?.primary}`, textDecoration: 'underline' }}>helpdesk@gmail.com</span></Typography>
                </Box> */}
                {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginTop: '32px' }}>
                    <CancelButton handleBack={handleClose} title={'Close'} />
                </Box> */}
            </Box>
        </Modal >
    )
};