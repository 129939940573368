/* --- MHCET V3 --- */
import React from "react";
import { Button } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";

export default function PrimaryButton({ text, disabled, handleClick, type, onFocus }) {
  return (
    <Button
      variant="contained"
      type={type}
      sx={{
        width: "100%",
        boxShadow: "none",
        fontWeight:"bold",
        backgroundColor: `${cssProperties?.backgroundcolor?.primary}`,
        textTransform: "none",
        "&:hover" : {
          backgroundColor: `${cssProperties?.backgroundcolor?.primary}`
        }
      }}
      disabled={disabled}
      onClick={text !== "loader" && handleClick}
      disableElevation
      onFocus={onFocus}
    >
      {text}
    </Button>
  );
}
