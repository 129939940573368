import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { cssProperties } from "../utils/commonCssProperties";

export default function PageLoader() {

    const { pageLoader } = useSelector(state => state.commonReducer);

    return (
        <>
            {pageLoader &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    backgroundColor: '#21212173',
                    // backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                    zIndex: 9999,
                }}>
                    <CircularProgress sx={{ color: `${cssProperties?.color?.primary}` }} size={70} thickness={4.5} />
                </Box>
            }
        </>
    )
}