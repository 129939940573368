import commonReducer from "./common/reducer";
import candidatesReducer from "./candidates/reducer";
import masterReducer from "./master/reducer";
import authReducer from "./auth/reducer";

const reducers = {
    commonReducer,
    candidatesReducer,
    masterReducer,
    authReducer
};

export default reducers;