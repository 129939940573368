import React, { useState, useEffect } from "react";
import Tabs from "./tabs";
import PersonalDetailsForm from "./personalDetailsForm";
import AddressForm from "./addressForm";
import OtherDetailsForm from "./otherDetailsForm";
import UploadsForm from "./uploadsForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import authActions from "../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { RiFileUserLine } from "react-icons/ri";
import { IoIosHome } from "react-icons/io";
import { PiGraduationCap } from "react-icons/pi";
import { IoDocumentTextOutline } from "react-icons/io5";

import { cssProperties } from "../../utils/commonCssProperties";



function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [searchParams] = useSearchParams();
  const Page = searchParams.get("page");
  const [registrationSection, setRegistrationSection] =
    useState("personaldetails");

  useEffect(() => {
    const currentActiveTab =
      Page === "personaldetails"
        ? 0
        : Page === "address"
        ? 1
        : Page === "otherdetails"
        ? 2
        : Page === "uploads"
        ? 3
        : null;

    if (currentActiveTab !== null) {
      setActiveTab(currentActiveTab);
    }
    setRegistrationSection(Page);
  }, [Page]);

  const { candidateTokenDetails, profileRegistrationActiveTab } = useSelector(
    (state) => state.authReducer
  );

  useEffect(() => {
    if (candidateTokenDetails?.candidateid) {
      dispatch({
        type: authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB,
        payload: { id: candidateTokenDetails?.candidateid },
      });
    }
  }, [candidateTokenDetails]);

  const RegistrationData = [
    { title: "Personal Details",icon: <span style={cssProperties.spanstyle}><RiFileUserLine style={cssProperties.iconstyle} /></span>, to: ((profileRegistrationActiveTab && profileRegistrationActiveTab?.candidate_status === 2) && `/${Cookies.get('mhet_cnd_project')}/registration?page=personaldetails`) },
    { title: "Address",icon: <span style={cssProperties.spanstyle}><IoIosHome style={cssProperties.iconstyle} /></span>, to: ((profileRegistrationActiveTab && profileRegistrationActiveTab?.candidate_status === 2) && `/${Cookies.get('mhet_cnd_project')}/registration?page=address`) },
    { title: "Other Details",icon: <span style={cssProperties.spanstyle}><PiGraduationCap style={cssProperties.iconstyle} /></span>, to: ((profileRegistrationActiveTab && profileRegistrationActiveTab?.candidate_status === 2) && `/${Cookies.get('mhet_cnd_project')}/registration?page=otherdetails`) },
    { title: "Uploads", icon: <span style={cssProperties.spanstyle}><IoDocumentTextOutline style={cssProperties.iconstyle} /></span>,to: ((profileRegistrationActiveTab && profileRegistrationActiveTab?.candidate_status === 2) && `/${Cookies.get('mhet_cnd_project')}/registration?page=uploads`) },
  ];

  useEffect(() => {
    if (!profileRegistrationActiveTab) {
      return;
    } else if (
      profileRegistrationActiveTab &&
      profileRegistrationActiveTab === "new"
    ) {
      navigate(
        `/${Cookies.get("mhet_cnd_project")}/registration?page=personaldetails`
      );
    } else {
      if (
        !profileRegistrationActiveTab?.candidate_status &&
        profileRegistrationActiveTab?.other_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=uploads`
        );
      } else if (
        !profileRegistrationActiveTab?.candidate_status &&
        !profileRegistrationActiveTab?.other_status &&
        profileRegistrationActiveTab?.address_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=otherdetails`
        );
      } else if (
        !profileRegistrationActiveTab?.candidate_status &&
        !profileRegistrationActiveTab?.other_status &&
        !profileRegistrationActiveTab?.address_status &&
        profileRegistrationActiveTab?.candidate_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=address`
        );
      } else if (
        !profileRegistrationActiveTab?.candidate_status &&
        !profileRegistrationActiveTab?.other_status &&
        !profileRegistrationActiveTab?.address_status &&
        !profileRegistrationActiveTab?.candidate_status
      ) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/registration?page=personaldetails`
        );
      }
    }
  }, [profileRegistrationActiveTab]);

  const preprocessFormData = (data) => {
    const processedData = { ...data };
    for (const key in processedData) {
      if (processedData.hasOwnProperty(key)) {
        if (
          typeof processedData[key] === "string" &&
          (processedData[key].trim() === "" ||
            processedData[key].toLowerCase() === "null")
        ) {
          processedData[key] = null;
        }
      }
    }
    return processedData;
  };

  return (
    <>
      <Tabs
        activeTab={activeTab}
        RegistrationData={RegistrationData}
        profileRegistrationActiveTab={profileRegistrationActiveTab}
      />
      {registrationSection === "personaldetails" && (
        <PersonalDetailsForm preprocessFormData={preprocessFormData} />
      )}
      {registrationSection === "address" && (
        <AddressForm preprocessFormData={preprocessFormData} />
      )}
      {registrationSection === "otherdetails" && (
        <OtherDetailsForm preprocessFormData={preprocessFormData} />
      )}
      {registrationSection === "uploads" && (
        <UploadsForm preprocessFormData={preprocessFormData} />
      )}
    </>
  );
}

export default Index;
