import React from "react";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Tabs(props) {
  const navigate = useNavigate();
  const { activeTab, RegistrationData, profileRegistrationActiveTab } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledStepLabel = styled(StepLabel)(({ theme, active }) => ({
    "&.MuiStepLabel-root": {
      cursor: profileRegistrationActiveTab?.other_status === 2 ? "pointer" : "none",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      background: active ? '#0F4F96' : 'transparent',
      color: active ? '#0F4F96' : '#0F4F96',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    "& .MuiStepLabel-iconContainer": {
      marginRight: theme.spacing(1),
    },
  }));
console.log('profileRegistrationActiveTab--',profileRegistrationActiveTab);
  return (
    <Grid
      sx={{
        height: "auto",
        backgroundColor: "#0F4F9633",
        margin: "10px 0px",
        padding: "15px 15px",
        borderRadius: "8px",
      }}
    >
      <Stepper
        activeStep={activeTab}
        orientation={isMobile ? "vertical" : "horizontal"}
    >
        {RegistrationData.map((item, index) => (
          <Step key={index}>
          {console.log(item)}

            <StyledStepLabel
              sx={{ cursor: "pointer",color:'#212121' }}
              onClick={() => (item.to ? navigate(item.to) : null)}
              icon={item.icon} 
            >
              {item.title}
            </StyledStepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
}
