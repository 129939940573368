import React from "react";
import { Button } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";

export default function CancelButton({ handleBack, title }) {

    return (
        <Button
            type='button'
            disableElevation
            variant="contained"
            sx={{
                borderRadius: '40px',
                padding: '0px 24px',
                height: '40px',
                backgroundColor: `${cssProperties?.hoverbgcolor?.sidebar}`,
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '15.4px',
                letterSpacing: '0.32px',
                boxShadow: 'none',
                color: `${cssProperties?.color?.primary}`,
                border: `0.5px solid ${cssProperties?.color?.primary}`,
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                '&:hover': {
                    backgroundColor: `${cssProperties?.hoverbgcolor?.sidebar}`,
                    border: `0.5px solid ${cssProperties?.color?.primary}`,
                    boxShadow: 'none',
                    color: `${cssProperties?.color?.primary}`,
                }
            }}
            onClick={handleBack}
        >
            {title ? title : "Cancel"}
        </Button>
    )
}