import React from "react";
import AnswerKeysTable from "./answerKeysTable";
import { useSearchParams } from "react-router-dom";
import QuestionListTable from "./answerListTable";
import ObjectionTracker from "./objectionTracker";
import RaisedObjection from "./raisedObjection";

export default function Index() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  return (
    <>
      {page === "tablelist" && <AnswerKeysTable />}
      {page === "answerlist" && <QuestionListTable />}
      {page === "objectiontracker" && <ObjectionTracker />}
      {page === "raisedobjection" && <RaisedObjection />}
    </>
  );
}
