import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import authActions from '../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { PiCaretLeftBold } from 'react-icons/pi';
import Cookies from 'js-cookie';
import ButtonLoader from '../../common/buttonLoader';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { AiOutlineLeft } from "react-icons/ai";
import PrimaryButton from '../../common/primaryButton';
import { cssProperties } from '../../utils/commonCssProperties';
import PageHeading from '../../layouts/pageHeading';
import { InputTrimAndLowercaseFunction, handleEmailValidation } from '../../utils/validations';
import BluePrimaryButton from '../../common/button/bluePrimaryButton';
import EnterMobilenoModal from './enterMobilenoModal';
import { MdOutlineCheckCircleOutline } from 'react-icons/md';
import BackgroundImage from '../../assets/images/backgroundImage.png';


export default function Index() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { buttonLoader } = useSelector(state => state.commonReducer);

  const { forgotPass_MobileVerification } = useSelector(state => state.authReducer);

  const [showModal, setShowModal] = useState(false);

  const onSubmit = async (data) => {

    dispatch({
      type: authActions.CANDIDATE_FORGETPASSWORD,
      payload: { navigate: navigate, data: data },
    });
    dispatch({
      type: authActions.FORGOTPASSWORD_MOBILEVERIFICATION,
      payload: { validated: false }
    });

  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: '26px'
      }}
    >


      <Grid container sx={{ display: 'flex', justifyContent: 'center' }} spacing={2}>
        <Grid item md={6} sm={11}

        > <Box
          sx={{
            borderRadius: "8px",
            // padding: '16px',
            backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
            height: '100%',
            overflow: 'hidden'
          }}
        >
            <img style={{ width: '100%', height: 'inherit', objectFit: 'cover' }} src={BackgroundImage} alt="background" />
          </Box></Grid>
        <Grid item md={6} sm={11}

        >
          <Box
            sx={{
              borderRadius: "8px",
              padding: '24px',
              backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,

              height: '100%'
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
              <Box sx={{ marginBottom: '8px', display: "flex", justifyContent: "flex-start", alignItems: "center", color: `${cssProperties?.color?.primary}`, cursor: 'pointer' }} onClick={() => navigate(`/${Cookies.get('mhet_cnd_project')}/login`)}>
                <AiOutlineLeft size={14} />
              </Box>
            </Box>
            <Box sx={{ marginBottom: '8px', textAlign: 'center' }}>
              <PageHeading align="center" page={'Forgot Password'} />
            </Box>
            {forgotPass_MobileVerification?.validated &&
              <Box sx={{ backgroundColor: 'rgba(34, 160, 107, 0.1)', border: '1px solid rgba(34, 160, 107, 1)', color: 'rgba(34, 160, 107, 1)', borderRadius: '8px', margin: '24px 0', padding: '12px' }}>
                <Typography variant="body2" sx={{ display: 'flex', gap: '6px' }}><MdOutlineCheckCircleOutline size={16} /> Email id sent to your mobile number. Enter the received emailid to reset your password</Typography>
              </Box>
            }
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <TextField
                  fullWidth
                  variant='outlined'
                  size="small"
                  label={
                    <span>
                      Email id
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    </span>
                  }
                  inputProps={{ maxLength: 50, style: { height: '56px', boxSizing: 'border-box' }, }}
                  error={!!errors.email}
                  {...register("email", {
                    required: "Emailid  is required ",
                    validate: handleEmailValidation
                  })}
                  onInput={InputTrimAndLowercaseFunction}
                  helperText={errors.email?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                />
                <Box display="flex" justifyContent="flex-end" sx={{
                  marginTop: '12px', color: `${cssProperties?.bordercolor?.primary2blue}`,
                  fontWeight: 500,
                  fontSize: '14px', cursor: 'pointer'
                }}
                  onClick={() => setShowModal(true)}
                >
                  {/* <Link
                  to={`/${Cookies.get("mhet_cnd_project")}/forgot-password`}
                  style={{
                    textDecoration: "none",
                    color: `${cssProperties?.bordercolor?.primary2blue}`,
                    fontWeight: 500,
                    fontSize: '14px'
                  }}
                > */}
                  Forgot Email
                  {/* </Link> */}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
                <BluePrimaryButton
                  type="submit"
                  title={buttonLoader ? <ButtonLoader /> : "Submit"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

      </Grid >
      <EnterMobilenoModal showModal={showModal} handleClose={() => setShowModal(false)} />
    </Box >
  );
}
