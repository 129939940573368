import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

export default function Index() {
  const navigate = useNavigate();
  return (
    <Box sx={{ height: "70vh",display:"flex",alignItems:"center",justifyContent:"center" }}>
      <Box p={2} sx={{ borderRadius: "8px",backgroundColor:"#fff" }}>
        <Typography variant="h5">New Password</Typography>
        <Typography mt={1} variant="h6"> New password has been sent to your email Id</Typography>
        <Box mt={2} sx={{color:"blue"}} onClick={()=> navigate(`/${Cookies.get("mhet_cnd_project")}/login`)}>Click here</Box>
      </Box>
    </Box>
  );
}
