export const handleNameValidation = name => {
  if (name === null || name === undefined || name === '') {
    return null; 
  }
  if (name.length > 35) {
    return 'Name must be less than or equal to 35 characters';
  }
  const isValid = /^[a-zA-Z\s.]*$/.test(name);
  if (!isValid) {
    return 'Only alphabets are allowed in the name field';
  }
  return null;
};


export const AlphabetsValidation = (e) => {
  let value = e.target.value;

  value = value.replace(/[^a-zA-Z\s.]/g, '');

  if (value[0] === ' ' || value.split('.').length > 2) {
    value = value.slice(0, -1); 
  }

  if (value === '.' || /^[a-zA-Z\s]*\.$/.test(value)) {
    e.target.value = value;
  } else {
    e.target.value = value.replace(/\./g, '');
  }

  return e.target.value;
};


export const AlphabetsandNumbersValidation = (e) => {
  let value = e.target.value.replace(/[^a-zA-Z0-9\s]/gi, '');

  if (value[0] === ' ') {
    value = value.slice(1);
  }

  e.target.value = value;
  return value;
};

export const AddressValidation = (address) => {
  if (!address) {
    return null;
  }
  const isValid = /^[a-zA-Z0-9\s]*$/.test(address);
  let error = null;

  if (!isValid) {
    error = 'Only alphabets and numbers are allowed.';
  } else if (address.length > 50) {
    error = 'Address must be less than or equal to 50 characters.';
  }
  return error;
};

export const AddressValidationFirstField = (address) => {
  const isValid = /^[a-zA-Z0-9\s,\/-]*$/.test(address);
  let error = null;

  if (!isValid) {
    error = 'Special characters are not allowed.';
  } else if (address.length > 50) {
    error = 'Address must be less than or equal to 50 characters.';
  }
  return error;
};


export const pincodeValidation = (input) => {
  if (!input) {
    return null;
  }
  const isValid = /^[0-9]{6}$/.test(input);
  let error = null;
  if (!isValid) {
    error = 'Invalid input. Pincode must be 6 digits long.';
  }
  return error;
};



export const NumbersValidation = (e) => {
  let value = e.target.value.replace(/[^0-9]/g, '');
  e.target.value = value;
  return value;
};

export const MobileNumberValidation = (e) => {
  const validValue = e.target.value.replace(/\D/g, '');
  const containsOnlyZeroes = /^0+$/.test(validValue);
  if (containsOnlyZeroes) {
    e.target.value = '';
  } else {
    e.target.value = validValue;
  }
};

export const MobileNumberFirstNumberValidation = (number) => {
  if (number?.length > 0) {
    let value = number;
    if (parseInt(value?.slice(0, 1)) < 6) {
      return 'Enter valid mobile number';
    }
  }
};


export const telephoneNumberFirstNumberValidation = (number) => {
  if (number?.length > 0) {
    let value = number;
    if (parseInt(value?.slice(0, 1)) < 2) {
      return 'Enter valid mobile number';
    }
  }
};

export const percentageValidation = (input) => {
  let error = null;
  if (isNaN(input)) {
    error = 'Invalid input. Please enter a number.';
    return error;
  }
  const decimalPlaces = input.split('.')[1]?.length || 0;
  if (decimalPlaces > 2) {
    error = 'Invalid input. Percentage cannot have more than two decimal places.';
    return error;
  }
  const value = parseFloat(input);
  if (value < 0) {
    error = 'Invalid input. Percentage cannot be negative.';
  } else if (value > 100) {
    error = 'Invalid input. Percentage cannot be greater than 100.';
  } else if (value > 99.99 && input.length > 5) {
    error = 'Invalid input. Percentage with two digits cannot exceed 99.99';
  }
  return error;
};

export const pincodeNumberValidation = (e) => {
  let value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
  e.target.value = value;
  return value;
};

export const percentageNumberValidation = (e) => {
  let value = e.target.value;
  value = value.replace(/[^0-9.]/g, '');
    const decimalIndex = value.indexOf('.');
  if (decimalIndex !== -1 && value.length - decimalIndex > 3) {
    value = value.slice(0, decimalIndex + 3);
  }
  const numericValue = parseFloat(value);
  if (numericValue <= 0) {
    value = '';
  } else if (numericValue > 900) {
    value = '900';
  } else if (numericValue > 900 && value.length > 3) {
    value = '899.99';
  }
  
  e.target.value = value;
  return value;
};


export const markValidation = (input) => {
  let error = null;
  const inputStr = input.toString(); 
  if (inputStr.includes('.')) {
    error = 'Invalid input. Marks cannot have decimal places.';
    return error;
  }
  const value = parseInt(inputStr, 10);
  if (value < 0) {
    error = 'Invalid input. Marks cannot be negative.';
  } else if (value > 2000) {
    error = 'Invalid input. Marks cannot be greater than 2000.';
  } else if (inputStr.length > 4) {
    error = 'Invalid input. Marks cannot have more than four digits.';
  }

  return error;
};



export const handleEmailValidation = email => {
  // eslint-disable-next-line no-useless-escape
  const isValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
  let error;

  if (!isValid) {
    error = 'Enter valid Email id';
  }

  return error;
};

export const passwordValidation = (password) => {

  let errors = [];

  // Check for minimum length
  if (password.length < 8) {
    errors.push('Password must be at least 8 characters long.');
  }

  // Check for uppercase letter
  if (!/[A-Z]/.test(password)) {
    errors.push('Password must contain at least one uppercase letter.');
  }

  // Check for lowercase letter
  if (!/[a-z]/.test(password)) {
    errors.push('Password must contain at least one lowercase letter.');
  }

  // Check for number
  if (!/\d/.test(password)) {
    errors.push('Password must contain at least one number.');
  }

  // Check for special character
  if (!/[!@#$%^&*]/.test(password)) {
    errors.push('Password must contain at least one special character.');
  }

  return errors.length > 0 ? errors.join(' ') : true;
};

export const InputTrimFunction = (e) => {
  if (e.target.value?.length > 0) {
    return e.target.value = e.target.value?.trim();
  }
};

export const InputTrimAndLowercaseFunction = (e) => {
  if (e.target.value?.length > 0) {
      return e.target.value = e.target.value?.trim()?.toLowerCase();
  }
};

export const calculateDaysDifference = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay));
  return diffDays || "0";
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export const hashMobile = (mobile) => {
  const hashedMobile = mobile.substring(0, 4) + '****' + mobile.substring(8, 10);
  return hashedMobile;
};