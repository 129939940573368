import React from "react";
import { Grid, TextField, Typography, Box, Select, MenuItem } from "@mui/material";

const Index = () => {
  return (
    <>
      <Grid>
        <Grid
          p={2}
          style={{
            minHeight: "74vh",
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
          >
          <Typography variant="h5" > Objection Details</Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={3} lg={3} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Question No
              </Typography>
            </Grid>
            <Grid item md={9} lg={9} xs={12}>
              <TextField
                disabled
                fullWidth
                size="small"
                placeholder="Enter the name"
                sx={{ width: "100%" }}
                value={'Test no.'}
              />
            </Grid>
            <Grid item md={3} lg={3} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Question Details
              </Typography>
            </Grid>
            <Grid item md={9} lg={9} xs={12}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  disabled
                  multiline
                  rows={3}
                  value={"testeest testeesttesteesttesteesttesteest"}
                  sx={{ width: "100%" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "10rem",
                    justifyContent: "space-between",
                  }}
                >
              
                </Box>
              </Box>
            </Grid>
            <Grid item md={3} lg={3} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Answer Key
              </Typography>
            </Grid>
            <Grid item md={9} lg={9} xs={12}>
              <TextField
                disabled
                multiline
                rows={2}
                placeholder="Enter the grievances"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={3} lg={3} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Your Response
              </Typography>
            </Grid>
            <Grid item md={9} lg={9} xs={12}>
              <TextField
                disabled
                multiline
                value={'Test Response'}
                rows={2}
                placeholder="Enter the grievance category"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={3} lg={3} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Correct Answer
              </Typography>
            </Grid>
            <Grid item md={9} lg={9} xs={12}>
              <TextField
                className="form-control-disabled"
                multiline
                rows={2}
                defaultValue="No Comments"
                disabled
                sx={{ width: "100%" }}
                value={'test correct answer'}
              />
            </Grid>
            <Grid item md={3} lg={3} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Explanation
              </Typography>
            </Grid>
            <Grid item md={9} lg={9} xs={12}>
              <TextField
                multiline
                rows={2}
                placeholder="Enter the response"
                sx={{ width: "100%" }}
                value={"test Explaination"}
                disabled
              />
            </Grid>
          </Grid>
              </Grid>
      </Grid>
    </>
  );
};

export default Index;
