import { Box, Card, Grid, Link } from "@mui/material";
import React, { useEffect } from "react";
import { PiArrowRightLight } from "react-icons/pi";
import "./courseSelection.css";
import { GrDocumentDownload } from "react-icons/gr";
import { cssProperties } from "../../utils/commonCssProperties";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
export default function CourseList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const courseid = location.state?.courseid;
  console.log(courseid);
  const { courseList } = useSelector((state) => state.candidatesReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  useEffect(() => {
    if (candidateid) dispatch({ type: candidatesActions.GET_ALL_COURSE_LIST });
  }, [candidateid]);

  const list = [
    {
      courseCategory: "PG",
      course: [
        {
          name: "B.Ed.M.Ed.- CET 2024",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
        {
          name: "M.Ed.- CET 2024",
          date: "(23Jan-15Mar)",
          register: "Registration yet to start",
        },
        {
          name: "M.P.Ed. - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
        {
          name: "B.Ed. (General & Special) & B.Ed.-ELCT - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Already registered",
        },
        {
          name: "B.P.Ed. - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Payment to be done",
        },
        {
          name: "L.L.B.3 Yrs. - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
        {
          name: "B.A/B.Sc-B.Ed (Four Year Integrated course) - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Registration closed",
        },
        {
          name: "L.L.B.5 Yrs. - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
      ],
    },
    {
      courseCategory: "TECHNICAL EDUCATION",
      course: [
        {
          name: "MHTCET - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
        {
          name: "MBA - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
        {
          name: "MCA - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Registration yet to start",
        },
        {
          name: "M.Arch - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Registration closed",
        },
        {
          name: "M.HMCT - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
        {
          name: "B.HMCT - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
        {
          name: "B Design - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
        {
          name: "B.Planning - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
      ],
    },
    {
      courseCategory: "MEDICAL EDUCATION",
      course: [
        {
          name: "MAH-PGP-CET",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
        {
          name: "B.Sc Nursing - CET 2024",
          date: "(23Jan-15Mar)",
          register: "Registration closed",
        },
        {
          name: "MAH-ANM-GNM CET 2024",
          date: "(23Jan-15Mar)",
          register: "Registration yet to start",
        },
      ],
    },
    {
      courseCategory: "ART EDUCATION",
      course: [
        {
          name: "AAC-CET 2024 (Fine Art)",
          date: "(23Jan-15Mar)",
          register: "Register now",
        },
      ],
    },
  ];
  function transformCourses(courses) {
    const result = [];

    const categoryMap = new Map();

    courses.forEach((course) => {
      const {
        courseid,
        coursecategory,
        coursename,
        regstartdate,
        regenddate,
        corcandidateid,
        isactive,
        ispaid,
        brochurelocation,
      } = course;

      if (!categoryMap.has(coursecategory)) {
        categoryMap.set(coursecategory, []);
      }

      let registerStatus = "";
      if (corcandidateid) {
        if (ispaid === "1") {
          registerStatus = "Already registered";
        } else {
          registerStatus = "Payment to be done";
        }
      } else if (isactive === "1") {
        if (new Date(regstartdate) > new Date()) {
          registerStatus = "Registration yet to start";
        }
        if (new Date(regenddate) < new Date()) {
          registerStatus = "Registration closed";
        }
        if (
          new Date(regstartdate) <= new Date() &&
          new Date(regenddate) >= new Date()
        ) {
          registerStatus = "Register now";
        }
      } else {
        registerStatus = "Registration closed";
      }

      let dateRange = "";
      if (regstartdate && regenddate) {
        const start = new Date(regstartdate).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        });
        const end = new Date(regenddate).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        });
        dateRange = `(${start}-${end})`;
      }

      categoryMap.get(coursecategory).push({
        name: coursename,
        date: dateRange,
        register: registerStatus,
        brochurelocation: brochurelocation,
        courseid: courseid,
      });
    });

    categoryMap.forEach((courses, courseCategory) => {
      result.push({
        courseCategory: courseCategory,
        course: courses,
      });
    });

    return result;
  }

  return (
    <Box
      sx={{
        margin: "1rem auto",
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "8px",
        padding: "10px",
      }}
    >
      {transformCourses(courseList?.filtered)?.map((val, listInd) => (
        <Box key={listInd}>
          <Box className="course_list_category">{val?.courseCategory}</Box>
          <Course course={val?.course} />
        </Box>
      ))}
    </Box>
  );
}

const Course = ({ course }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      {course?.map((c, index) => (
        <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
          <Card className="course_list_card_cls">
            <Box>
              <Box className="course_list_name">{c?.name}</Box>
              <Box className="course_list_date">{c?.date}</Box>
            </Box>
            {c?.register === "Register now" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  className="course_list_register_now"
                  onClick={() =>
                    navigate(
                      `/${Cookies.get("mhet_cnd_project")}/pre-request`,
                      { state: { courseid: c.courseid } }
                    )
                  }
                >
                  {" "}
                  Register Now <PiArrowRightLight />{" "}
                </Box>
                {c.brochurelocation.trim() !== "" && (
                <Box color={"#310000"} sx={{ cursor: "pointer" }}>
                  <Link
                    target="_blank"
                    href={c.brochurelocation}
                    download="brochure.pdf"
                  >
                    <GrDocumentDownload />{" "}
                  </Link>{" "}
                </Box>
                 )} 
              </Box>
            ) : c?.register === "Registration yet to start" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box className="course_list_register_yet_to_start">
                  Registration yet to start
                </Box>
                {c.brochurelocation.trim() !== "" && (
                <Box color={"#310000"} sx={{ cursor: "pointer" }}>
                  <Link
                    target="_blank"
                    href={c.brochurelocation}
                    download="brochure.pdf"
                  >
                    <GrDocumentDownload />{" "}
                  </Link>{" "}
                </Box>
                )} 
              </Box>
            ) : c?.register === "Already registered" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box className="course_list_registration_closed">
                  Already registered
                </Box>

                {c.brochurelocation.trim() !== "" && (
                <Box color={"#310000"} sx={{ cursor: "pointer" }}>
                  <Link
                    target="_blank"
                    href={c.brochurelocation}
                    download="brochure.pdf"
                  >
                    <GrDocumentDownload />{" "}
                  </Link>{" "}
                </Box>
                 )} 
              </Box>
            ) : c?.register === "Payment to be done" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  className="course_list_payment_to_be_done"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/${Cookies.get(
                        "mhet_cnd_project"
                      )}/course-registration?page=personalinformation`,
                      { state: { courseid: c.courseid } }
                    )
                  }
                >
                  Payment to be done <PiArrowRightLight />
                </Box>

                {c.brochurelocation.trim() !== "" && (
                <Box color={"#310000"} sx={{ cursor: "pointer" }}>
                  <Link
                    target="_blank"
                    href={c.brochurelocation}
                    download="brochure.pdf"
                  >
                    <GrDocumentDownload />{" "}
                  </Link>{" "}
                </Box>
                 )} 
              </Box>
            ) : c?.register === "Registration closed" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box className="course_list_registration_closed">
                  Registration closed
                </Box>
                {c.brochurelocation.trim() !== "" && (
                  <Box color={"#310000"} sx={{ cursor: "pointer" }}>
                    <Link
                      target="_blank"
                      href={c.brochurelocation}
                      download="brochure.pdf"
                    >
                      <GrDocumentDownload />{" "}
                    </Link>{" "}
                  </Box>
                )}
              </Box>
            ) : null}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
