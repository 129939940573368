import authActions from "./actions";
import commonActions from "../common/actions";
import { takeEvery, call, all, put, delay, select } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import Cookies from "js-cookie";
import setAuthToken from "../../utils/setAuthToken";
import {
  EncryptFunction,
  EncryptObjectFunction,
} from "../../utils/cryptoFunction";

const authSagas = function* () {
  yield all([
    yield takeEvery(authActions.CANDIDATE_SINUP, candidateSignup),
    yield takeEvery(authActions.CANDIDATE_LOGIN, candidateLogin),
    yield takeEvery(
      authActions.CANDIDATE_FORGETPASSWORD,
      candidateForgetPassword
    ),
    yield takeEvery(authActions.CANDIDATE_TOKEN_VERIFY, candidateTokenVerify),
    yield takeEvery(
      authActions.VERIFY_EMAILID_FOR_SIGNUP,
      verifyEmailidForSignup
    ),
    yield takeEvery(authActions.CANDIDATE_LOGOUT, candidateLogout),
    yield takeEvery(
      authActions.CHECK_EMAIL_VERIFICAITON,
      checkEmailVerification
    ),
    yield takeEvery(
      authActions.RESEND_EMAIL_FOR_VERIFICATION,
      resendEmailForVerification
    ),
    yield takeEvery(
      authActions.VERIFY_MOBILENO_FOR_SIGNUP,
      verifyMobilenoForSignup
    ),
    yield takeEvery(
      authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB,
      getProfileRegistrationActiveTab
    ),
    yield takeEvery(
      authActions.CHECK_CANDIDATE_MOBILENO,
      checkCandidateMobileno
    ),
    yield takeEvery(
      authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
      sendOtpForMobileVerification
    ),
    yield takeEvery(
      authActions.FORGOTPASSWORD_MOBILEVERIFICATION_SENDMAILID,
      forgotPasswordMobileVerification
    ),
    yield takeEvery(
      authActions.CHECK_DUPLICATE_EMAIL_OR_MOBILENO,
      checkDuplicateEmailOrMobileno
    ),
    yield takeEvery(
      authActions.CANDIDATE_RESET_PASSWORD,
      candidateResetPassword
    ),
  ]);
};

const candidateSignup = function* (data) {
  const { payload } = data;
  // const { navigate } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/signup`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      // navigate(`/${Cookies.get('mhet_cnd_project')}/login`);
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: true,
          type: "EV",
          method: "emailAndMobileVerification",
        },
      });
    //   navigate(`/${Cookies.get("mhet_cnd_project")}/verify-email`);
      Cookies.set("mhet_cnd_temptoken", result?.data?.token, { expires: 7 });
      Cookies.set(
        "mhet_cnd_tempdata",
        EncryptObjectFunction(result?.data?.data),
        { expires: 7 }
      );
      Cookies.set("mhet_cnd_email_verified", false);
      Cookies.set("mhet_cnd_mobileno_verified", false);
      Cookies.set("mhet_cnd_mobileno", payload?.data?.mobileno);

      // setAuthToken(result?.data?.token);
      // yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (error) {
    console.log(error);
    // Cookies.remove('mhet_cnd_token');
    window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const candidateLogin = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/login`, payload?.data)
    );
    // debugger
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      if (result?.data?.page === "emailid") {
        navigate(`/${Cookies.get("mhet_cnd_project")}/verify-email`);
        Cookies.set("mhet_cnd_temptoken", result?.data?.token, { expires: 7 });
        Cookies.set(
          "mhet_cnd_tempdata",
          EncryptObjectFunction(result?.data?.data),
          { expires: 7 }
        );
        Cookies.set("mhet_cnd_email_verified", false);
        Cookies.set("mhet_cnd_mobileno_verified", false);
      } else if (result?.data?.page === "mobileno") {
        yield put({
          type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
          payload: {
            data: { mobileno: result.data.data.mobileno, navigate: navigate },
          },
        });

        navigate(`/${Cookies.get("mhet_cnd_project")}/verify-mobileno`);
        Cookies.set("mhet_cnd_temptoken", result?.data?.token, { expires: 7 });
        Cookies.set(
          "mhet_cnd_tempdata",
          EncryptObjectFunction(result?.data?.data),
          { expires: 7 }
        );
        Cookies.set("mhet_cnd_email_verified", true);
        Cookies.set("mhet_cnd_mobileno_verified", false);
      } else {
        navigate(`/${Cookies.get("mhet_cnd_project")}/home`);
        Cookies.set("mhet_cnd_token", result?.data?.token, { expires: 7 });
        setAuthToken(result?.data?.token);
        yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
      }
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (error) {
    console.log(error);
    Cookies.remove("mhet_cnd_token");
    window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
};

const candidateLogout = function* (data) {
  // const { payload } = data;
  // const { navigate } = payload;

  // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    let token = Cookies.get("mhet_cnd_token");
    if (token) {
      const result = yield call(() =>
        axios.post(`${API_URL}/candidate/logout`, { token: token })
      );
      // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      if (result?.data?.statusCode === 200) {
        Cookies.remove("mhet_cnd_token");
        window.location.href = `/${Cookies.get("mhet_cnd_project")}/`;
      }
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
    }
  } catch (error) {
    console.log(error);
    Cookies.remove("mhet_cnd_token");
    window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
};

const candidateForgetPassword = function* (data) {

    const { payload } = data;
    const { navigate, setReset, setVerified, setEmailid  } = payload;
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/candidate/forgot-password`, payload?.data)
        );
        // debugger
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            // navigate(`/${Cookies.get('mhet_cnd_project')}/forgot-password-status`);
            // Cookies.set('mhet_cnd_token', result?.data?.token, { expires: 7 });
            // setAuthToken(result?.data?.token);
            setReset(false);
            setVerified(true);
            setEmailid(payload?.data?.email);
            // yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (error) {
        console.log(error);
        Cookies.remove('mhet_cnd_token');
        // window.location.href = `/${Cookies.get('mhet_cnd_project')}/login`;
    };
};


const candidateResetPassword = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
      const result = yield call(() =>
          axios.post(`${API_URL}/candidate/reset-password`, payload?.data)
      );
      yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
      yield put({
          type: commonActions.SET_ALERT,
          payload: {
              status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
              show: true,
              message: result?.data?.message
          }
      });
      if (result?.data?.statusCode === 200) {
        navigate(`/${Cookies.get('mhet_cnd_project')}/reset-password?page=success`);
      };
      yield delay(2000);
      yield put({
          type: commonActions.SET_ALERT,
          payload: { status: null, show: false, message: null }
      });
  } catch (error) {
      console.log(error);
  };
};

const candidateTokenVerify = function* () {
  try {
    const { candidateTokenDetails } = yield select(
      (state) => state.authReducer
    );
    if (!candidateTokenDetails) {
      const result = yield call(() =>
        axios.get(`${API_URL}/candidate/verify-token`, {
          headers: {
            authorization: `Bearer ${Cookies.get("mhet_cnd_token")}`,
          },
        })
      );
      if (result?.data?.statusCode === 200) {
        yield put({
          type: authActions.SET_CANDIDATE_TOKEN_DETAILS,
          payload: result?.data?.result,
        });
      } else {
        Cookies.remove("mhet_cnd_token");
        window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
      }
    }
    // else{
    //     Cookies.remove('mhet_cnd_token');
    //     window.location.href = `/${Cookies.get('mhet_cnd_project')}/`;
    // }
  } catch (err) {
    console.log(err);
    Cookies.remove("mhet_cnd_token");
    window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
};

const verifyEmailidForSignup = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/verify-emailid`, payload?.data)
    );
    if (result?.data?.statusCode === 200) {
      navigate(`/candidate/email-verification-status?status=success`);
    } else {
      navigate(`/candidate/email-verification-status?status=failed`);
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const checkEmailVerification = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/check-emailid-verified`, payload?.data)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
        yield put({
            type: commonActions.SET_SHOW_MODAL,
            payload: {
              show: false,
              type: "EV",
              method: "emailAndMobileVerification",
            },
          });
        yield put({
            type: commonActions.SET_SHOW_MODAL,
            payload: {
              show: true,
              type: "EVS",
              method: "emailAndMobileVerification",
            },
          });
      Cookies.set("mhet_cnd_email_verified", true);
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const resendEmailForVerification = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/resend-verify-email`, payload?.data)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const sendOtpForMobileVerification = function* (data) {
  const { payload } = data;
  const { navigate, mobileno, setResendCountdown } = payload.data;
  try {
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/check-mobileno/${mobileno}`)
    );
    // console.log(result)
    if (result?.data?.statusCode === 200) {
      Cookies.set("mhet_cnd_mobileno_otp", EncryptFunction(result?.data?.otp));
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: false,
          type: "EVS",
          method: "emailAndMobileVerification",
        },
      });
    yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: true,
          type: "MV",
          method: "emailAndMobileVerification",
        },
      });
      setResendCountdown(60);
    //   navigate(`/${Cookies.get("mhet_cnd_project")}/verify-mobileno`);
    }
  } catch (err) {
    console.log(err);
  }
};
const verifyMobilenoForSignup = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/verify-mobileno`, payload?.data)
    );
    if (result?.data?.statusCode === 200) {
      // navigate(`/${Cookies.get("mhet_cnd_project")}/mobileno-verification`);
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: false,
          type: "Mv",
          method: "emailAndMobileVerification",
        },
      });
    yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: true,
          type: "MVS",
          method: "emailAndMobileVerification",
        },
      });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
      yield delay(10000);
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: false,
          type: "MVS",
          method: "emailAndMobileVerification",
        },
      });
      navigate(`/${Cookies.get("mhet_cnd_project")}/login`);
      Cookies.set("mhet_cnd_mobileno_verified", true);
      yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    }
    
  } catch (err) {
    console.log(err);
  }
};

const getProfileRegistrationActiveTab = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/profile-activetab/${payload?.id}`)
    );
    yield put({
      type: authActions.SET_PROFILE_REGISTRATION_ACTIVE_TAB,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : "new",
    });
  } catch (err) {
    console.log(err);
  }
};

const checkCandidateMobileno = function* (data) {
    const { payload } = data;
    const { handleShowOtpInput,setForget,setResendCountdown} = payload;

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/candidate/check-mobileno/${payload?.data}`)
        );
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
        if (result?.data?.statusCode === 200) {
            Cookies.set('mhet_cnd_forgetemail_otp', EncryptFunction(result?.data?.otp));
            setForget(false)
            setResendCountdown(60);
            handleShowOtpInput();
           
        };
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        console.log(err);
    }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const forgotPasswordMobileVerification = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/send-mail-by-mobileno/${payload.data}`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if (result?.data?.statusCode === 200) {
      navigate(`/${Cookies.get("mhet_cnd_project")}/login`);
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const checkDuplicateEmailOrMobileno = function* (data) {
  const { payload } = data;
  const { setAndClearError } = payload;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/check-duplicate`, payload?.data)
    );
    if (result?.data) {
      setAndClearError({ ...result?.data, type: payload?.data?.type });
    }
    // if (result?.data?.statusCode === 200) {
    //     navigate(`/${Cookies.get('mhet_cnd_project')}/login`);
    // };
    // yield put({
    //     type: commonActions.SET_ALERT,
    //     payload: {
    //         status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
    //         show: true,
    //         message: result?.data?.message
    //     }
    // });
    // yield delay(2000);
    // yield put({
    //     type: commonActions.SET_ALERT,
    //     payload: { status: null, show: false, message: null }
    // });
  } catch (err) {
    console.log(err);
  }
};

export default authSagas;
