import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  handleNameValidation,
  AlphabetsValidation,
  handleEmailValidation,
} from "../../utils/validations";
import masterActions from "../../redux/master/action";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../../common/backButton";
import Cookies from "js-cookie";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";

export default function PersonalDetailsForm({ preprocessFormData }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [maritalStatus, setMaritalStatus] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleMaritalStatusChange = (e) => {
    setValue("maritalstatusid", parseInt(e.target.value));
    trigger("maritalstatusid");
    setMaritalStatus(e.target.value);
    if (e.target.value === 0) {
      setValue("spousename", "");
    }
  };

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const { gender, mothertongue, region, religion, nationality, annualIncome } =
    useSelector((state) => state.masterReducer);
  const { personalDetails } = useSelector((state) => state.candidatesReducer);
  console.log(personalDetails);
  const candidatedraftid = personalDetails[0]?.candidatedraftid;
  console.log(personalDetails);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  // useEffect(() => {
  //   if (personalDetails.length > 0) {
  //     const {

  //       status
  //     } = personalDetails[0];
  //     if(status === 2){
  //       console.log(status,'status')
  //       setDisabled(true)
  //     }else{
  //       console.log(status,'status false')
  //       setDisabled(false)
  //     }

  //   }
  // }, []);
  useEffect(() => {
    if (personalDetails.length > 0) {
      const {
        genderid,
        fathername,
        mothername,
        mothertongueid,
        religionid,
        regionid,
        nationalityid,
        familyincomeid,
        maritalstatusid,
        emailid,
        mobileno,
        spousename,
        status,
      } = personalDetails[0];
      if (status === 2) {
        console.log(status, "status");
        setDisabled(true);
      } else {
        console.log(status, "status false");
        setDisabled(false);
      }
      setValue("candidatename", personalDetails[0]?.candidatename);
      setValue("genderid", genderid);
      setValue("dob", personalDetails[0]?.dob);
      setValue("fathername", fathername);
      setValue("mothername", mothername);
      setValue("mothertongueid", mothertongueid);
      setValue("religionid", religionid);
      setValue("regionid", regionid);
      setValue("nationalityid", nationalityid);
      setValue("familyincomeid", familyincomeid);
      setValue("maritalstatusid", maritalstatusid);
      setValue("emailid", emailid);
      setValue("mobileno", mobileno);
      setValue("spousename", spousename === null ? "" : spousename);
    }
  }, [personalDetails, setValue]);

  useEffect(() => {
    dispatch({ type: masterActions.GET_GENDER });
    dispatch({ type: masterActions.GET_MOTHERTONGUE });
    dispatch({ type: masterActions.GET_REGION });
    dispatch({ type: masterActions.GET_RELIGION });
    dispatch({ type: masterActions.GET_NATIONALITY });
    dispatch({ type: masterActions.GET_ANNUALINCOME });
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  const handleDraft = (getValues) => {
    const values = getValues();
    let operation = candidatedraftid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_PERSONAL_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...values,
          status: 1,
          operation: operation,
          candidateid: parseInt(candidateid),
        },
      },
    });
    console.log(operation);
    dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
  };

  const handleSave = (data) => {
    dispatch({
      type: candidatesActions.PROFILE_PERSONAL_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          status: 2,
        },
        navigate: navigate,
      },
    });
  };

  const handleCancel = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/home`);
  };

  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #0F4F96",
      }}
    >
      <Box mb={2}>
        <Typography
          variant="h5"
          sx={{ fontSize: 14, fontWeight: 400, color: "#666666" }}
        >
          * Marked fields are required
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            variant="outlined"
            // sx={{
            //   '& .MuiInputBase-input.Mui-disabled': {

            //       color: '#a3a3a3',

            //   }
            // }}
            type="text"
            label={
              <span>
                Name (As per SSC/HSC Marksheet)
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter name"
            {...register("candidatename", {
              required: "Name is required",
              validate: handleNameValidation,
            })}
            disabled
            onInput={AlphabetsValidation}
            InputLabelProps={{
              shrink: !!watch("candidatename")?.trim(),
            }}
          />
          {/* {errors.candidatename && (
            <small className="error">{errors.candidatename.message}</small>
          )} */}
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            label={
              <span>
                DOB
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Click to set date"
            InputLabelProps={{
              shrink: !!watch("dob"),
            }}
            type="date"
            {...register("dob", {
              required: "DOB is required",
            })}
            disabled
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            type="text"
            variant="outlined"
            label={
              <span>
                Email
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter email"
            {...register("emailid", {
              required: "Email is required",
              validate: handleEmailValidation,
            })}
            disabled={true}
            InputLabelProps={{
              shrink: !!watch("emailid")?.trim(),
            }}
          />
          {/* {errors.emailid && (
            <small className="error">{errors.emailid.message}</small>
          )} */}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            type="text"
            variant="outlined"
            label={
              <span>
                Mobile No
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter mobile number"
            {...register("mobileno", {
              required: "Mobile number is required",
            })}
            disabled={true}
            InputLabelProps={{
              shrink: !!watch("mobileno")?.trim(),
            }}
          />
          <Box pt={1} sx={{ display: "flex", flexDirection: "column" }}>
            {/* {errors.mobileno && (
              <small className="error">{errors.mobileno.message}</small>
            )} */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            type="text"
            variant="outlined"
            disabled={disabled}
            label={
              <span>
                Enter Father Name
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter Father's Name"
            {...register("fathername", {
              required: "Father's Name is required",
              validate: handleNameValidation,
            })}
            onInput={AlphabetsValidation}
            error={!!errors.fathername}
            helperText={
              errors.fathername?.message ||
              "Enter a dot (.) In Case of Blank/ Not applicable"
            }
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            InputLabelProps={{
              shrink: !!watch("fathername")?.trim(),
            }}
          />
          {/* {errors.fathername && (
            <small className="error">{errors.fathername.message}</small>
          )} */}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            type="text"
            variant="outlined"
            label={
              <span>
                Enter Mother Name
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            disabled={disabled}
            placeholder="Enter Mother's Name"
            {...register("mothername", {
              required: "Mother's Name is required",
              validate: handleNameValidation,
            })}
            error={!!errors.mothername}
            helperText={
              errors.mothername?.message ||
              "Enter a dot (.) In Case of Blank/ Not applicable"
            }
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            onInput={AlphabetsValidation}
            InputLabelProps={{
              shrink: !!watch("mothername")?.trim(),
              // shrink: "mothername"?.trim(),
            }}
          />
          {/* {errors.mothername && (
            <small className="error">{errors.mothername.message}</small>
          )} */}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.genderid}>
            <InputLabel shrink={!!watch("genderid")} htmlFor="genderid">
              Gender
              <span className="error" style={{ color: "#666666" }}>
                {" "}
                *
              </span>
            </InputLabel>
            <Select
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              id="genderid"
              label="Gender"
              disabled={disabled}
              {...register("genderid", { required: "Gender is required" })}
              onChange={(e) => {
                setValue("genderid", e.target.value);
                trigger("genderid");
              }}
              value={watch("genderid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {gender?.map((val, i) => (
                <MenuItem value={val?.genderid} key={i}>
                  {val?.gender}
                </MenuItem>
              ))}
            </Select>
            {errors.genderid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.genderid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.religionid}>
            <InputLabel shrink={!!watch("religionid")} htmlFor="religionid">
              Religion
              <span className="error" style={{ color: "#666666" }}>
                {" "}
                *
              </span>
            </InputLabel>
            <Select
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              label="Religion"
              disabled={disabled}
              {...register("religionid", {
                required: "Religion is required",
              })}
              onChange={(e) => {
                setValue("religionid", e.target.value);
                trigger("religionid");
              }}
              value={watch("religionid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {religion?.map((val, i) => (
                <MenuItem value={val?.religionid} key={i}>
                  {val?.religion}
                </MenuItem>
              ))}
            </Select>
            {errors.religionid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.religionid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.regionid}>
            <InputLabel shrink={!!watch("regionid")} htmlFor="regionid">
              Region
              <span className="error" style={{ color: "#666666" }}>
                {" "}
                *
              </span>
            </InputLabel>
            <Select
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              label="Region"
              disabled={disabled}
              {...register("regionid", { required: "Region is required" })}
              onChange={(e) => {
                setValue("regionid", e.target.value);
                trigger("regionid");
              }}
              value={watch("regionid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {region?.map((val, i) => (
                <MenuItem value={val?.regionid} key={i}>
                  {val?.region}
                </MenuItem>
              ))}
            </Select>
            {errors.regionid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.regionid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.mothertongueid}>
            <InputLabel
              shrink={!!watch("mothertongueid")}
              htmlFor="mothertongueid"
            >
              Mother Tongue
              <span className="error" style={{ color: "#666666" }}>
                {" "}
                *
              </span>
            </InputLabel>
            <Select
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              label="Mother Tongue"
              disabled={disabled}
              {...register("mothertongueid", {
                required: "Mother Tongue is required",
              })}
              onChange={(e) => {
                setValue("mothertongueid", e.target.value);
                trigger("mothertongueid");
              }}
              value={watch("mothertongueid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {mothertongue?.map((val, i) => (
                <MenuItem value={val?.mothertongueid} key={i}>
                  {val?.mothertongue}
                </MenuItem>
              ))}
            </Select>
            {errors.mothertongueid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.mothertongueid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.nationalityid}>
            <InputLabel
              shrink={!!watch("nationalityid")}
              htmlFor="nationalityid"
            >
              Nationality
              <span className="error" style={{ color: "#666666" }}>
                {" "}
                *
              </span>
            </InputLabel>
            <Select
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              label="Nationality"
              disabled={disabled}
              {...register("nationalityid", {
                required: "Nationality is required",
              })}
              onChange={(e) => {
                setValue("nationalityid", e.target.value);
                trigger("nationalityid");
              }}
              value={watch("nationalityid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {nationality?.map((val, i) => (
                <MenuItem value={val?.nationalityid} key={i}>
                  {val?.nationality}
                </MenuItem>
              ))}
            </Select>
            {errors.nationalityid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.nationalityid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.familyincomeid}>
            <InputLabel
              shrink={!!watch("familyincomeid")}
              htmlFor="familyincomeid"
            >
              {" "}
              Annual Family Income
              <span className="error" style={{ color: "#666666" }}>
                {" "}
                *{" "}
              </span>
            </InputLabel>
            <Select
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              label=" Annual Family Income"
              disabled={disabled}
              {...register("familyincomeid", {
                required: "Annual Family Income is required",
              })}
              onChange={(e) => {
                setValue("familyincomeid", e.target.value);
                trigger("familyincomeid");
              }}
              value={watch("familyincomeid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {annualIncome?.map((val, i) => (
                <MenuItem value={val?.familyincomeid} key={i}>
                  {val?.incomerange}
                </MenuItem>
              ))}
            </Select>
            {errors.familyincomeid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.familyincomeid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.maritalstatusid}>
            <InputLabel
              shrink={
                !!watch("maritalstatusid") || watch("maritalstatusid") === 0
              }
              htmlFor="maritalstatusid"
            >
              Marital Status
              <span className="error" style={{ color: "#666666" }}>
                {" "}
                *
              </span>
            </InputLabel>
            <Select
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              label="Marital Status"
              {...register("maritalstatusid", {
                required: "Marital status is required",
              })}
              onChange={handleMaritalStatusChange}
              value={watch("maritalstatusid") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={0}>Unmarried</MenuItem>
              <MenuItem value={1}>Married</MenuItem>
            </Select>
            {errors.maritalstatusid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.maritalstatusid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.spousename}>
            <TextField
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              fullWidth
              type="text"
              label="Spouse Name"
              placeholder="Enter spouse name"
              {...register("spousename", {
                validate: (value) => {
                  if (getValues("maritalstatusid") === 1 && !value.trim()) {
                    return "Spouse name is required.";
                  }
                  return true;
                },
              })}
              disabled={getValues("maritalstatusid") === 0}
              InputLabelProps={{
                shrink: !!watch("spousename")?.trim(),
              }}
              error={!!errors.spousename}
            />
            {errors.spousename && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.spousename.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        pb={2}
        pt={2}
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "flex-end",
          flexDirection: isMobile ? "column" : null,
        }}
      >
        {!editMode ? (
          <SaveButton
            name={"Edit"}
            handleDraft={() => {
              setDisabled(false);
              setEditMode(true);
            }}
          />
        ) : (
          <>
            <BackButton
              name={"Clear"}
              handleBack={() => {
                setDisabled(true);
                setEditMode(false);
                handleCancel();
              }}
            />
            <SaveButton
              name={draftButtonLoader ? "loader" : "Save As Draft"}
              handleDraft={() => handleDraft(getValues)}
            />
            <SubmitButton
              name={buttonLoader ? "loader" : "Next"}
              handleSubmit={handleSubmit(handleSave)}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}
