import {
  Box,
  Checkbox,
  Grid,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import { cssProperties } from "../../utils/commonCssProperties";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import PageLoader from "../../common/pageLoader";
export default function Index() {
  const { courseid } = useLocation().state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courseList } = useSelector((state) => state.candidatesReducer);
  const [course, setCourse] = useState(null);
  const [check, setCheck] = useState(false);
  const handleClick = () => {
    // localStorage.setItem("courseid", courseid);
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=personalinformation`,
      { state: { courseid: courseid } }
    );
    dispatch({
      type: candidatesActions.GET_COURSE_TAB,
      payload: {
        data: {
          courseid: courseid,
        },
      },
    });
  };
  useEffect(() => {
    dispatch({ type: candidatesActions.GET_ALL_COURSE_LIST })
  }, [])
  useEffect(() => {
    console.log(courseid);
    courseid && setCourse(courseList?.all?.find((obj) => obj.courseid === courseid));
  }, [courseList, courseid]);

  if (course) {
    return (
      <Grid
        p={2}
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid rgba(213, 213, 213, 1)",
          borderRadius: "8px",
          minHeight: "50vh",
          widht: "100vw",
          wordBreak: "break-word",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box>
            <Typography variant="h5" fontWeight={600}>
              {" "}
              Pre-Requisite for {course?.coursename}{" "}
            </Typography>
          </Box>
          {course?.brochurelocation && course?.brochurelocation !== "" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
                padding: "15px 0px",
              }}
            >
              <MdKeyboardDoubleArrowRight />
              <MuiLink
                target="_blank"
                href={course?.brochurelocation}
                download="brochure.pdf"
              >
                {" "}
                Download information Brochure for {course?.coursename}{" "}
              </MuiLink>
            </Box>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={6} sm={12} xs={12}>
            <div style={{ borderRadius: "8px", textWrap: "wrap",backgroundColor: "#FCDEDC", padding: "30px",  }} dangerouslySetInnerHTML={{ __html: course?.prerequisite }} />
          </Grid>
          {/* <Grid item lg={6} sm={12} xs={12}>
          <ol style={{ display: "flex", borderRadius: "8px", textWrap: "wrap", flexDirection: "column", backgroundColor: "#FCDEDC", gap: "10px", fontSize: "15px", padding: "30px", fontWeight: "500" }}>
            <li>{'Passed HSC examination.'}</li>
            <li>{'Candidate appearing for final year of qualifying examination are also eligible to appear for CET.'}</li>
            <li>
              {"Maharashtra State Candidate MUST clearly mention the Category, in case of reservation (Such as SC/ST/OBC/VI/DT-NT(A)/NT(B)/NT(C)/NT(D)/SBC/EWS/PWD/etc), Candidate must possess valid documents like Caste Certificate and Non Creamy Layer Certificates as is applicable (valid up to 31st March 2025)."}
            </li>
            <li>{'A PWD candidate must have a certificate confirming a disability of at least 40 percent or more.'}</li>
            <li>
              {`Fees: For General Category Candidates from Maharashtra State, Outside Maharashtra State (OMS) Candidates: Rs. 1000/-`}
            </li>
            <li>
              {"For Candidates of Backward class categories [SC/ST/OBC/VI/DT-NT(A)/NT(B)/NT(C)/NT(D)/SBC/EWS/PWD Candidates from Maharashtra State etc): Rs. 800/-"}
            </li>
            <li>{'AB ORPHAN/Gender Others candidates will be charged Rs. 800/-'}</li>
            <li>
              {'Please read the User Manual and information Brochure before filling the CBT (Computer Based Test) online registration form and application form, Candidate should verify the application before finally submitting and making payment.'}
            </li>
            <li>
              {' Candidates should use their active E-mail ID and Mobile Number for the registration of MAH-BBCA/BBA/BMS/BBM-CET-2024,Candidate should keep their E-mail ID and Mobile Number active till the process of CET and Centralised Admission is over.'}
            </li>
            <li>{'The submitted and fees paid applications will not be edited. Hence candidates are advised to verify the filled in details before making payment.'}</li>
            <li>{'Please do not share your Application No., Password and OTP with anybody.'}</li>
            <li>{'Please use Good Quality Photograph, Signature and Document for Proof of identity images for Uploading.'}</li>
            <li>{'Candidates are advised to visit the official www.mahacet.org website for latest Notifications.'}</li>
            <li>{'Candidate use Only one Mobile No and E-mail ID for One Application Form.'}</li>
          </ol>
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <ol style={{ display: "flex", textWrap: "wrap", borderRadius: "8px", flexDirection: "column", backgroundColor: "#FCDEDC", gap: "10px", fontSize: "15px", padding: "30px", fontWeight: "500" }}>
            <li>{'Passed HSC examination.'}</li>
            <li>{'Candidate appearing for final year of qualifying examination are also eligible to appear for CET.'}</li>
            <li>
              {"Maharashtra State Candidate MUST clearly mention the Category, in case of reservation (Such as SC/ST/OBC/VI/DT-NT(A)/NT(B)/NT(C)/NT(D)/SBC/EWS/PWD/etc), Candidate must possess valid documents like Caste Certificate and Non Creamy Layer Certificates as is applicable (valid up to 31st March 2025)."}
            </li>
            <li>{'A PWD candidate must have a certificate confirming a disability of at least 40 percent or more.'}</li>
            <li>
              {`Fees: For General Category Candidates from Maharashtra State, Outside Maharashtra State (OMS) Candidates: Rs. 1000/-`}
            </li>
            <li>
              {"For Candidates of Backward class categories [SC/ST/OBC/VI/DT-NT(A)/NT(B)/NT(C)/NT(D)/SBC/EWS/PWD Candidates from Maharashtra State etc): Rs. 800/-"}
            </li>
            <li>{'AB ORPHAN/Gender Others candidates will be charged Rs. 800/-'}</li>
            <li>
              {'Please read the User Manual and information Brochure before filling the CBT (Computer Based Test) online registration form and application form, Candidate should verify the application before finally submitting and making payment.'}
            </li>
            <li>
              {'Candidates should use their active E-mail ID and Mobile Number for the registration of MAH-BBCA/BBA/BMS/BBM-CET-2024,Candidate should keep their E-mail ID and Mobile Number active till the process of CET and Centralised Admission is over.'}
            </li>
            <li>{'The submitted and fees paid applications will not be edited. Hence candidates are advised to verify the filled in details before making payment.'}</li>
            <li>{'Please do not share your Application No., Password and OTP with anybody.'}</li>
            <li>{'Please use Good Quality Photograph, Signature and Document for Proof of identity images for Uploading.'}</li>
            <li>{'Candidates are advised to visit the official www.mahacet.org website for latest Notifications.'}</li>
            <li>{'Candidate use Only one Mobile No and E-mail ID for One Application Form.'}</li>
          </ol>
        </Grid> */}
        </Grid>

        <Box
          mt={3}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox onChange={(e) => setCheck(e.target.checked)} />{" "}
          <Box sx={{ fontWeight: 500, color: "#E6393E" }}>
            I have downloaded the information Brochure of {course?.coursename},
            read and understood all the instructions there in as well as those
            mentioned above, and the application form for the{" "}
            {course?.coursename} accordingly
          </Box>
        </Box>
        <Box
          mt={3}
          sm={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {" "}
          {MauvelousPrimaryButton({
            title: "Process to Complete Application Form",
            handleClick: handleClick,
            disabled: !check,
          })}{" "}
        </Box>
      </Grid>
    );
  }
  return <PageLoader />;
}
