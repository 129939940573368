import React from "react";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";
import Index from "../components/prerequestPage";
import Layout from "../layouts/layout";
import { NavLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import Cookies from "js-cookie";
import { cssProperties } from "../utils/commonCssProperties";

export default function PreRequestPage() {

  const breadcrumbs = [
    <Link underline="hover" key="1" component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/home`} sx={{
      color: cssProperties?.fontcolor?.primary,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.1px',
  }}>
        Dashboard
    </Link>,
    
    <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/course-selection`} sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.1px',

    }}>
        Course selection
    </Link>,
    <Typography key="3" color="text.primary"
        sx={{
            display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: "bold",
            lineHeight: '20px',
            letterSpacing: '0.1px'
        }}
    >
        Pre-Requisite<MdKeyboardArrowDown size={16} />
    </Typography>,
];



  return (
    <Layout>
      <Header breadcrumbs={breadcrumbs} />
      <Sidebar />
      <Index />
    </Layout>
  );
}
