import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import masterActions from "../../redux/master/action";
import {
  percentageValidation,
  percentageNumberValidation,
} from "../../utils/validations";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../../common/backButton";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cookies from 'js-cookie';

export default function OtherDetailsForm({ preprocessFormData }) {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    control,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange'
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const date = new Date();
  const currentYear = date.getFullYear();
  const [years, setYears] = useState([]);
  const [disability, setDisability] = useState("");
  const [minorityDetails, setMinorityDetails] = useState("");
  const [scribe, setScribe] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false); 


  const handleDisability = (e) => {
    setValue("isdisability", parseInt(e.target.value));
    setDisability(e.target.value);
    trigger("isdisability");
    if(e.target.value === 0){
      setValue('disabilitytypeid', '')
    }
  };

  const handleMinority = (e) => {
    setValue("isminority", parseInt(e.target.value));
    setMinorityDetails(e.target.value);
    trigger("isminority");
    if (e.target.value === 0) {
      setValue('linguisticminorityid', '');
      setValue('religiousminorityid', '');
    }
  };

  const handleScribe = (e) => {
    setValue("isscriberequired", parseInt(e.target.value));
    setScribe(e.target.value);
    trigger("isscriberequired");
    if(e.target.value === 0){
      setValue('isextratimerequired', '')
    }
  };

  const handleBack = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/registration?page=address`);
  };

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);
  const { minority, pwdType, board, state } = useSelector(
    (state) => state.masterReducer
  );

  const [filteredStates, setFilteredStates] = useState(state);

  const watchedBoardId = useWatch({ control, name: "sscboardid" });
  useEffect(() => {
    const selectedBoard = board.find((b) => b.boardid === watchedBoardId);
    if (selectedBoard) {
      const boardStateId = selectedBoard.boardstateid;
      const filteredStates = state.filter(
        (s) => s.boardstateid === boardStateId
      );
      setFilteredStates(filteredStates);
    } else {
      setFilteredStates(state);
      setValue("sscschoolstateid", "");
    }
  }, [watchedBoardId, board, state, setValue]);

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const { otherDetails } = useSelector((state) => state.candidatesReducer);
  const candidateotherdetailsid = otherDetails[0]?.candidateotherdetailsid;
  const candidateotherdetailsdraftid =
    otherDetails[0]?.candidateotherdetailsdraftid;

  useEffect(() => {
    if (otherDetails.length > 0) {
      if(otherDetails[0].status === 2){
        console.log(otherDetails[0].status,'status')
        setDisabled(true)
      }else{
        console.log(otherDetails[0].status,'status false')
        setDisabled(false)
      }
      reset({ ...otherDetails[0] });
    }
    console.log(reset({ ...otherDetails[0] }));
    setValue("linguisticminorityid", otherDetails[0]?.linguisticminorityid);
    setValue("isminority", otherDetails[0]?.isminority);
    setValue("isscriberequired", otherDetails[0]?.isscribe);
  }, [otherDetails]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: masterActions.GET_CATEGORY });
    dispatch({ type: masterActions.GET_CASTE });
    dispatch({ type: masterActions.GET_PWD_TYPE });
    dispatch({ type: masterActions.GET_BOARD });
    dispatch({ type: masterActions.GET_STATE });
    dispatch({ type: masterActions.GET_MINORITY });
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
    }
    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const yearsArray = [];
      for (let i = 2000; i <= currentYear; i++) {
        yearsArray.push(i);
      }
      return yearsArray;
    };
    setYears(generateYears());
  }, [candidateid]);

  const handleDraft = (getValues) => {
    const values = preprocessFormData(getValues());
    let operation = candidateotherdetailsdraftid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_OTHER_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...values,
          status: 1,
          operation: operation,
          candidateid: candidateid,
        },
      },
    });
    dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
  };

  const handleSave = (data) => {
    console.log("data------",data);
    let operation = candidateotherdetailsid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_OTHER_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          status: 2,
          candidateid: candidateid,
          operation: operation,
        },
        navigate: navigate,
      },
    });
    dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
  };

  const handleCancel = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/home`);
  };

  console.log("is mhccccc",watch("ismhFcandidate"))


  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border:'1px solid #0F4F96'

      }}
    >
      <Grid>
        <Typography pb={2} variant="h4" sx={{ textWrap: "wrap", fontSize: 20, fontWeight: 500, color:"#212121" }}>Other Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth  error={!!errors.ismhcandidate}>
              <InputLabel shrink={!!watch("ismhcandidate") || watch("ismhcandidate") === 0} htmlFor="ismhcandidate">
                Are you Domiciled in the state of Maharashtra?
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              disabled={disabled}
                id="ismhcandidate"
                label="Are you Domiciled in the state of Maharashtra?"
                {...register("ismhcandidate", {
                  required: "MH Candidate is required",
                })}
                onChange={(e) => {
                  setValue("ismhcandidate", e.target.value);
                  trigger("ismhcandidate");
                }}
                value={watch("ismhcandidate") ?? ""}
            
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.ismhcandidate && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.ismhcandidate.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth  error={!!errors.isminority}>
              <InputLabel shrink={!!watch("isminority") || watch("isminority") === 0} htmlFor="isminority">
                Minority Details  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                id="isminority"
                disabled={disabled}
                label="Minority Details"
                {...register("isminority", {
                  required: "Minority details is required",
                })}
                onChange={handleMinority}
                value={watch("isminority") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.isminority && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isminority.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              
              error={!!errors.linguisticminorityid}
            >
              <InputLabel shrink={!!watch("linguisticminorityid")} htmlFor="linguisticminorityid">
                Linguistic Minority  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                id="linguisticminorityid"
                label="Linguistic Minority"
                {...register("linguisticminorityid",
                  // {
                  //   required:
                  //     minorityDetails !== 0
                  //       ? "Linguistic minority is required"
                  //       : false,
                  // }
                )}
                disabled={
                  (getValues("isminority") && getValues("isminority") === 1) && !disabled ? false : true
                  // minorityDetails === 0 || getValues("isminority") === "0"
                }
                onChange={(e) => {
                  setValue("linguisticminorityid", e.target.value);
                  trigger("linguisticminorityid");
                }}
                value={watch("linguisticminorityid") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {minorityDetails !== "0" &&
                  minority &&
                  minority.length > 0 &&
                  minority.map(
                    (item) =>
                      item.type === "Linguistic Minority" && (
                        <MenuItem
                          key={item?.minorityid}
                          value={item?.minorityid || ""}
                        >
                          {item?.minority}
                        </MenuItem>
                      )
                  )}
              </Select>
              {errors.linguisticminorityid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.linguisticminorityid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              
              error={!!errors.religiousminorityid}
            >
              <InputLabel shrink={!!watch("religiousminorityid")} htmlFor="religiousminorityid">
                Religious Minority  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                id="religiousminorityid"
                label="Religious Minority"
                {...register("religiousminorityid",
                  // {
                  //   required:
                  //     minorityDetails !== 0
                  //       ? "Religious minority is required"
                  //       : false,
                  // }
                )}
                disabled={
                  (getValues("isminority") && getValues("isminority") === 1) && !disabled ? false : true
                  // minorityDetails === 0 || getValues("isminority") === "0"
                }
                onChange={(e) => {
                  setValue("religiousminorityid", e.target.value);
                  trigger("religiousminorityid");
                }}
                value={watch("religiousminorityid") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {minorityDetails !== "0" &&
                  minority?.map(
                    (item) =>
                      item.type === "Religious Minority" && (
                        <MenuItem
                          key={item?.minorityid}
                          value={item?.minorityid || ""}
                        >
                          {item.minority}
                        </MenuItem>
                      )
                  )}
              </Select>
              {errors.religiousminorityid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.religiousminorityid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth  error={!!errors.isdisability}>
              <InputLabel shrink={!!watch("isdisability") || watch("isdisability") === 0} htmlFor="isdisability">
                Are you person with Disability?
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                id="isdisability"
                disabled={disabled}
                label="Are you person with Disability?"
                {...register("isdisability", {
                  required: "Disability status is required",
                })}
                onChange={handleDisability}
                value={watch("isdisability") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.isdisability && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isdisability.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              
              error={!!errors.disabilitytypeid}
            >
              <InputLabel shrink={!!watch("disabilitytypeid")} htmlFor="disabilitytypeid">Type of Disability?</InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                id="disabilitytypeid"
                label="Type of Disability?"
                {...register("disabilitytypeid")}
                disabled={
                  (getValues("isdisability") && getValues("isdisability") === 1 && !disabled) ? false : true
                  // disability === 0 || getValues("isdisability") === "0"
                }
                onChange={(e) => {
                  setValue("disabilitytypeid", e.target.value);
                  trigger("disabilitytypeid");
                }}
                value={watch("disabilitytypeid") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {disability !== "0" &&
                  pwdType?.map((val, i) => (
                    <MenuItem value={val?.pwdtypeid} key={i}>
                      {val?.pwdtype}
                    </MenuItem>
                  ))}
              </Select>
              {errors.disabilitytypeid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.disabilitytypeid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              
              error={!!errors.isscriberequired}
            >
              <InputLabel shrink={!!watch("isscriberequired") || watch("isscriberequired") === 0} htmlFor="isscriberequired">
                Do You Require Scribe?  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                id="isscriberequired"
                disabled={disabled}
                label="Do You Require Scribe?"
                {...register("isscriberequired", {
                  required: "Require Scribe is required",
                })}
                onChange={handleScribe}
                value={watch("isscriberequired") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.isscriberequired && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isscriberequired.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              
              error={!!errors.isextratimerequired}
            >
              <InputLabel shrink={!!watch("isextratimerequired") || watch("isextratimerequired") === 0} htmlFor="isextratimerequired">
                Do You Require Extra Time?  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                id="isextratimerequired"
                label="Do You Require Extra Time?"
                // {...register("isextratimerequired", {
                //   required:
                //   (scribe !== 0 || getValues("isscriberequired") === "1") && "Require extra time is required",                })}
                disabled={
                  (getValues("isscriberequired") && getValues("isscriberequired") === 1 && !disabled) ? false : true
                  // scribe === 0 || getValues("isscriberequired") === "0"
                }
                onChange={(e) => {
                  setValue("isextratimerequired", e.target.value);
                  trigger("isextratimerequired");
                }}
                value={watch("isextratimerequired") ?? ""}

              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.isextratimerequired && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.isextratimerequired.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Typography pb={2} pt={2} variant="h4" sx={{ textWrap: "wrap", fontSize: 20, fontWeight: 500, color:"#212121"  }}>Education Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth  error={!!errors.sscpassingyear}>
              <InputLabel shrink={!!watch("sscpassingyear")} htmlFor="sscpassingyear">
                SSC Passing Year  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                id="sscpassingyear"
                label="SSC Passing Year"
                disabled={disabled}
                {...register("sscpassingyear", {
                  required: "SSC Passing Year is required",
                })}
                onChange={(e) => {
                  setValue("sscpassingyear", e.target.value);
                  trigger("sscpassingyear");
                }}
                value={watch("sscpassingyear") ?? ""}

              >
                <MenuItem value="">Select One</MenuItem>
                {years.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscpassingyear && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscpassingyear.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
              fullWidth
              disabled={disabled}
              variant="outlined"
              
              type="text"
              label={
                <span>
                  SSC Total percentage
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter the SSC Total percentage"
              {...register("ssctotalpercentage", {
                required: "SSC Total percentage is required",
                validate: percentageValidation,
              })}
              onInput={percentageNumberValidation}
              error={!!errors.ssctotalpercentage}
              helperText={errors.ssctotalpercentage?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("ssctotalpercentage")
              }}
            />
            <Box>
              <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
  <FormControl fullWidth error={!!errors.sscboardid}>
    <InputLabel shrink={!!watch("sscboardid") || watch("sscboardid") === 0} htmlFor="sscboardid">
      SSC Board <span className="error" style={{ color: "#666666" }}> *</span>
    </InputLabel>
    <Select
      inputProps={{ style: { height: '56px', boxSizing: 'border-box' } }}
      disabled={disabled}
      id="sscboardid"
      label="SSC Board"
      {...register("sscboardid", {
        required: "SSC Board is required",
      })}
      onChange={(e) => {
        const id = e.target.value;
        const filterId = board?.find(
          (row) => row.boardid === parseInt(id)
        );
        const finalId = filterId.boardstateid;
        setValue("sscschoolstateid", finalId);
        setValue("sscboardid", id);
        clearErrors("sscboardid"); // Clear the error when a value is selected
      }}
      value={watch("sscboardid") >= 0 ? watch("sscboardid") : ""}
    >
      {board?.map((val, i) => (
        <MenuItem value={val?.boardid} key={i}>
          {val?.board}
        </MenuItem>
      ))}
    </Select>
    {errors.sscboardid && (
      <FormHelperText style={{ margin: 0 }}>
        {errors.sscboardid.message}
      </FormHelperText>
    )}
  </FormControl>
</Grid>

          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              
              error={!!errors.sscschoolstateid}
            >
              <InputLabel shrink={!!watch("sscschoolstateid") || watch("sscschoolstateid") === 0} htmlFor="sscschoolstateid">
                SSC School State  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                disabled={disabled}
                id="sscschoolstateid"
                label="SSC School State"
                {...register("sscschoolstateid", {
                  required: "SSC School State is required",
                })}
                onChange={(e) => {
                  setValue("sscschoolstateid", e.target.value);
                  trigger("sscschoolstateid");
                }}
                value={watch("sscschoolstateid") ?? ""}

              >
                <MenuItem value="">Select One</MenuItem>
                {/* <MenuItem value={0}>Not Applicable</MenuItem> */}
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.sscschoolstateid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.sscschoolstateid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth  error={!!errors.appearingfor}>
              <InputLabel shrink={!!watch("appearingfor") || watch("appearingfor") === 0} htmlFor="appearingfor">
                Are you appearing/applied for 12th HSC Diploma exam in{" "}
                {currentYear}?  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                disabled={disabled}
                id="appearingfor"
                label={
                  <>
                    <span>
                      Are you appearing/applied for 12th HSC Diploma exam in{" "}
                      {currentYear}?
                    </span>
                      <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                  </>
                }
                {...register("appearingfor", {
                  required: "Required",
                })}
                onChange={(e) => {
                  setValue("appearingfor", e.target.value);
                  trigger("appearingfor");
                  if(e.target.value === 0){
                    setValue('hscboardid', '')
                  }
                }}
                value={watch("appearingfor") ?? ""}
              >
                <MenuItem value="">Select One</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
              {errors.appearingfor && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.appearingfor.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth  error={!!errors.hscboardid}>
              <InputLabel shrink={!!watch("hscboardid") || watch("hscboardid") === 0} htmlFor="hscboardid">
                HSC Board  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
              </InputLabel>
              <Select
                inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                id="hscboardid"
                label="HSC Board"
                {...register("hscboardid", {
                  // required: " HSC Board is required",
                })}
                onChange={(e) => {
                  setValue("hscboardid", e.target.value);
                  trigger("hscboardid");
                }}
                value={watch("hscboardid") ?? ""}
                disabled={
                  (getValues("appearingfor") && getValues("appearingfor") === 1 && !disabled) ? false : true
                }
              >
                <MenuItem value="">Select One</MenuItem>
                {board?.map((val, i) => (
                  <MenuItem value={val?.boardid} key={i}>
                    {val?.board}
                  </MenuItem>
                ))}
              </Select>
              {errors.hscboardid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.hscboardid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          pb={2}
          pt={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            <BackButton handleBack={handleBack} />
          </Box>

          <Box sx={{ display: "flex", gap: "8px" }}>
            <BackButton name={"clear"} handleBack={handleCancel} />
            {disabled ? (
              <SaveButton
                name={"Save As Draft"}
                handleDraft={() => setDisabled(false)}
              />
            ) : (
              <SaveButton
                name={draftButtonLoader ? "loader" : "Save Draft"}
                handleDraft={() => handleDraft(getValues)}
              />
            )}
            <SubmitButton
              name={buttonLoader ? "loader" : "Next"}
              handleSubmit={handleSubmit(handleSave)}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
