import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  styled,
  Box,
} from "@mui/material";
import React, { useRef } from "react";
import PreviewForm from "./previewForm";
import GovernmentImage from "../../assets/images/courseRegistration/Government.png";
import CetImage from "../../assets/images/courseRegistration/Cet.png";
import PrintDownloadButton from "../../common/button/printDownloadButton";
import { IoMdDownload } from "react-icons/io";
import { MdPrint } from "react-icons/md";
import { Margin, usePDF } from "react-to-pdf";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";

export default function PrintApplicationForm() {
  const ref = useRef();
  const location = useLocation();
  const courseid = location.state?.courseid;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const style = styled({
    fontStyle: {
      fontSize: isMobile ? 18 : 20,
      fontWeight: 600,
    },
  });
  const { toPDF, targetRef } = usePDF({
    filename: "Application Form.pdf",
    page: { format: "A3", margin: Margin.SMALL },
  });

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });
  const classes = style();

  return (
    <Grid
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid
        container
        pr={3}
        pl={3}
        pt={2}
        sx={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
      >
        <PrintDownloadButton
          name="Download Application Form"
          startIcon={<IoMdDownload />}
          handleSubmit={toPDF}
        />
        <PrintDownloadButton
          name="Print Application Form"
          startIcon={<MdPrint />}
          handleSubmit={handlePrint}
        />
      </Grid>
      <Grid>
        <Grid
          container
          p={3}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Grid item md={3}>
            <img
              style={{ height: "141px", width: "auto" }}
              src={CetImage}
              alt="image"
            />
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={classes.fontStyle}>
              Government of Maharashtra
            </Typography>
            <Typography sx={classes.fontStyle}>
              State Common Entrance Test Cell, Mumbai
            </Typography>
            <Typography sx={classes.fontStyle}>
              MAH - LL.B. 3 Yrs .CET - 2024 Online Examination
            </Typography>
            <Typography sx={classes.fontStyle}>APPLICATION FORM</Typography>
          </Grid>
          <Grid
            item
            md={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ height: "143px", width: "auto" }}
              src={GovernmentImage}
              alt="image"
            />
          </Grid>
        </Grid>
        <Box>
          <PreviewForm courseId={courseid} targetRef={targetRef}/>
        </Box>
      </Grid>
    </Grid>
  );
}
