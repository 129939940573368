import { Box, Grid, Link, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import barCode from '../../assets/images/barcode.png'
import cetIcon from '../../assets/images/courseRegistration/Cet.png'
import emblem from '../../assets/images/courseRegistration/Government.png'
import PrintDownloadButton from '../../common/button/printDownloadButton'
import { RiDownloadLine } from 'react-icons/ri'
import { SlPrinter } from "react-icons/sl";
import { useReactToPrint } from 'react-to-print'
import { Margin, usePDF } from 'react-to-pdf'

export default function Index() {
  const { toPDF, targetRef } = usePDF({ filename: 'Admit Card.pdf',page:{format:'A3',margin:Margin.SMALL} });

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  })
  return (
    <Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box > {PrintDownloadButton({ name: "Download Admit Card", startIcon: <RiDownloadLine />, handleSubmit: toPDF })}</Box>
          <Box > {PrintDownloadButton({
            name: "Print Admit Card", startIcon: <SlPrinter />,handleSubmit:handlePrint
          })}</Box>
        </Box>
      </Box>
      <Grid container >
        <Grid item xs={12} sm={12} md={12} lg={12} px={2} py={2} >
          <Grid container >
            <Grid item xs={4} sm={4} md={4} lg={4} textAlign={"left"}> <span style={{ fontWeight: "600" }}>Venue: </span>{"venue"}</Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} textAlign={"center"}><span style={{ fontWeight: "600" }}>Date</span>:{"date"}</Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} textAlign={"right"}><span style={{ fontWeight: "600" }}>Time</span>:{"time"}</Grid>
          </Grid>
        </Grid>
        <div ref={targetRef}>
          <Grid className='admit_card' item xs={12} sm={12} md={12} lg={12} p={2} sx={{ backgroundColor: "#FFFFFF", border: "1px solid rgba(213, 213, 213, 1)", borderRadius: "8px", minHeight: "50vh", widht: "100vw", wordBreak: "break-word", position: "relative" }}>
          <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                  <Grid item xs={3} sm={3} md={3} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                    <Box>
                    <img style={{width:"100%"}} src={cetIcon} /> 
                    </Box>
                      
                    </Grid>
                  
                  <Grid item xs={6} sm={6} md={6} lg={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontWeight: 600 }}>
                    <Box item>Government of Maharashtra</Box>
                    <Box mt={2}>State Common Entrance Test Cell, Mumbai</Box>
                    <Box mt={2}>MAH - LL.B 3yrs CET - 2024 Online Examination </Box>
                    <Box mt={2}>ADMIT CARD</Box>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Box>
                    <img style={{width:"100%"}} src={emblem} /> 
                    </Box>
                    </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={4} border={"1px solid #BDBDBD"}>
                <Grid container>
                  <Grid item xs={10} sm={10} md={10} lg={10} >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} >
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Candidate's Full Name</Grid>
                          <Grid item px={1} py={1} xs={9} sm={9} md={9} lg={9} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> MILIND PATEL</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} >
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Roll Number/UserName</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 2487462643</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>DOB/Password</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 03/06/2001</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Date of CET Examination</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 03/06/2024</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Examination Time</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 02:00 PM to 4:00 PM</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Reporting Time at the CET Examination Centre</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 12.30 PM</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Gate Closure Time</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 01.30 PM</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Question Paper Language</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> English/Marathi</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Name of the course</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> MAH - LL.B 3yrs</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Gender</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> Male</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Application Number</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 248600001</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>{"Person with Disability(PWD)"}</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> No</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Disability Type</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>{"Extra Time Required"}</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> </Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Scribe</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>{"Venue Code"}</Grid>
                          <Grid item px={1} py={1} xs={9} sm={9} md={9} lg={9} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}>NAGP02</Grid>
                        </Grid>
                      </Grid>
                      <Grid item px={1} py={1} xs={12} sm={12} md={12} lg={12} fontWeight={"600"} bgcolor={'#BDBDBD'}>
                        <Box>CET Exam Centre Address</Box>
                      </Grid>
                      <Grid item px={1} pt={1} pb={7} xs={12} sm={12} md={12} lg={12} fontWeight={"600"} border={"1px solid #BDBDBD"}>
                        <Box py={1}>MODERN COLLEGE</Box>
                        <Box py={1}>MIDC,T-POINT WAADI,AMRAWATI ROAD,OPP RAHUL HOTEL,WAADI,OPP RAHUL HOTEL,NAGPUR 440023</Box>
                      </Grid>
                      <Grid item px={1} py={1} xs={12} sm={12} md={12} lg={12} fontWeight={"600"} border={"1px solid #BDBDBD"} >
                        <Box py={1}>Please bring this hall ticket with  currently valid photo identity proof in original- THIS IS ESSENTIAL. Currently valid
                          Original photo identity proof may be PAN Card/Permanent Driving Licencse/Voter’s Card/Bank Passbook  with
                          photograph/photo Identity proof issued by a Gazetted Officer on official letterhead/Photo Identity proof issued by a
                          People’s Representative on official letterhead/Recent Identity Card issued by a recognized College/University/Aadhaar
                          Card and Learner’s Driving License and soft copy of teh Photo ID card will NOT be accepted as valid ID proof for this CET.</Box>
                      </Grid>

                    </Grid>

                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} flexDirection={"column"} gap={3} justifyContent={"center"} alignItems={"center"} py={4}  >
                        <img style={{ width: "135px", height: "154px" }} src='https://s3-alpha-sig.figma.com/img/859a/0f15/6e996856943efa70e862de79043e99e6?Expires=1719187200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=afveg04l2rB8Cy1Boe28UjjzJjK2noJKA4anekT0J~7scbY9oWYQfWUpXVfsCB97CDaTzFaXbJ2RQYeCtJaPJG42DQTta3UHlqo-9uUd2qq-2IL4Rs1Wf4JsIiScVsjFIBENcqokyPS-D2J8dcaUMnsZqkG6sCf24ziHtcLwtVBzZtZ44bTJhTJ~xuduC8KGs-K~7JiKbwKx5ZZC~~Pn~iLUjLn~Jj5yUJ1DS-W36WCE8J3SIo8OoABSg9U~JlCR8H5BfhlY5V4OzNp4GrWzOHDDzi9uaFMk-KdyigWtUExwafmHH2EiqdTDd2HQUrzTVGWDk0lDhomPuURUg-TFCA__' />
                        <Box sx={{ width: "135px", height: "43px" }} border={"1px solid #BDBDBD"}>
                        </Box>
                        <Box sx={{ width: "135px", height: "170px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600 }} px={3} border={"5px double #BDBDBD"}>
                          <Box py={3}>
                            PLEASE AFFIX YOUR
                            RECENT COLOR
                            PHOTO & SIGN
                            ACROSS IT
                          </Box>
                        </Box>
                      </Grid>

                    </Grid>
                  </Grid>


                  <Grid container>

                    <Grid item xs={12} sm={12} md={12} lg={12} border={"1px solid #BDBDBD"}>
                      <Grid container>
                        <Grid item pt={2} sx={{ width: "80%", height: "161px", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center" }} xs={6} sm={6} md={6} lg={6} >

                          <Box sx={{ height: "80px", width: "80%", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center" }}>

                            <Box sx={{ pt: 2, fontWeight: 600 }}>
                              Candidate’s Signature
                            </Box>
                          </Box>


                        </Grid>

                        <Grid item pt={2} sx={{ width: "80%", height: "161px", border: "1px solid #BDBDBD", display: "flex", flexDirection: "column", alignItems: "center" }} xs={6} sm={6} md={6} lg={6} >

                          <Box sx={{ height: "80px", width: "80%", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center" }}>

                            <Box sx={{ pt: 2, fontWeight: 600 }}>
                              Invigilator’s Signature
                            </Box>
                          </Box>
                          <Box sx={{ pt: 2, fontWeight: 600 }}>
                            Invigilator  should verify Candidate and then sign here
                          </Box>
                        </Grid>


                      </Grid>


                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} border={"1px solid #BDBDBD"}>

                      <Box sx={{ height: "161px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                     <Box>
                        <img style={{width:"100%"}} src={barCode} />
                      </Box> 
                        <Box sx={{ fontWeight: "600", mt: "5px" }}>24150703483</Box>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: "161px", border: "1px solid #BDBDBD", display: "flex", flexDirection: "column", alignItems: "center" }} xs={12} sm={12} md={12} lg={12} >

                      <Box sx={{ height: "80px", width: "100%", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "600" }}>

                        <Link>Click here to download scribe declaration fornm for PWD Candidates only</Link>
                      </Box>
                      <Box sx={{ height: "80px", width: "100%", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "600" }}>

                        <Link>Click here to download the undertaking for correction in Name/Photo/Signature/DOB/Gender</Link>
                      </Box>
                      <Box sx={{ height: "80px", width: "100%", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "600", color: "#212121" }}>

                        Please turn to next page for instruction
                      </Box>

                    </Grid>

                  </Grid>
                </Grid>


              </Grid>
            </Grid>
          </Grid>
          <Grid className='admit_card' item xs={12} sm={12} md={12} lg={12} p={2} mt={4} sx={{ backgroundColor: "#FFFFFF", border: "1px solid rgba(213, 213, 213, 1)", borderRadius: "8px", minHeight: "50vh", widht: "100vw", wordBreak: "break-word" }}>
            <Grid container>

              <Grid item xs={12} sm={12} md={12} lg={12} py={2} fontWeight={600} display={"flex"} justifyContent={"center"} alignItems={"center"} border={"1px solid #BDBDBD"}>

                INSTRUCTION
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} py={2} fontWeight={600} display={"flex"} justifyContent={"center"} alignItems={"center"} border={"1px solid #BDBDBD"}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textWrap: "wrap", px: 6 }}>
                    <ol style={{ display: "flex", flexDirection: "column", gap: 9 }}>
                      <li>
                        Candidate should reach the CET Examination Centre before the Gate Closure Time mentioned on the Admit Card. Candidate will not be allowed to  enter the CET
                        examination Centre after the Gate Closure Time.
                      </li>
                      <li>
                        You will be required to enter the User name (Roll Number) & Password (DOB in Format DDMMYYYY), as mentioned in the Admit Card, to enter the On- Line exam site.  Please
                        ensure that your Name and other details which will subsequently appear on the screen are correct. You must maintain secrecy of the
                        username & password.  The examination will be conducted online.
                      </li>
                      <li>
                        You are required to bring this Hall Ticket with currently valid photo identity proof in original. Please note that your name as appearing on the  Hall Ticket (provided
                        by you during the process of registration) should reasonably match with the name as appearing on the photo identity proof.  Female candidates who have changed
                        first/last/middle name post marriage must take special note of this. If there is any mismatch between the name indicated in the Hall Ticket and Photo Identity Proof,
                        you will not be allowed to appear for the exam. In case of candidates who have changedtheir name will be allowed only if they produce Gazette
                        notification/their marriage certificate/affidavit.
                      </li>
                      <li>
                        Candidates opted for Scribe shall bring the duly filled prescribed pro-forma & disability certificate. Candidates opted for extra time (including Learning Disability) shall
                        produce disability certificate issued by the appropriate authority in original for verification to the invigilator.
                      </li>
                      <li>
                        Brochure giving information/instructions about the nature of examination is available on the website of State CET CELL. Please download the Brochure and
                        study it  carefully.
                      </li>
                      <li>
                        You must bring a ball point pen with you. You will have to sign the attendance sheet in the presence of the Invigilator.
                      </li>
                      <li>
                        The mere fact that the Hall Ticket has been issued to you does not imply that your candidature has been finally cleared by State CET Cell or that the entries made by  you    in
                        your application for examination have been accepted by State CET Cell as true and correct. It may be noted that State CET Cell will take up verification of eligible
                        candidates, i.e., educational qualifications and category with reference to original documents, only at the time of document verification of candidates who qualify on
                        the basis of result of the online examination. You may therefore please note that if at any stage you  do not satisfy the eligibility criteria as given in the notification or  the
                        information furnished by you in the application is incorrect, your candidature is  liable to be cancelled forth-with with such further actions as State CET Cell may like  to take.
                      </li>
                      <li>
                        Your responses (answers) will be analyzed with other candidates to detect patterns of similarity of right and wrong answers. If in the analytical procedure adopted  in
                        this regard, it is inferred/concluded that the responses have been shared and scores obtained are not genuine/valid, your candidature may be cancelled and/or  the
                        result withheld.
                      </li>
                      <li>
                        You must report at the examination venue 30 minutes before the time as printed on this Hall Ticket. Candidates arriving late will not be permitted to appear for
                        the  on-line examination.
                      </li>
                      <li>
                        Use of books, notebooks, calculators, watch calculators, pagers, mobile phones etc. is not permitted in this examination. Candidates are advised not to bring any
                        of  the banned items including mobile phones/pagers to the venue of examination as safety arrangement cannot be assured.Any candidate found resorting to any
                        unfair means or malpractice or any misconduct while appearing for the examination including giving/receiving help to/from any candidate during the examination
                        will be disqualified. The candidate should be vigilant to ensure that no other  candidate is able to copy from his/her answers.
                      </li>
                      <li>
                        Any request for change of date/session/Centre/venue will not be entertained.
                      </li>
                      <li>
                        The possibility of occurrence of some problem in the administration of the examination cannot be ruled out completely which may impact test  delivery and/or result  from
                        being generated. In that event, every effort will be made to rectify such problem, which may include movement of  candidates, delay in test. Conduct of a re-exam is at
                        the absolute discretion of test conducting body. Candidates will not have any claim for are- test. Candidates not willing to move or not willing to participate in the
                        delayed process of test delivery shall be summarily rejected from the process.
                      </li>
                      <li>
                        More than one session are required if the nodes capacity is less or some technical disruption takes place at any Centre or for any candidate.
                      </li>
                      <li>
                        Instances for providing incorrect information and/or process violation by a candidate detected at any stage of the admission process will lead to disqualification of the
                        candidate from the admission process and he/she will not be allowed to appear in admission process of the State CET Cell in the future. If such instances go undetected
                        during the current process but are detected subsequently, such disqualification will take place with retrospective effect.
                      </li>
                      <li>
                        Anyone found to be disclosing, publishing reproducing, transmitting, storing or facilitating transmission and storage of test contents in any form or any information
                        therein in whole or part thereof or by any means verbal or written, electronic or mechanical or taking away the papers supplied in the examination hall or found to be  in
                        unauthorized possession of test content is likely to be prosecuted.
                      </li>
                    </ol>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} px={4} mt={2} fontWeight={600}>
                    <Box>For any issue, please contact helpdesk no.<span style={{ color: "#E6393E" }}>  07969134401, 07969134402 </span></Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} px={4} mt={2} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={1}>
                    <Box>STATE CET CELL </Box>
                    <Box> 8th Floor, New Excelsior Building, A. K. Nayak Marg, Fort, Mumbai- 400 001 </Box>
                    <Box>  ******************************************************  </Box>
                  </Grid>
                </Grid>

              </Grid>

            </Grid>

            <Grid item py={1} xs={12} sm={12} md={12} lg={12} display="flex" justifyContent={"space-between"} alignItems={"center"} mt={1}>
              <Box>Application Number:<span style={{ fontWeight: "600" }}> 248500001 </span></Box>
              <Box>IP Address: <span style={{ fontWeight: "600" }}>45.64.204.206 </span></Box>
              <Box>Date of Downloading: <span style={{ fontWeight: "600" }}> 15/03/2024 01:03:13 </span></Box>
            </Grid>
          </Grid>
        </div>

      </Grid>
    </Grid>
  )
}
