import React from "react";
import Index from "../components/newPasswordMail/index";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";

export default function NewPasswordMail() {
  return (
    <Layout>
      <Header />
      <Sidebar />
      <Index />
    </Layout>
  );
}
