import React from 'react'
import Layout from '../layouts/layout'
import Header from '../layouts/header'
import Sidebar from '../layouts/sideBar'
import Index from '../components/objectionDetails'
import { Link, Typography } from '@mui/material'
import Cookies from "js-cookie";
import { NavLink } from 'react-router-dom'
import { cssProperties } from '../utils/commonCssProperties'
import { MdKeyboardArrowDown } from 'react-icons/md'

const ObjectionDetails = () => {
    const breadcrumbs = [
        <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/home`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
        }}>
            Dashboard
        </Link>,
    
        <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/answer-key?page=tablelist`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
    
        }}>
            Answer Key
        </Link>,
        <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/answer-key?page=objectiontracker`} sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            color:cssProperties?.fontcolor?.primary
        }}>
            Objection Tracker
        </Link>,
    
        <Link underline="hover" key="1" style={{textDecoration:"none"}} component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/answer-key?page=raisedobjection`} sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            color:cssProperties?.fontcolor?.primary
        }}>
            Raised Objection
        </Link>,
    
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: "bold",
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            Objection Details<MdKeyboardArrowDown size={16} />
        </Typography>,
    ];
    return (
    <>
       <Layout>
                <Header breadcrumbs={breadcrumbs}/>
                <Sidebar />
                <Index />
            </Layout>   

    </>
  )
}

export default ObjectionDetails
