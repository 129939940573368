import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { cssProperties } from "../utils/commonCssProperties";

export default function BeforeLoginInformation() {
  return (
    <>
      <Grid p={3} sx={{ minHeight: "568px", maxwidth: "800px",background:`${cssProperties?.backgroundcolor?.secondary}`,border:`1px solid ${cssProperties?.bordercolor?.secondary}`,borderRadius:"8px"}}>
          <Typography pt={1} pb={2} sx={{fontSize:"20px",color:`${cssProperties?.color?.tertiary}`, fontWeight:"bold"}}>Helpdesk Information:</Typography>
          <Typography pt={1} sx={{fontSize:"14px",color:`${cssProperties?.color?.tertiary}`, fontWeight:400, lineHeight:"30px"}}>1.Candidates have to register and complete 
            their candidate profile first to raise a ticket 
            through the Helpdesk Ticketing System.</Typography>
            <Typography pt={1} sx={{fontSize:"14px",color:`${cssProperties?.color?.tertiary}`, fontWeight:400, lineHeight:"30px"}}>2.For the candidates, who are facing any 
            issues in registration and are unable to 
            create their profile, please contact the below helpdesk
            numbers:</Typography>
            <ul style={{paddingTop:"8px"}}>
              <li style={{textDecoration:"underline",cursor:"pointer", lineHeight:"30px"}}> 07969134401</li>
              <li style={{textDecoration:"underline",cursor:"pointer", lineHeight:"30px"}}> 07969134402</li>
              <li style={{textDecoration:"underline",cursor:"pointer", lineHeight:"30px"}}> 18002090191</li>
            </ul>

            <Typography pt={2} sx={{fontSize:"14px",color:`${cssProperties?.color?.tertiary}`, fontWeight:400, lineHeight:"16px"}}><span style={{fontWeight:"bold"}}>Helpdesk Timings:</span> 09:00 am to 06:00 pm (All 
              Days except Public Holidays)</Typography>
             
            <Box pt={2}>
              <Typography sx={{fontSize:"14px",color:`${cssProperties?.color?.tertiary}`, fontWeight:400, lineHeight:"30px"}}>Raise a Ticket - <span style={{fontSize:"14px",color:`${cssProperties?.color?.primary}`, fontWeight:"bold"}}>User Manual | Tutorial Video</span></Typography>
            </Box>
      </Grid>
    </>
  );
}
