import { Box, Typography } from '@mui/material'
import React from 'react'
import MauvelousPrimaryButton from '../../common/button/mauvelousPrimaryButton'

export default function Index() {
    return (
        <Box p={2} sx={{ backgroundColor: "#ffffff", borderRadius: "8px",display:"flex",flexDirection:"column",gap:"14px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant='h5' sx={{ fontWeight: 600 }}>Instructions</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, fontWeight: 500 }}>
                    <Box color={"#06C270"}>Registered successfully for MAH-B.BCA/BBA/BBM-CET Academic Year 2024-2025</Box>
                    <Box sx={{ border: "2px solid #F7F7F7", borderRadius: "8px",color:"#E6393E", padding: "5px", width: "fit-content" }}>Application No: 2433232344</Box>
                </Box>
                <Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Typography variant='h5' fontWeight={600}>Import Instructions:</Typography>
                    <Box sx={{ color: "red",
              backgroundColor: "#FCDEDC",
              border: "1px solid #F0A8B2",
              minHeight: "5rem",
              borderRadius: "8px",
              fontSize: "16px",
              padding: "16px",}} >
                        <ol style={{ display: "flex", flexDirection: "column", gap: "14px", padding: "20px" }}>
                            <li>Candidate is advised not to disclose or share their password with anybody. CET Cell will not be responsible for violation or misuse of the password of a candidate.</li>

                            <li>Candidate should remember to log out at the end of their session so that the particulars of the candidate cannot be tampered or modified by unauthorized persons.</li>

                            <li>Application No. has been sent to Candidate's Registered Mobile Number.</li>
                        </ol>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center",marginTop:"24px" }}>
                {MauvelousPrimaryButton({ title: "Print Application Form" })}
            </Box>
        </Box>
    )
}
