import React, { useState } from "react";
import Cropper from "react-easy-crop";
import {
  Modal,
  Box,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";

export default function ImageCropSignature({
  showModal,
  handleClose,
  image,
  onCropDone,
  onCropCancel,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectratio, setAspectRatio] = useState(4 / 3);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onAspectRatioChange = (event) => {
    setAspectRatio(parseFloat(event.target.value));
  };

  return (
    <Modal
      open={showModal}
      // onClose={handleClose}
      aria-labelledby="crop-image-signature-modal-title"
      aria-describedby="crop-image-signature-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="crop-image-signature-modal-title"
          variant="h6"
          component="h2"
        >
          Crop Image Signature
        </Typography>
        <Box sx={{ height: "50vh", position: "relative", mt: 2 }}>
          <Cropper
            image={image}
            aspect={aspectratio}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            style={{
              containerStyle: {
                height: "100%",
                width: "100%",
                backgroundColor: "#fff",
              },
            }}
          />
        </Box>
        <RadioGroup
          row
          aria-label="aspect-ratio"
          name="aspect-ratio"
          value={aspectratio}
          onChange={onAspectRatioChange}
          sx={{ mt: 2, display: "flex", justifyContent: "center" }}
        >
          <FormControlLabel value={1 / 1} control={<Radio />} label="1:1" />
          <FormControlLabel value={5 / 4} control={<Radio />} label="5:4" />
          <FormControlLabel value={4 / 3} control={<Radio />} label="4:3" />
          <FormControlLabel value={3 / 2} control={<Radio />} label="3:2" />
          <FormControlLabel value={5 / 3} control={<Radio />} label="5:3" />
          <FormControlLabel value={16 / 9} control={<Radio />} label="16:9" />
          <FormControlLabel value={3 / 1} control={<Radio />} label="3:1" />
        </RadioGroup>
        <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
          <Grid item>
            <SaveButton name="cancel" handleDraft={onCropCancel} />
          </Grid>
          <Grid item>
            <SubmitButton
              name="Crop & Apply"
              handleSubmit={() => {
                onCropDone(croppedArea);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}