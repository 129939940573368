import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import Tabs from "./tabs";
import PersonalDetailsForm from "./personalDetailsForm";
import AddressForm from "./addressForm";
import ExamCenterForm from "./examCenterForm";
import PreviewForm from "./previewForm";
import PayApplicationFeeForm from "./payApplicationFeeForm";
import PrintApplicationForm from "./printApplicationForm";
import AcademicDetailsForm from "./academicDetailsForm";
import ReservationDetailsForm from "./reservationDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import authActions from "../../redux/auth/actions";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const { courseTabs } = useSelector((state) => state.candidatesReducer);
  console.log(courseTabs,'tbs')
  const initialTab = courseTabs[0]?.tablabel?.toLowerCase().replaceAll(" ", "");
  const [activeTab, setActiveTab] = useState(0);
  const [nextPageName, setNextPageName] = useState("");
  const [previousPageName, setPreviousPageName] = useState("");
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const [courseTab, setCourseTab] = useState(`${initialTab}`);
  const candidateid = useSelector(
    (state) => state.authReducer?.candidateTokenDetails?.candidateid
  );
  const { courseActiveTab } = useSelector((state) => state.candidatesReducer);
  console.log(courseActiveTab,'activetab');
  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_TAB,
        payload: {
          data: {
            courseid: courseid,
          },
        },
      });
    dispatch({
      type: candidatesActions.GET_COURSE_ACTIVE_TAB,
      payload: {
        data: {
          courseid: courseid,
        },
      },
    });
  }
  }, [candidateid]);

  // useEffect(()=>{
  //   dispatch({ type:authActions.CANDIDATE_TOKEN_VERIFY});
  // },[])

  useEffect(() => {
    const tabMapping = courseTabs.reduce((acc, tab, index) => {
      const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
      acc[pageName] = index;
      return acc;
    }, {});
    tabMapping["preview"] = courseTabs.length;
    tabMapping["applicationfee"] = courseTabs.length + 1;
    tabMapping["printapplication"] = courseTabs.length + 2;
    const currentActiveTab = tabMapping[page] ?? null;
    if (currentActiveTab !== null) {
      setActiveTab(currentActiveTab);
    }
    setCourseTab(page);
    const reverseMapping = Object.fromEntries(
      Object.entries(tabMapping).map(([key, value]) => [value, key])
    );
    const nextPageIndex = (currentActiveTab + 1) % (courseTabs.length + 3);
    const nextPageName = reverseMapping[nextPageIndex];
    setNextPageName(nextPageName);

    const previousPageIndex =
      (currentActiveTab - 1 + (courseTabs.length + 3)) %
      (courseTabs.length + 3);
    const previousPageName = reverseMapping[previousPageIndex];
    setPreviousPageName(previousPageName);

    console.log("previous page name:", previousPageName);
  }, [page, courseTabs,courseActiveTab]);

  useEffect(()=>{
    console.log('courseActiveTab---',courseActiveTab);
    if(parseInt(courseActiveTab?.ispaid) === 1){
      navigate(
        `/${Cookies.get(
          "mhet_cnd_project"
        )}/course-registration?page=printapplication`,
        {state:{courseid:courseid}}
      );
    }
    else if (courseActiveTab?.exampreference_status === 1) {
      navigate(
        `/${Cookies.get(
          "mhet_cnd_project"
        )}/course-registration?page=preview`,
        {state:{courseid:courseid}}
      );
    } else if (courseActiveTab?.academic_status === 1) {
      navigate(
        `/${Cookies.get(
          "mhet_cnd_project"
        )}/course-registration?page=examinationdetails`,
        {state:{courseid:courseid}}
      );
    } else if (courseActiveTab?.reservation_status === 1) {
      navigate(
        `/${Cookies.get(
          "mhet_cnd_project"
        )}/course-registration?page=academicdetails`,
        {state:{courseid:courseid}}
      );
    }else if (courseActiveTab?.address_status === 1) {
      navigate(
        `/${Cookies.get(
          "mhet_cnd_project"
        )}/course-registration?page=domicileandcategorydetails`,
        {state:{courseid:courseid}}
      );
    }else if (courseActiveTab?.personalinformation_status === 1) {
      navigate(
        `/${Cookies.get(
          "mhet_cnd_project"
        )}/course-registration?page=address`,
        {state:{courseid:courseid}}
      );
    }
  },[courseActiveTab])

  const courseRegistrationData = courseTabs.map((tab) => {
    const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
    return {
      title: tab.tablabel,
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${pageName}`,
    };
  });

  courseRegistrationData.push(
    {
      title: "Preview & Validate Information",
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=preview`,
    },
    {
      title: "Pay Application Fee",
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=applicationfee`,
    },
    {
      title: "Print Application Form",
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=printapplication`,
    }
  );

  return (
    <>
      <Tabs
        activeTab={activeTab}
        courseRegistrationData={courseRegistrationData}
      />
      {courseTabs?.find((obj) => obj.tablabel === "Personal Information") && (
        <>
          {courseTab === "personalinformation" && (
            <PersonalDetailsForm
              nextPageName={nextPageName}
              previousPageName={previousPageName}
            />
          )}
        </>
      )}

      {courseTabs?.find((obj) => obj.tablabel === "Address") && (
        <>
          {courseTab === "address" && (
            <AddressForm
              nextPageName={nextPageName}
              previousPageName={previousPageName}
            />
          )}
        </>
      )}

      {courseTabs?.find((obj) => obj.tablabel === "Qualification Details") && (
        <>
          {courseTab === "qualificationdetails" && (
            <AcademicDetailsForm
              nextPageName={nextPageName}
              previousPageName={previousPageName}
            />
          )}
        </>
      )}

      {courseTabs?.find((obj) => obj.tablabel === "Domicile and Category Details") && (
        <>
          {courseTab === "domicileandcategorydetails" && (
            <ReservationDetailsForm
              nextPageName={nextPageName}
              previousPageName={previousPageName}
            />
          )}
        </>
      )}

      {courseTabs?.find((obj) => obj.tablabel === "Examination Details") && (
        <>
          {courseTab === "examinationdetails" && (
            <ExamCenterForm
              nextPageName={nextPageName}
              previousPageName={previousPageName}
            />
          )}
        </>
      )}

      {courseTab === "preview" && <PreviewForm />}
      {courseTab === "applicationfee" && <PayApplicationFeeForm />}
      {courseTab === "printapplication" && <PrintApplicationForm />}
    </>
  );
}
