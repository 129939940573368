import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import PrimaryButton from "../primaryButton";

export default function MobileVerificationStatus() {
  const navigate = useNavigate();
  return (
    <Grid sx={{height:"100vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
      <Box p={2} m={2} sx={{display:"flex",alignItems:"center",justifyContent:"center",rowGap:"8px",flexDirection:"column",backgroundColor:"#fff",borderRadius:"8px"}}>
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
          <IoCheckmarkCircleOutline size={70} color="#06C270" />
        </Box>
        <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",rowGap:"8px"}}>
        <Typography variant="h6"  sx={{fontSize:'20px',fontWeight:500,color:'#212121', mt: 2, textAlign: "center" }}>Your mobile number has been verified successfully !</Typography>
          <PrimaryButton
            title="Done"
            onClick={() => {
              Cookies.remove("mhet_cnd_temptoken");
              Cookies.remove("mhet_cnd_tempdata");
              Cookies.remove("mhet_cnd_email_verified");
              Cookies.remove("mhet_cnd_mobileno_verified");
              Cookies.remove("mhet_cnd_temptoken");
              navigate(`/${Cookies.get("mhet_cnd_project")}/login`);
              window.location.reload();
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
}
