import React, { useEffect, useState } from "react";
import {
  // Box,
  // Modal,
  // Typography,
  // Button,
  // Stack,
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent
} from "@mui/material";
import b64toBlob from "../../services/base64ToBlob";
import SaveButton from "../../common/saveButton";
import { MdClose } from "react-icons/md";

export default function ViewImageModal(props) {
  const { showModal, handleClose, document, documentname } = props;

  const [fileUrl, setFileUrl] = useState();


  useEffect(() => {
    if ((/(?:\.([^.]+))?$/).exec(documentname)[0] === ".pdf" && document) {
      const blob = b64toBlob(document, "application/pdf");
      const url = URL.createObjectURL(blob);
      setFileUrl(url);
    }
  }, [document, documentname]);

  return (
    <Dialog
      open={showModal}
      // onClose={handleClose}
      // fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        View
        <br />
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {(/(?:\.([^.]+))?$/).exec(documentname)[0] === ".pdf" ? (
          <iframe
            src={fileUrl}
            width="100%"
            height="450px"
            title="PDF Viewer"
            style={{ border: "1px solid rgba(0, 0, 0, 0.3)" }}
          />
        ) : (
          <img
            src={document}
            alt=""
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <SaveButton handleDraft={handleClose} name={'Close'} />
      </DialogActions>
    </Dialog>
    // <Modal
    //   open={showModal}
    //   onClose={handleClose}
    // >
    //   <Box
    //     sx={{
    //       position: "absolute",
    //       top: "50%",
    //       left: "50%",
    //       transform: "translate(-50%, -50%)",
    //       bgcolor: "background.paper",
    //       boxShadow: "none",
    //       borderRadius:"8px",
    //       p: 2,
    //       minWidth:"50%",
    //       minHeight: "30rem",
    //       overflow: "auto",
    //       display:"flex",
    //       flexDirection:"column",
    //       justifyContent:"space-between"
    //     }}
    //   >
    //     <Box>
    //     <Typography variant="h6" component="h2">
    //         View
    //       </Typography>
    // {(/(?:\.([^.]+))?$/).exec(documentname)[0] === ".pdf" ? (
    //   <iframe
    //     src={fileUrl}
    //     width="100%"
    //     height="450px"
    //     title="PDF Viewer"
    //     style={{ border: "1px solid rgba(0, 0, 0, 0.3)" }}
    //   />
    // ) : (
    //   <img
    //     src={document}
    //     alt=""
    //     style={{ maxWidth: "100%", maxHeight: "100%" }}
    //   />
    // )}
    //     </Box>
    //     <Box pt={1} sx={{display:"flex",justifyContent:"flex-end"}}>
    //       <SaveButton handleDraft={handleClose} name={'Close'} />
    //     </Box>
    //   </Box>

    // </Modal>
  );
}