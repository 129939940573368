import "./App.css";
import { BrowserRouter } from "react-router-dom";
import ToastMessage from "./common/toastMessage";
import AppRoutes from "./routes/appRoutes";
import PageLoader from "./common/pageLoader";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastMessage />
        <PageLoader />
        <AppRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
