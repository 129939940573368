import React, { useEffect } from "react";
import {
  FormControl,
  Grid,
  useMediaQuery,
  MenuItem,
  Select,
  Typography,
  useTheme,
  Box,
  Chip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import masterActions from "../../redux/master/action";
import SubmitButton from "../../common/submitButton";
import { useLocation, useNavigate } from "react-router-dom";
import candidatesActions from "../../redux/candidates/actions";
import { masterFieldLabel, isFieldDisabled } from "../../utils/courseValues";
import BackButton from "../../common/backButton";
import Cookies from "js-cookie";

export default function ExamCenterForm({ nextPageName, previousPageName }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { state, district } = useSelector((state) => state.masterReducer);
  const { masterFields } = useSelector((state) => state.candidatesReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );


  console.log('state, district-----',state, district);
  const { courseFullRegistrationDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  console.log(courseFullRegistrationDetails);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: {
          courseid: courseid,
        },
      });
    }
  }, [candidateid]);

  useEffect(()=>{
    setTimeout(()=>{
      dispatch({ type:masterActions.GET_STATE})
      dispatch({
        type: masterActions.GET_DISTRICT,
        payload: getValues("stateid"),
      });
    },2000)
  },[])

  useEffect(() => {
      if (courseFullRegistrationDetails?.length > 0) {
          reset({ ...courseFullRegistrationDetails[0] });
          setValue("preference1districtid",courseFullRegistrationDetails[0]?.preference1districtid);
          console.log('stateid---------',getValues("stateid"),'....');
        }
      // setValue(courseFullRegistrationDetails[0]?.preference2districtid, "preference2districtid");
      // setValue(courseFullRegistrationDetails[0]?.preference3districtid, "preference3districtid");
      // setValue(courseFullRegistrationDetails[0]?.preference4districtid, "preference4districtid");
  }, [courseFullRegistrationDetails]);
  console.log(courseFullRegistrationDetails);

  const handleProcced = (data) => {
    console.log(data,'data....');
    const corcandidatepreferenceid =
      courseFullRegistrationDetails[0]?.corcandidatepreferenceid;
    dispatch({
      type: candidatesActions.COURSE_EXAM_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidatepreferenceid
            ? parseInt(corcandidatepreferenceid)
            : 0,
          candidateid: candidateid,
          status: 1,
          courseid: courseid,
          createdby: candidateid,
          updatedby: corcandidatepreferenceid ? parseInt(candidateid) : "",
        },
        navigate: navigate,
        nextPageName: nextPageName,
      },
    });
  };

  const language = [
    { id: 1, language: "Tamil" },
    { id: 2, language: "English" },
    { id: 3, language: "Hindi" },
  ];

  const handleBack = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${previousPageName}`,
      { state: { courseid: courseid } }
    );
  };

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  return (
    <Grid
      p={3}
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Typography
        pb={2}
        variant="h4"
        sx={{ textWrap: "wrap", fontSize: 30, fontWeight: 500 }}
      >
        Exam Centre Selection
      </Typography>

      {masterFields?.find(
        (field) =>
          field.masterfieldlabel === masterFieldLabel.SELECT_SUBJECT_GROUP
      ) && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SELECT_SUBJECT_GROUP
                )?.fieldlabel
              }
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.SELECT_SUBJECT_GROUP
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small" error={!!errors.courseid}>
              <Controller
                name="subjectgroup"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SELECT_SUBJECT_GROUP
                )?.ismandatory === "1" ? "Select group is required" : false}}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="subjectgroup"
                    value={watch("subjectgroup") || ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.SELECT_SUBJECT_GROUP)}
                  >
                    <MenuItem value="" disabled>
                      Select one
                    </MenuItem>
                    <MenuItem value="PCM">PCM</MenuItem>
                    <MenuItem value="PCB">PCB</MenuItem>
                    <MenuItem value="PCMB">PCMB</MenuItem>
                  </Select>
                )}
              />
              {errors.subjectgroup && (
                <small className="error" style={{ margin: 0,marginTop:".2rem" }}>
                  {errors.subjectgroup.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      {masterFields?.find(
        (field) => field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_STATE
      ) && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SSC_SCHOOL_STATE
                )?.fieldlabel
              }{" "}
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_STATE
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small" error={!!errors.stateid}>
              {/* <InputLabel
              shrink={!!watch("examstate") || watch("examstate") === 0}
              htmlFor="genderid"
            >
              Select state for MAH-B.BCA/BBA/BMS/BBM- CET 2024 Examination
              Centre District<span className="error">*</span>
            </InputLabel> */}
              <Select
                id="stateid"
                //   label="Select state for  MAH-B.BCA/BBA/BMS/BBM- CET 2024 Examination Centre District"
                // value={getValues("pstateid")}
                {...register("stateid", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_STATE
                  )?.ismandatory === "1" ? "State is required" : false,
                })}
                onChange={(e) => {
                  const stateid = e.target.value;
                  setValue("stateid", stateid);
                  dispatch({
                    type: masterActions.GET_DISTRICT,
                    payload: stateid,
                  });
                  trigger("stateid");
                }}
                value={watch("stateid") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.SSC_SCHOOL_STATE)}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.stateid && (
                <small className="error" style={{ margin: 0,marginTop:".2rem" }}>
                  {errors.stateid.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      {masterFields?.find(
        (field) => field.masterfieldlabel === masterFieldLabel.SELECT_LANGUAGE
      ) && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.SELECT_LANGUAGE
                )?.fieldlabel
              }
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel === masterFieldLabel.SELECT_LANGUAGE
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth error={!!errors.language}>
              <Controller
                name="language"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.SELECT_LANGUAGE
                )?.ismandatory === "1" ? "Select Language is required" : false}}
                render={({ field }) => (
                  <Select
                    {...field}
                    multiple
                    fullWidth
                    size="small"
                    value={field.value || []}
                    onChange={(event) => {
                      const selectedValues = event.target.value;
                      field.onChange(selectedValues);
                      console.log(selectedValues);
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          const selectedLanguage = language.find(
                            (lang) => lang.language === value
                          );
                          return (
                            <Chip
                              key={value}
                              label={selectedLanguage?.language}
                            />
                          );
                        })}
                      </Box>
                    )}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.SELECT_LANGUAGE)}
                  >
                    {language.map((val) => (
                      <MenuItem key={val.id} value={val.language}>
                        {val.language}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.language && (
                <small className="error" style={{ margin: 0,marginTop:".2rem" }}>
                  {errors.language.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      {masterFields?.find(
        (field) =>
          field.masterfieldlabel ===
          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
      ) && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
                )?.fieldlabel
              }
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <FormControl fullWidth size="small" error={!!errors.examdistrict1}>
              <Controller
                name="preference1districtid"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
                )?.ismandatory === "1" ? "District is required" : false}}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="preference1districtid"
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1)}
                  >
                    <MenuItem value={0}>Not Applicable</MenuItem>
                    {district?.map((val, i) => (
                      <MenuItem value={val?.districtid} key={i}>
                        {val?.district}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.preference1districtid && (
                <small className="error" style={{ margin: 0,marginTop:".2rem" }}>
                  {errors.preference1districtid.message}
                </small>
              )}
            </FormControl> */}
            <FormControl fullWidth size="small" error={!!errors.preference1districtid}>
              {/* <InputLabel
              shrink={!!watch("examstate") || watch("examstate") === 0}
              htmlFor="genderid"
            >
              Select state for MAH-B.BCA/BBA/BMS/BBM- CET 2024 Examination
              Centre District<span className="error">*</span>
            </InputLabel> */}
              <Select
                id="preference1districtid"
                name="preference1districtid"
                //   label="Select state for  MAH-B.BCA/BBA/BMS/BBM- CET 2024 Examination Centre District"
                // value={getValues("pstateid")}
                {...register("preference1districtid", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_STATE
                  )?.ismandatory === "1" ? "State is required" : false,
                })}
                // onChange={(e) => {
                //   const stateid = e.target.value;
                //   setValue("stateid", stateid);
                //   dispatch({
                //     type: masterActions.GET_DISTRICT,
                //     payload: stateid,
                //   });
                //   trigger("stateid");
                // }}
                value={watch("preference1districtid") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.SSC_SCHOOL_STATE)}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {district?.map((val, i) => (
                  <MenuItem value={val?.districtid} key={i}>
                    {val?.district}
                  </MenuItem>
                ))}
              </Select>
              {errors.district && (
                <small className="error" style={{ margin: 0,marginTop:".2rem" }}>
                  {errors.district.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      {masterFields?.find(
        (field) =>
          field.masterfieldlabel ===
          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
      ) && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
                )?.fieldlabel
              }
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small" error={!!errors.examdistrict2}>
              <Controller
                name="preference2districtid"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
                )?.ismandatory === "1" ? "District is required" : false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="preference2districtid"
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2)}
                  >
                    <MenuItem value={0}>Not Applicable</MenuItem>
                    {district
                      ?.filter(
                        (val) =>
                          val.districtid !== watch("preference1districtid")
                      )
                      .map((val, i) => (
                        <MenuItem value={val?.districtid} key={i}>
                          {val?.district}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              {errors.preference2districtid && (
                <small className="error" style={{ margin: 0,marginTop:".2rem" }}>
                  {errors.preference2districtid.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      {masterFields?.find(
        (field) =>
          field.masterfieldlabel ===
          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
      ) && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
                )?.fieldlabel
              }
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small" error={!!errors.examdistrict3}>
              <Controller
                name="preference3districtid"
                control={control}
                rules={{ required: masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
                )?.ismandatory === "1" ? "District is required" : false}}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="preference3districtid"
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(masterFields, masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3)}
                  >
                    <MenuItem value={0}>Not Applicable</MenuItem>
                    {district
                      ?.filter(
                        (val) =>
                          val.districtid !== watch("preference2districtid") &&
                          val.districtid !== watch("preference1districtid")
                      )
                      .map((val, i) => (
                        <MenuItem value={val?.districtid} key={i}>
                          {val?.district}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              {errors.preference3districtid && (
                <small className="error" style={{ margin: 0,marginTop:".2rem" }}>
                  {errors.preference3districtid.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} mt={1}>
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
        ) && (
          <>
            <Grid item xs={12} md={5}>
              <Typography>
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                fullWidth
                size="small"
                error={!!errors.examdistrict4}
              >
                <Controller
                  name="preference4districtid"
                  control={control}
                  rules={{ required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
                  )?.ismandatory === "1" ? "District is required" : false }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="preference4districtid"
                      value={field.value ?? ""}
                      disabled={isFieldDisabled(masterFields, masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4)}
                    >
                      <MenuItem value={0}>Not Applicable</MenuItem>
                      {district
                        ?.filter(
                          (val) =>
                            val.districtid !== watch("preference2districtid") &&
                            val.districtid !== watch("preference1districtid") &&
                            val.districtid !== watch("preference3districtid")
                        )
                        .map((val, i) => (
                          <MenuItem value={val.districtid} key={i}>
                            {val.district}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                {errors.preference4districtid && (
                  <small className="error" style={{ margin: 0,marginTop:".2rem" }}>
                    {errors.preference4districtid.message}
                  </small>
                )}
              </FormControl>
            </Grid>
          </>
        )}

        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : null,
          }}
        >
          <BackButton handleBack={handleBack} />
          <SubmitButton
            name={buttonLoader ? "loader" : "proceed"}
            handleSubmit={handleSubmit(handleProcced)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
