import React from "react";
import Index from "../components/landingPage";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";

export default function LandingPage() {

    return (
        <>
            <Layout>
                <Header />
                <Sidebar />
                <Index />
            </Layout>
        </>
    )
}