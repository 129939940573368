import React from "react";
import DataTable from "../../common/dataTable";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import SaveButton from "../../common/saveButton";
import TablePagination from "../../common/tablePagination";

export default function ObjectionTracker() {
  const navigate = useNavigate();

  const questionKeysHeaders = [
    { name: "Question NO", accessor: "questionNo" },
    { name: "Question Details", accessor: "questionDetails" },
    { name: "Answer Key", accessor: "answerKey" },
    { name: "Your Response", accessor: "yourResponse" },
    { name: "Select", accessor: "select" },
  ];

  const questionKeysData = [
    {
      questionNo: 1,
      questionDetails:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      answerKey: "A",
      yourResponse : "A",
      select : "1"
    },
    {
      questionNo: 2,
      questionDetails:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      answerKey: "B",
      yourResponse : "A",
      select : "1"
    },
    {
      questionNo: 3,
      questionDetails:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      answerKey: "A",
      yourResponse : "C",
      select : "1"
    },
    {
      questionNo: 4,
      questionDetails:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      answerKey: "D",
      yourResponse : "D",
      select : "1"
    },
  ];

  const handleViewRaiseObjection = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/answer-key?page=raisedobjection`);
  }

  const handleRaiseObjection = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/answer-key?page=raisedobjection`);
  }

  return (
    <Grid>
      <Box sx={{display:"flex",justifyContent:"flex-end"}}>
        <MauvelousPrimaryButton title={"View Raised Objection"} handleClick={handleViewRaiseObjection} />
      </Box>
      <Grid sx={{display:"flex",justifyContent:"space-between"}} pt={2}>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <Typography>Course : <b>B.ED - CET2024</b></Typography>
          <Typography>Exam Date : <b>05.02.2024</b> </Typography>
        </Box>
        <Box>
          <FormControl sx={{width: "9rem"}} size="small">
            <InputLabel htmlFor="questionno">
            Question No
            </InputLabel>
            <Select size="small" id="questionno" label="Question No">
              <MenuItem value={1}>01</MenuItem>
              <MenuItem value={2}>02</MenuItem>
              <MenuItem value={3}>03</MenuItem>
              <MenuItem value={4}>04</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Typography>Raise objection for the question that you wrote</Typography>
      <DataTable keys={questionKeysHeaders} values={questionKeysData} />
      <TablePagination />
      <Box><Typography></Typography></Box>
      <Box mt={2} sx={{display:"flex",justifyContent:"flex-end"}}>
        <MauvelousPrimaryButton handleClick={handleRaiseObjection} title={"Raise Objection"} />
      </Box>
    </Grid>
  );
}
