import { Box, Card, Pagination, Typography } from '@mui/material'
import React from 'react'
import PrintDownloadButton from '../../common/button/printDownloadButton'
import { PiFileXls } from 'react-icons/pi';
import Cookies from 'js-cookie'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const navigate = useNavigate()
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        ChartDataLabels,
    );

    const options = {
        plugins: {
            title: {
                display: false,
                // text: 'Chart.js Bar Chart - Stacked',
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: true,
                color: '#212121',
                formatter: (value, context) => value,
                anchor: 'end',
                align: 'top',
                offset: 1,
            },
        },
        responsive: true,
        width:1000,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {

                min: 0,
                max: 1000,
                stacked: true,
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
        },
        barPercentage: 0.5,
        categoryPercentage: 0.5,
    };

    const labels = ['B planning', 'B.A,B.Ed & B.Sc,B.Ed', 'B.Ed,M.Ed (Integrated)', 'B.P.Ed', 'A.Arch', 'BHMCT',];

    const data = {
        labels,
        datasets: [
            {
                label: "Total tickets",
                backgroundColor: "#D73E34",
                borderColor: "#fff",
                data: [100, 500, 300, 600, 200, 100],
                barThickness: 35,
                borderWidth: 4,
                pointStyle: "circle",
                borderRadius: 8,
                stack: 'stack 1'
            },
            {
                label: "Total resolved",
                backgroundColor: "#3461D7",
                borderColor: "#fff",
                data: [600, 100, 500, 300, 200, 100],
                barThickness: 35,
                borderWidth: 4,
                pointStyle: "circle",
                borderRadius: 8,
                stack: 'stack 2'
            },
            {
                label: "Tickets yet to resolve",
                backgroundColor: "#34D7CD",
                borderColor: "#fff",
                data: [100, 250, 500, 300, 200, 100],
                barThickness: 35,
                borderWidth: 4,
                pointStyle: "circle",
                borderRadius: 8,
                stack: 'stack 3'
            },
            {
                label: "Tickets yet to assign",
                backgroundColor: "#34B0D7",
                borderColor: "#fff",
                data: [600, 100, 500, 300, 200, 100],
                barThickness: 35,
                borderWidth: 4,
                pointStyle: "circle",
                borderRadius: 8,
                stack: 'stack 4'
            },
        ],

    };
    const handleNavigate = () => {
        navigate(`/${Cookies.get("mhet_cnd_project")}/objection-tracker-view`)
    }
    return (
        <>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 2 }}>
                <Typography pb={1} variant="h5"  >Dashboard | Objection Tracker</Typography>

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <PrintDownloadButton name="Download Objection" startIcon={<PiFileXls />} />
                    <PrintDownloadButton name="View Objections" handleSubmit={handleNavigate} />
                </Box>
            </Box>
            <Card sx={{ borderRadius: '8x', padding: 2, boxShadow: 'none' }}>
                <Box
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: 'center',
                        gap: "8px",
                    }}
                    pt={1}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <Box
                            style={{
                                height: "12px",
                                width: "12px",
                                backgroundColor: "#D73E34",
                                borderRadius: "50%",
                            }}
                        ></Box>
                        <Box>
                            <Typography
                                variant="caption"
                                style={{ margin: 0, fontSize: 13, wordWrap: "break-word" }}
                            >
                                Total tickets
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <Box
                            style={{
                                height: "12px",
                                width: "12px",
                                backgroundColor: "#3461D7",
                                borderRadius: "50%",
                            }}
                        ></Box>
                        <Box>
                            <Typography
                                variant="caption"
                                style={{ margin: 0, fontSize: 13, wordWrap: "break-word" }}
                            >
                                Tickets resolved
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <Box
                            style={{
                                height: "12px",
                                width: "12px",
                                backgroundColor: "#34D7CD",
                                borderRadius: "50%",
                            }}
                        ></Box>
                        <Box>
                            <Typography
                                variant="caption"
                                style={{ margin: 0, fontSize: 13, wordWrap: "break-word" }}
                            >
                                Tickets yet to resolve
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <Box
                            style={{
                                height: "12px",
                                width: "12px",
                                backgroundColor: "#34B0D7",
                                borderRadius: "50%",
                            }}
                        ></Box>
                        <Box>
                            <Typography
                                variant="caption"
                                style={{ margin: 0, fontSize: 13, wordWrap: "break-word" }}
                            >
                                Tickets yet to assign
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Bar options={options} data={data} />
                <Box sx={{ paddingTop: 2, display: 'flex', justifyContent: 'center' }}>
                    <Pagination count={10} variant="outlined" shape="rounded" />
                </Box>
            </Card>
        </>
    )
}

export default Index
